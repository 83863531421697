.inline-menu {

    @include breakpoint() {

        padding: 32px;

        background-color: $white;

        @include box-shadow(0 4px 35px 0 rgba(0,0,0,0.15));

        position: absolute;
        z-index: 9;

    }

    &.arrow {

        @include breakpoint() {
        }

        &.top {

            @include breakpoint() {
                margin: 24px 0 0 0;
            }

            &::before {

                @include breakpoint() {

                    content: '';
                    display: block;

                    width: 0;
                    height: 0;

                    border-style: solid;
                    border-width: 0 10px 10px 10px;

                    border-color: transparent transparent $white transparent;

                    position: absolute;
                    top: -10px;

                }

            }

            &.center {

                &::before {

                    @include breakpoint() {

                        left: 50%;
                        @include transform(translate3d(-50%, 0, 0));

                    }

                }

            }

            &.right {

                &::before {

                    @include breakpoint() {

                        right: 24px;

                    }

                }

            }

        }

    }

}

.inline-menu__container {

    @include breakpoint() {
        position: relative;
    }

    .inline-menu__trigger {

        & + .inline-menu {

            @include breakpoint() {

                max-height: 0;
                min-width: 320px;

                opacity: 0;
                visibility: hidden;

                top: 24px;
                right: 0;

            }

        }

        &.is-active {

            & + .inline-menu {

                @include breakpoint() {

                    max-height: 999vh;

                    opacity: 1;
                    visibility: visible;

                    @include animation(fade_in_down 600ms both);

                }

            }

        }

    }

}
