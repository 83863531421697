//  SIGN IN
.sign-in__container {

    @include breakpoint() {
        padding: 0 24px;

        @include animation(fade_in 600ms ease-in-out 1 forwards 0ms);
    }
    @include breakpoint($tablet) {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        width: 1000px;
        margin: auto;

    }
    @include breakpoint($tablet) {

        flex-direction: column;
        justify-content: space-evenly;

        height: 570px;

    }
    @include breakpoint($small_desktop) {
        width: 1100px;
    }

	.alpha {

		@include breakpoint() {
			color: $white;
        }
        @include breakpoint($small_tablet) {

            width: 540px;
            margin: 0 auto;

        }
        @include breakpoint($tablet) {

            width: 460px;

        }
        @include breakpoint($small_desktop) {
            width: 600px;
        }

        .iamx-info {

            @include breakpoint() {
                font-size: 14px;
            }
            @include breakpoint($tablet) {
                font-size: 16px;
            }

        }

        .break-from-tablet {
            @include breakpoint() {
                display: none;
            }
            @include breakpoint($small_tablet) {
                display: block;
            }
        }

		.assure-id-logo-white {

			@include breakpoint() {

				background-repeat: no-repeat;
				background-attachment: scroll;
				background-position: center center;

				background-size: cover;

				background-image: url('../images/logos/acuant-logo-white.svg');

				width: 111px;
				height: 52px;

				margin: 0 auto 40px;

				display: block;

            }
            @include breakpoint($small_tablet) {
                margin: 0 auto 50px;
            }

		}

		h2,
		p {
			@include breakpoint() {

				text-align: center;
				margin: 0 0 40px 0;

            }
            @include breakpoint($small_tablet) {
                margin: 0 0 50px 0;
            }
        }
        
        p {
            @include breakpoint($small_desktop) {
                width: 500px;
                margin: 0 auto;
            }
        }

        h2.extra-bold {

            @include breakpoint() {
                font-size: 18px;
                line-height: 1.25;
            }
            @include breakpoint($tablet) {
                font-size: 24px;
            }

        }

	}

	.sign-in-form {

		@include breakpoint() {
	
			padding: 32px 16px;
			margin: 0 0 40px 0;
	
			background-color: $white;
	
		}
		@include breakpoint($small_tablet) {

			width: 477px;

			padding: 56px;
			margin: 0 auto 50px auto;

        }
        @include breakpoint($tablet) {

            flex: none;
            align-self: flex-end;

            width: 460px;
            margin: 0;

            order: 3;

        }
        @include breakpoint($small_desktop) {
            width: 477px;
        }

        .f-title {

            @include breakpoint() {
                font-size: 24px;
            }
            @include breakpoint($tablet) {
                font-size: 32px;
            }

        }

        .f-description {

            @include breakpoint() {
                font-size: 14px;
            }
            @include breakpoint($tablet) {
                font-size: 16px;
            }

        }

	}

	.beta {

		@include breakpoint() {

			color: $white;

        }
        @include breakpoint($tablet) {

            width: 460px;
            margin: 0;

        }
        @include breakpoint($small_desktop) {
            width: 600px;
        }

		p {

			@include breakpoint() {

				font-weight: $bold;
				text-align: center;

			}

		}

	}

    .sign-in__info {
        color: #fff;

        @include breakpoint() {

            margin-top: 24px;
            padding: 16px 0 0 0;

            font-size: 14px;
            line-height: 16px;

            font-weight: $medium;

            text-align: center;

        }

    }

    //  IE11 SPECIFIC MEDIA QUERY TARGETING
    @media screen and (min-width: $small_desktop) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: calc( ( 100vh - 570px ) / 2 );
    }

}

//  RECOVER
.recover__container {

    @include breakpoint() {
        padding: 0 20px;

        @include animation(fade_in 600ms ease-in-out 1 forwards 0ms);
    }
    @include breakpoint($small_tablet) {

        margin: auto;

    }

    .recover-form {

        @include breakpoint() {
	
			padding: 40px 20px;
			margin: 0 0 40px 0;
	
			background-color: $white;
	
        }
        @include breakpoint($small_tablet) {

            width: 477px;
            margin: 0;

            padding: 56px;

        }

    }

}