.DayPickerInput-OverlayWrapper {

    @include breakpoint() {

        margin: 24px 0 0 0;
    
    }

}

.DayPicker {

    @include breakpoint() {

        padding: 24px 16px 8px 16px;

        @include box-shadow(0 5px 35px 0 rgba(0,0,0,0.15));

    }

    &::before {

        @include breakpoint() {

            content: '';
            display: block;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $white transparent;

            position: absolute;
            top: -8px;

        }

    }

    .DayPicker-wrapper {

        .DayPicker-NavBar {

            @include breakpoint() {

                width: 100%;
                height: 20px;

                padding: 0 1rem;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

            }

            .DayPicker-NavButton {

                @include breakpoint() {

                    position: static;
                    margin: 0;

                    position: relative;
                    z-index: 99;

                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;

                }

                &.DayPicker-NavButton--prev {
                    @include breakpoint() {
                        background-image: url('../images/icons/i_cal_chevron_left.svg');
                    }
                }

                &.DayPicker-NavButton--next {
                    @include breakpoint() {
                        background-image: url('../images/icons/i_cal_chevron_right.svg');
                    }
                }

            }

        }

        .DayPicker-Months {

            .DayPicker-Month {

                .DayPicker-Caption {

                    @include breakpoint() {
                        
                        margin-top: -35px;
                        margin-bottom: 15px;

                        text-align: center;

                    }

                    div {

                        @include breakpoint() {
                            font-weight: $bold;
                        }

                    }

                }

                .DayPicker-Weekdays {

                    .DayPicker-WeekdaysRow {

                        .DayPicker-Weekday {

                        }

                    }

                }

                .DayPicker-Body {

                    .DayPicker-Week {

                        .DayPicker-Day {

                            @include breakpoint() {
                                font-weight: $bold;
                            }

                            &.DayPicker-Day--selected {

                                @include breakpoint() {

                                    border-radius: 0;

                                    color: $white;
                                    background-color: $maximum_red;

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}