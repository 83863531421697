.filter__container {

    @include breakpoint() {

        position: relative;

        display: flex;

    }

    .filter__trigger {

        @include breakpoint() {

            color: $old_silver;

            font-size: 12px;
            line-height: 20px;
            font-weight: $bold;

            letter-spacing: 1px;

            text-transform: uppercase;

            display: inline;
            flex-direction: row;

            margin: auto 0;

            cursor: pointer;

            @include user-select(none);

            position: relative;

        }
        @include breakpoint($small_tablet) {
            display: flex;
        }

        .mobile-only {
            @include breakpoint() {
                display: inline;
            }
            @include breakpoint($small_tablet) {
                display: none;
            }
        }

        &::after {

            @include breakpoint() {

                content: '';
                display: inline;

                width: 0;
                height: 0;

                margin: auto 10px auto 10px;

                border-style: solid;
                border-width: 4px 3px 0 3px;

                border-color: $silver_sand transparent transparent transparent;

                position: relative;
                top: 8px;

            }
            @include breakpoint($large_mobile) {
                margin: auto 0 auto 10px;
            }
            @include breakpoint($small_tablet) {
                position: static;
                display: block;
            }

        }

        & + .filter__content {

            @include breakpoint() {

                max-height: 0;
                width: 240px;

                opacity: 0;
                visibility: hidden;

                margin-top: 44px;

            }

        }

        &.is-active {

            & + .filter__content {

                @include breakpoint() {

                    max-height: 999vh;

                    opacity: 1;
                    visibility: visible;

                    @include animation(fade_in_down 600ms both);

                }

            }

        }

    }

}

.filter {

    @include breakpoint() {

        display: flex;
        flex-direction: column;

    }

    li {

        @include breakpoint() {
            margin: 0;
        }

        &:last-child {
            @include breakpoint() {
                margin: 0;
            }
        }

        .filter-item {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 16px;
                line-height: 24px;
                font-weight: $bold;

                display: block;

                text-decoration: none;

            }

            &.is-selected {

                @include breakpoint() {
                    color: $old_silver;
                }

            }

        }

    }

}

.page__filters {

    @include breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 16px 0 0 0;
        padding: 24px 0;

    }
    @include breakpoint($small_tablet) {
        justify-content: flex-start;
    }

    .filter__container {

        @include breakpoint($small_tablet) {
            margin: 0 40px 0 0;
        }

        &:last-child {
          @include breakpoint($small_tablet) {
              margin: 0;
          }  
        }

        &:first-child {
            .filter__content {
                @include breakpoint() {
                    left: 0;
                }
            }
        }

        &:last-child {
            .filter__content {
                @include breakpoint() {
                    right: 0;
                }
            }
        }

    }

}
