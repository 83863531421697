body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}



@tailwind base;
@tailwind components;
@tailwind utilities;