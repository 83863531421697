.notification {

    @include breakpoint() {

        max-height: 0;

        overflow: hidden;

        @include transition(all 300ms ease-in-out 0ms);

    }

    &.red {

        --primary-color: #{$maximum_red};
        --secondary-color: #{$white};

        @include breakpoint() {

            color: var(--secondary-color);
            background-color: var(--primary-color);

        }

    }

    .notification__container {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

            padding: 10px 0;

        }
        @include breakpoint($small_tablet) {

            flex-direction: row;

        }

    }

    .notification__content {

        @include breakpoint() {

            font-size: 14px;
            line-height: 25px;

            font-weight: $bold;

            margin: 0 0 20px 0;

        }
        @include breakpoint($small_tablet) {

            text-align: left;

            margin: 0;

        }
        @include breakpoint($tablet) {

            max-width: 600px;
            margin: 0 auto 0 0;

        }

    }

    .notification__controls {

        @include breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

        }
        @include breakpoint($small_tablet) {

            flex: none;
            align-items: center;

        }

        .cta {

            &.var {

                @include breakpoint() {

                    --cta-var-primary-color: var(--secondary-color);
                    --cta-var-secondary-color: var(--primary-color)

                }

                @include breakpoint($tablet) {
                    margin: 0 0 0 20px;
                }

            }

        }

        .f-link {

            &.var {

                @include breakpoint() {

                    --link-var-color: var(--secondary-color);
                    --link-var-line-height: 50px;

                }

            }

        }

    }

    //  PUSH CONTENT ALONG WITH NOTIFICATION
    &.push-in {

        @include breakpoint() {

            max-height: 100vh;

        }

    }

}