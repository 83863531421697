.calendar-container {
  width: 360px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/*  SECTIONS  */
.section_mp {
  clear: both;
  padding: 0px;
  margin: 0px;
}

/*  COLUMN SETUP  */
.col_mp {
  display: block;
  float:left;
  text-align: center;
}
.col_mp:first-child {
  margin-left: 0;
}

/*  GROUPING  */
.group_mp:before, .group:after {
  content:"";
  display:table;
}
.group_mp:after {
  clear:both;
}
.group_mp {
  zoom:1; /* For IE 6/7 */
}

/*  GRID OF THREE  */
.span_1_of_3_mp { width: 33.33%; }

.col_mp{
  font-size: 16px;
  padding-bottom: 5px;
  padding-top:5px;
}

.col_mp:hover{
  color:white;
  background-color: darkslateblue;
  cursor: pointer;
}
.selected_date_mp{
  font-size:12px;
  color:darkslateblue;
  font-weight: bold;
}
.selected_cell{
  background-color: darkslateblue;
  font-style: italic;
  color: #fff;
}
.arrows_mp{
  font-weight: bold;
  font-size: 18px;
}


//  OVERRIDES
.calendar-container {

  @include breakpoint() {

    font-family: 'Raleway',sans-serif;
    font-size: 16px;

    width: 100%;

    position: absolute;
  
    z-index: 99;

    padding: 16px;

    background-color: $white;
    @include box-shadow(0 5px 35px 0 rgba(0,0,0,0.15));
    border: none;

    top: 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

  }
  @include breakpoint($small_tablet) {

    left: 0;
    width: 320px;
    
  }

  &::before {

    @include breakpoint() {

      content: '';
      display: block;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $white transparent;

      position: absolute;
      top: -8px;

    }

  }

  .col_mp {

    @include breakpoint() {

      font-size: 16px;
      font-weight: $bold;

      width: calc(100% / 3);
      flex: none;

      line-height: 30px;
      padding: 5px 0;

    }

    &:hover {

      @include breakpoint() {

        color: $white;
        background-color: rgba($maximum_red,0.5);

      }

    }

    &.selected_cell {

      @include breakpoint() {

        font-style: normal;

        color: $white;
        background-color: $maximum_red;

      }

    }

  }

  .section_mp {

    @include breakpoint() {

      width: 100%;
      flex: none;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;      
    
      margin: 0 0 16px 0;

    }

    .col_mp {

      &.arrows_mp {
  
        @include breakpoint() {

          min-width: 40px;

          flex-grow: 0;
          flex-shrink: 0;

          text-indent: -9999px;

          background-repeat: no-repeat;
          background-attachment: scroll;
          background-position: center center;

        }

        &:hover {

          @include breakpoint() {
            background-color: $white;
          }
        
        }  

        &:first-child {

          @include breakpoint() {
            background-image: url('../images/icons/i_cal_chevron_left.svg');
          }

        }
        &:last-child {

          @include breakpoint() {
            background-image: url('../images/icons/i_cal_chevron_right.svg');
          }

        }

      }

      &.selected_date_mp {

        @include breakpoint() {

          color: $dark_gray;

          font-size: 18px;
          font-weight: $bold;

          flex-shrink: 0;
          flex-grow: 1;

          line-height: 30px;
          padding: 5px 0;
        
        }

      }  

    }

  }

}