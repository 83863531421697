input[type="checkbox"].checkbox {

	@include breakpoint() {
		display: none;
	}

	& + label {

		@include breakpoint() {

			color: $dark_gray;

			font-size: 14px;
			line-height: 16px;
			font-weight: $medium;			

			text-transform: none;

			display: flex;
            flex-direction: row;
            letter-spacing: 0;

			position: relative;

			@include user-select(none);

			cursor: pointer;

		}

		&::before,
		&::after {

			@include breakpoint() {

				content: '';
				display: block;

                box-sizing: border-box;
                
                flex-shrink: 0;

			}

		}

		&::before {

			@include breakpoint() {

				width: 20px;
				height: 20px;

				border: solid 2px $old_silver;
				border-radius: 2px;

				@include transition(all 300ms ease-in-out 0ms);

			}

		}

		&::after {

			@include breakpoint() {

				height: 6px;
				width: 10px;

				border-bottom: solid 2px $white;
				border-left: solid 2px $white;

				transform-origin: center center;

				@include transform(rotate(-45deg));

				position: absolute;
				top: 5px;
				left: 4px;

				opacity: 0;
				visibility: hidden;

				@include transition(all 300ms ease-in-out 0ms);

			}

		}

        &:not(:empty) {

            @include breakpoint() {

                font-size: 16px;
                line-height: 20px;
                font-weight: $medium;

            }

            &::before {
                @include breakpoint() {
                    margin: 0 24px 0 0;
                }
            }

        }

	}

	&:checked {

		& + label {

			&::before {

				@include breakpoint() {
					border-color: $persian_green;
					background-color: $persian_green;
				}

			}

			&::after {

				@include breakpoint() {

					opacity: 1;
					visibility: visible;

				}

			}

		}

	}

}