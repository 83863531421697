.nav__lang-container {

    @include breakpoint() {

        order: 3;

        display: flex;
        flex-direction: column;

        padding: 20px 0 0 0;

    }
    @include breakpoint($small_tablet) {

        width: 50%;
        
        order: 2;
        
        align-self: flex-end;

    }
    @include breakpoint($tablet) {

        width: auto;

        padding: 0;
        margin: 0 0 0 auto;

        order: 2;

        align-self: auto;

        position: relative;

    }

    .nav__lang-label {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 12px;
            line-height: 20px;
            font-weight: $bold;

            margin: 0 auto 20px;

            text-transform: uppercase;

        }
        @include breakpoint($tablet) {
            display: none;
        }

    }

    .lang-trigger {

        @include breakpoint() {

            text-decoration: none;

            margin: 0 auto 20px auto;

            display: flex;
            flex-direction: row;

            position: relative;

        }
        @include breakpoint($tablet) {

            margin: auto;

        }

        .lang-selected-preview {

        }

        .flag {

            @include breakpoint() {

                width: 40px;
                height: 40px;

                background-size: 40px 40px;

                margin: 0 70px 0 0;

            }
            @include breakpoint($tablet) {

                width: 24px;
                height: 24px;

                background-size: 24px 24px;

            }

            &::before {

                @include breakpoint() {

                    color: $dark_gray;

                    line-height: 40px;
                    margin: 0 0 0 60px; 

                }
                @include breakpoint($tablet) {

                    line-height: 24px;
                    margin: 0 0 0 34px;

                }

            }

            &::after {

                @include breakpoint() {

                    content: '';
                    display: block;
    
                    width: 0;
                    height: 0;
    
                    border-style: solid;
                    border-width: 8px 8px 0 8px;
                    border-color: $dark_gray transparent transparent transparent;
    
                    position: absolute;

                    right: 0;
                    top: 50%;

                    @include transform(translate3d(0,-50%,0));

                }

            }

        }

        & + .lang-list {

            @include breakpoint() {

                max-height: 0;

                opacity: 0;
                visibility: hidden;

                overflow: hidden;

            }

        }

        &.is-active {

            @include breakpoint() {
                margin-bottom: 40px;
            }
            @include breakpoint($tablet) {
                margin-bottom: auto;
            }

            & + .lang-list {
        
                @include breakpoint() {
        
                    opacity: 1;
                    visibility: visible;

                    max-height: 999vh;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
        
                }
                @include breakpoint($tablet) {

                    position: absolute;

                    top: 60px;
                    right: -40px;

                    z-index: 99999;

                    width: 290px;
                    padding: 20px 10px 0 10px;

                    background-color: $white;
                    @include box-shadow(0 4px 35px 0 rgba($black, 0.15));
                    
                    overflow: unset;

                    @include animation(fade_in_down 600ms both);

                }

                .lang-item {
        
                    @include breakpoint() {
        
                        flex: none;
                        width: 33.333333%;
        
                        margin: 0 0 20px 0;
                        padding: 0 0 0 20px;
        
                    }
                    @include breakpoint($tablet) {

                        width: 70px;

                        margin: 0 10px 20px 10px;
                        padding: 0;

                    }
        
                    input[type="radio"] {
        
                        @include breakpoint() {
                            display: none;
                        }
        
                        & + label {
                            @include breakpoint() {

                                margin: 0;
                                cursor: pointer;

                            }

                        }
        
                    }
        
                }
        
            }

            &::after {

                @include breakpoint() {
    
                    border-width: 0 8px 8px 8px;
                    border-color: transparent transparent $dark_gray transparent;

                }
                @include breakpoint($tablet) {

                    border-width: 8px 8px 0 8px;
                    border-color: $dark_gray transparent transparent transparent;

                }

    
            }

        }

    }

}

//  FLAG DEFINITIONS
.flag {

    @include breakpoint() {

        display: block;

        width: 24px;
        height: 24px;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;

        background-size: 24px 24px;

        text-decoration: none;

    }

    &:hover {

        &::before {

            @include breakpoint($tablet) {
                color: $dark_gray;
            }

        }

    }

    &::before {

        @include breakpoint() {

            display: block;

            color: $dark_gray;

            font-size: 16px;
            line-height: 24px;
            font-weight: $bold;

            text-transform: uppercase;

            margin: 0 0 0 34px;

        }
        @include breakpoint($tablet) {

            color: $light_gray;

            @include transition(all 300ms ease-in-out 0ms);

        }

    }

    &.us {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_usa.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'usa';
            }
        }
    }

    &.en {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_en.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'en';
            }
        }
    }

    &.fr {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_fr.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'fr';
            }
        }
    }

    &.de {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_de.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'de';
            }
        }
    }

    &.esp {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_sp.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'esp';
            }
        }
    }

    &.it {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_it.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'it';
            }
        }
    }

    &.au {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_au.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'au';
            }
        }
    }

    &.dk {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_dk.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'dk';
            }
        }
    }

    &.sv {
        @include breakpoint() {
            background-image: url('../images/icons/i_lang_sv.svg');
        }
        &::before {
            @include breakpoint() {
                content: 'sv';
            }
        }
    }

}