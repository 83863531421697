.rep__list {

    @media print {

        //  TEMP
        background-color: $white;

    }

    & > * {

        @media print {
            page-break-inside: avoid;
        }

    }
    .rep-head{
        display:none;
        @media print {

            display: flex;
            flex-direction: column;

            padding: 0.5cm 0.5cm 0cm 0.5cm;
            margin: 0 0 1cm 0;

        }
        .rep-head__title{
            color: $dark_gray;

            font-size: 24px;
            line-height: 32px;
            font-weight: $bold;

            margin: 0 0 16px 0;
        }
    }
    .rep__item {

        @media print {

            display: flex;
            flex-direction: column;

            padding: 0.5cm 0.5cm 0cm 0.5cm;
            margin: 0 0 1cm 0;

        }

        &:last-of-type {

            @media print {

                padding: 0.5cm 0.5cm 0.5cm 0.5cm;

            }

        }

        .rep__designation {

            @media print {
                margin: 0 0 0.5cm 0;
            }

            .rep__name {

                @media print {

                    color: $dark_gray;

                    font-size: 18px;
                    line-height: 21px;
                    font-weight: $extra_bold;

                    margin: 0 0 0.25cm 0;

                }

            }

            .rep__id {

                @media print {

                    color: $old_silver;

                    font-size: 14px;
                    line-height: 16px;

                }

            }

        }

        .rep__row {

            @media print {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                padding: 16px 20px;
                margin: 0;

            }

            .rep__cell {

                @media print {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    flex: none;

                    margin: 0;

                    // overflow: hidden;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;

                    align-items: center;

                }

                &:last-child {
                    @media print {
                        margin: 0;
                    }
                }

                &.sub {

                    @media print {

                        width: 25%;
                        padding: 0 0.25cm 0 0;

                    }

                }

                &.type {

                    @media print {
                        width: 15%;
                    }

                }

                &.auth,
                &.data,
                &.bar,
                &.total {

                    @media print {

                        justify-content: center;
                        
                        width: 15%;

                    }

                }

            }

        }

        .rep__head {

            @media print {

                background-color: $anti_flash_white;
                display: block;

            }

            .rep__row {

                .rep__cell {

                    @media print {

                        color: $dark_gray;

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $bold;
                        
                    }
                    
                }

            }

        }

        .rep__body {

            .rep__row {

                .rep__cell {

                    &.auth,
                    &.data,
                    &.bar,
                    &.total {

                        @extend .roboto;

                    }

                    @media print {

                        color: $dark_gray;

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $medium;

                    }
                   
                }

            }

        }

        .rep__foot {

            @media print {
                border-top: solid 2px $anti_flash_white;
            }

            .rep__row {

                &:only-child {

                    @media print {

                        padding: 16px 20px;
                        margin: 0;
                        border-bottom: none;

                    }

                }

                .rep__cell {

                    &.auth,
                    &.data,
                    &.bar,
                    &.total {

                        @extend .roboto;

                    }

                    @media print {

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $bold;

                    }

                }

            }

        }

        //  CONTAINER FOR CHILDREN ASOCIATED WITH PARRENT
        .rep__children {

            @media print {

                margin: 0;
                padding: 0 0 0 24px;

                background-color: $x12gray;
                @include box-shadow(inset 0 -4px 8px 0 rgba(0,0,0,0.15));

            }

        }

    }

}
