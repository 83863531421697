//	BREAKPOINTS
$mobile: 23em; 					//	~= 375	iPhone 6/7/8
$large_mobile: 25em; 		//	~= 414	iPhone 6/7/8 Plus
$small_tablet: 48em;		//	768			Tablet
$tablet: 64em;					//	1024		Large Tablet OR Landscape Tablet
$small_desktop: 80em;		//	1280		Notebooks
$desktop: 90em;					//	1440		Large Notebooks	OR Desktops
$large_desktop: 120em;	//	1920		Large Desktops

//	COLORS
$white: #ffffff;
$black: #000000;
$gray: #bdbdbd;
$dim_gray: #6D6A75;
$granite_gray: #606060;
$maximum_red: #D52B1E;
$persian_green: #00A887;
$ocean_green: #52C78F;
$platinum: #e1e1e1;
$arsenic: #434343;
$chrome_yellow: #EBA900;
$grizzly: #825410;
$liver: #6E2525;
$old_silver: #828282;
$silver_sand: #C4C4C4;
$main-page-bg: #fafafa;
$anti_flash_white: #f2f2f2;
$x11gray: #bdbdbd;
$x12gray: #e5e5e5;

$dark_gray: rgba($black, 0.8);
$light_gray: rgba($black, 0.4);
$ultra_ligh_gray: rgba($black, 0.1);
$border_color: $ultra_ligh_gray;
$border_highlight_color: rgba($black, 0.2);
$label_color: #828282;

//	FONT WEIGHTS
$light: 300;
$regular: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;