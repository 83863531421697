.month-picker {

  @include breakpoint() {

    position: relative;

  }

  .picker {

    .btn {

      @include breakpoint() {

        font-family: 'Raleway',sans-serif;

        width: 100%;
        height: 48px;

        padding: 0 16px;

        border: solid 2px $border_color;
        background-color: $white;

        color: $dark_gray;

        font-size: 16px;
        font-weight: $medium;

        @include transition(all 300ms ease-in-out 0ms);

        text-align: left;

      }
      
    }

  }

  .popover {

    @include breakpoint() {

      max-width: 100%;

      position: absolute;

      padding: 16px;
      background-color: $white;

      @include box-shadow(0 5px 35px 0 rgba(0,0,0,0.15));

    }
    @include breakpoint($small_tablet) {

      max-width: 688px;

    }

    .arrow {

    }

    .sec-wrap {

      .calendar {

        .clearfix {

          @include breakpoint($small_tablet) {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

          }

          .year {

            @include breakpoint() {
              margin: 0 0 24px 0;
            }
            @include breakpoint($small_tablet) {
              width: 320px;
            }

            &.year-start {

              @include breakpoint($small_tablet) {
                margin: 0 16px 0 0;
              }

            }

            &.year-end {

            }

            .head {

              .title {

                @include breakpoint() {

                  font-size: 18px;
                  line-height: 40px;

                  margin: 0 0 16px 0;

                  font-weight: $bold;

                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;

                  justify-content: space-between;

                }

                .btn {

                  @include breakpoint() {

                    width: 40px;
                    height: 40px;

                    line-height: 40px;

                    background-color: transparent;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;

                  }

                  i {
                    @include breakpoint() {
                      display: none;
                    }
                  }

                  &.year-down {

                    @include breakpoint() {
                      background-image: url('../images/icons/i_cal_chevron_left.svg');
                    }

                  }

                  &.year-up {

                    @include breakpoint() {
                      background-image: url('../images/icons/i_cal_chevron_right.svg');
                    }

                  }

                }

              }

            }

            .months {

              .clearfix {

                @include breakpoint() {

                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;

                }

                .month {

                  @include breakpoint() {

                    height: 40px;

                    flex: none;
                    width: calc(100% / 3);

                  }

                  .btn {

                    @include breakpoint() {

                      height: 40px;

                      color: $dark_gray;
                      background-color: $white;

                    }

                  }

                  &.selected {
  
                    .btn {

                      @include breakpoint() {

                        color: $white;
                        background-color: $maximum_red;
                      
                      }

                    }

                  }
  
                  &.highlight {
  
                    .btn {

                      @include breakpoint() {

                        color: $white;
                        background-color: rgba($maximum_red,0.5);
                      
                      }

                    }

                  }
  
                }  

              }

            }

          }

        }

      }

      .shortcuts {

        @include breakpoint() {

          width: 100%;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          justify-content: space-between;

        }
        @include breakpoint() {
          justify-content: unset;
        }

        .btn {

          @include breakpoint() {

            width: calc(50% - 8px);
          
          }
          @include breakpoint() {
            width: 200px;
          }

          &.btn-success {

            @include breakpoint() {
              margin: 0 8px 0 auto;
            }

          }

          &.btn-default {

            @include breakpoint() {

              color: $old_silver;
              background-color: $white;

              margin: 0 auto 0 8px;
            }

          }

        }

      }

    }

  }

}