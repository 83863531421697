.pills {

    @include breakpoint() {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin: 0 -8px;

    }

    li {

        @include breakpoint() {

            flex: 1 1 auto;
            margin: 8px 8px;

        }

        &:last-child {

            @include breakpoint() {

                flex: none;
                width: 100%;

            }
            @include breakpoint($small_tablet) {

                flex: 1 1 auto;
                width: auto;

            }

        }

        .pill {

            @include breakpoint() {

                padding: 0 44px 0 16px;
                display: block;

                color: $dim_gray;
                background-color: rgba($black, 0.05);

                font-size: 14px;
                line-height: 40px;
                font-weight: $medium;

                border-radius: 20px;

                text-decoration: none;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: calc(100% - 16px) center;
                background-size: 12px 12px;

                background-image: url('../images/icons/i_close.svg');

            }

            &.add {

                @include breakpoint() {

                    background-color: $white;
                    background-image: none;

                    border: solid 1px rgba($black, 0.05);

                    padding: 0 16px;

                    text-align: center;

                }

            }

        }

    }

}