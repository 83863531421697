footer {

    @include breakpoint() {

        padding: 40px 0;
        margin: auto 0 0 0;

        text-align: center;

        color: #b3b3b3;
        background-color: $dark_gray;

        font-size: 14px;
        line-height: 20px;

    }

}