.asign-new-sub {

    &.mobile {

        @include breakpoint() {

            width: 100vw;
            flex: none;

            display: block;

            margin: 16px -24px 0;
            padding: 24px;

            background-color: $anti_flash_white;

            position: relative;

        }
        @include breakpoint($small_tablet) {

            display: none;

        }

        &::before {

            @include breakpoint() {

                content: '';
                display: block;

                width: 0;
                height: 0;

                border-style: solid;
                border-width: 0 12px 16px 12px;
                border-color: transparent transparent $anti_flash_white transparent;

                position: absolute;

                top: -12px;
                right: 24px;

            }

        }

    }

    &.tablet-and-above {

        @include breakpoint() {
            display: none;
        }
        @include breakpoint($small_tablet) {

            display: block;

            width: 400px;
            padding: 32px;

            position: absolute;
            z-index: 999;

            top: 48px;
            right: 0;

            background-color: $white;
            @include box-shadow(0 4px 35px 0 rgba($black, 0.15));

        }

        &::before {

            @include breakpoint() {

                content: '';
                display: block;

                width: 0;
                height: 0;

                border-style: solid;
                border-width: 0 12px 16px 12px;
                border-color: transparent transparent $white transparent;

                position: absolute;

                top: -12px;
                right: 24px;

            }

        }

    }

    .asign-new-sub__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 18px;
            line-height: 21px;
            font-weight: $bold;

            margin: 0 0 16px 0;

        }
        @include breakpoint($small_tablet) {
            margin: 0 0 24px 0;
        }

    }

    .asign-new-sub__search {

        @include breakpoint() {

            background-color: transparent;

            border: none;
            border-bottom: solid 2px $border_color;

            padding: 0 32px 0 0;
            margin: 0 0 24px 0;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: right center;
            background-size: 24px 24px;

            background-image: url('../images/icons/i_search.svg');

        }
        @include breakpoint($small_tablet) {
            margin: 0 0 32px 0;
        }

    }

    .asign-new-sub__list {

        @include breakpoint() {

            margin: 0 0 24px 0;

        }
        @include breakpoint($small_tablet) {

            margin: 0 0 32px 0;

            //  CALCULATED 2 HEADS & 3 ITEMS TOTAL HEIGHT
            max-height: 261px;

            overflow-y: scroll;

            //  TEMPORARY REMOVE AFTER SCROLL IMPLEMENTATION
            margin-right: -15px;

        }

        .asign-new-sub__head {

            @include breakpoint() {

                color: $old_silver;

                font-size: 12px;
                line-height: 14px;
                font-weight: $bold;

                text-transform: uppercase;
                letter-spacing: 1px;

                margin: 0 0 24px;

            }
            @include breakpoint($small_tablet) {
                margin: 0 0 32px 0;
            }

        }

        .asign-new-sub__item {

            @include breakpoint() {
                margin: 0 0 24px 0;
            }
            @include breakpoint($small_tablet) {
                margin: 0 0 32px 0;
            }

            &:last-child {
                @include breakpoint() {
                    margin: 0;
                }
            }

            input[type="checkbox"] {
                & + label {
                    &::before {
                        @include breakpoint($small_tablet) {
                            margin: 0;
                        }
                    }
                }
            }
            

            .asign-new-sub__details {

                @include breakpoint() {
                    margin: 0 0 0 16px;
                }

                .asign-new-sub__item-name {

                    @include breakpoint() {

                        color: $old_silver;

                        font-size: 16px;
                        line-height: 19px;
                        font-weight: $bold;

                        display: block;

                    }

                    .highlight {
                        @include breakpoint() {
                            color: $dark_gray;
                        }
                    }

                }

                .asign-new-sub__item-id {

                    @include breakpoint() {

                        color: $old_silver;

                        font-size: 12px;
                        line-height: 14px;
                        font-weight: $medium;

                    }

                }

            }

        }

    }

}

//  ANIMATION FOR THE TABLET AND ABOVE VERSION
.asign-new-sub__trigger {

    & + .asign-new-sub {

        &.tablet-and-above {

            @include breakpoint($small_tablet) {

                max-height: 0;

                opacity: 0;
                visibility: hidden;
                overflow: hidden;

                @include transition(all 300ms ease-in-out 0ms);

            }

        }

    }

    &.is-active {

        & + .asign-new-sub {

            &.tablet-and-above {
    
                @include breakpoint($small_tablet) {

                    max-height: 999vh;
    
                    opacity: 1;
                    visibility: visible;
                    overflow: unset;
    
                }

            }
    
        }

    }

}