//	GLOBAL UNIVERSAL RULES & IMPORTS

//	GOOGLE FONT IMPORT
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800|Roboto:400,700,800');

//	FONT DECLARATION
body {
	font-family: 'Raleway', sans-serif;
}

body {

    .roboto {

        font-family: 'Roboto',sans-serif;

    }

}

//	FONT SMOOTHING
html {
	-webkit-font-smoothing: antialiased;
}

//	MIN HEIGHTS
html {
	height: 100%;
}

body {
    min-height: 100%;
    overflow-y: scroll;
}

#root {
    min-height: 100vh;
    overflow-y: hidden;
}

//	BORDERBOX SETUP
* {
	box-sizing: border-box;
}


//  BODY BACKGROUND
body {
    background-color: #E8E8E8;
}