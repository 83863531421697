.context {

    @include breakpoint() {

        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin: 0 0 24px 0;

    }
    @include breakpoint($small_tablet) {
        margin: 0 0 12px 0;
    }

    &:only-child {
        @include breakpoint($small_tablet) {
            margin: 0;
        }
    }

    .context__cell {

        &.alpha {

            @include breakpoint() {

                flex-grow: 1;

            }

        }

        &.beta {

            @include breakpoint() {

                margin: 0 0 0 auto;

                display: flex;
                flex-direction: row;

                flex-grow: 0;

                position: relative;

            }

        }

    }

    .context__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 18px;
            line-height: 24px;
            font-weight: $extra_bold;

        }

        &.large {

            @include breakpoint() {

                font-size: 18px;
                font-weight: $extra_bold;

            }

        }

    }

    .context__trigger {

        @include breakpoint() {

            color: $maximum_red;

            font-size: 12px;
            line-height: 24px;
            font-weight: $bold;

            letter-spacing: 1px;

            text-transform: uppercase;

            @include transition(all 300ms ease-in-out 0ms);

            display: block;
            white-space: nowrap;

            margin: auto 0;

            cursor: pointer;

        }

        &:hover {

            @include breakpoint() {

                color: darken($maximum_red, 10%);

            }

        }

    }

    //  CHILD MOD
    &.child {

        @include breakpoint() {

            padding: 0 0 0 40px;

            position: relative;

        }

        &::before {

            @include breakpoint() {

                content: '';
                display: block;

                height: 30px;
                width: 10px;

                border-bottom: solid 1px $old_silver;
                border-left: solid 1px $old_silver;

                position: absolute;

                bottom: 12px;
                left: 20px;

            }
            @include breakpoint($small_tablet) {
                height: 20px;
                width: 10px;
            }

        }

        .context__cell {

            &.alpha {

                .context__title {

                    @include breakpoint() {
                        color: $old_silver;
                    }

                }

            }

        }

    }

}