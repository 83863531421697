.page__head {

    @include breakpoint() {

        display: flex;
        flex-direction: row;

        justify-content: space-between;

        padding: 32px 0 0 0;

    }
    @include breakpoint($small_tablet) {

        padding: 48px 0 0 0;
        
    }
 
    .page__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 24px;
            line-height: 48px;

            font-weight: $bold;

        }
        @include breakpoint($small_tablet) {
            font-size: 32px;
        }

        &.small {
            @include breakpoint($small_tablet) {
                font-size: 24px;
            }
        }

    }

    .cta {

        @include breakpoint() {

            max-width: 180px;

            padding: 0;
            margin: 0 0 0 10px;

        }
        @include breakpoint($small_tablet) {

            max-width: none;

            flex-grow: 0;
            flex-basis: 0;

            padding: 0 20px;

        }

        &.mobile-short {

            @include breakpoint() {
                max-width: 140px;
            }
            @include breakpoint($small_tablet) {
                max-width: none;
            }

        }

    }

}