.placeholder {

    @include breakpoint() {

        color: $arsenic;

        font-size: 16px;
        line-height: 24px;
        font-weight: $bold;

        padding: 20px;

        background-color: rgba($maximum_red, 0.05);

    }
    @include breakpoint($tablet) {

        padding: 40px 30px;

    }

}