.entity-select__component {

    @include breakpoint($small_tablet) {
        position: relative;
    }

    //  LINK MADE TO LOOK LIKE A SELECt
    //  USED AS TRIGGER
    .sim-select {

        @include breakpoint() {

            color: $old_silver;

            font-size: 16px;
            line-height: 44px;
            font-weight: $medium;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: calc(100% - 16px) center;
            background-size: 12px 7px;

            background-image: url('../images/icons/i_triangle.svg');

            text-decoration: none;

            display: block;
            padding: 0 16px;

            border: solid 2px $border_color;

        }

    }

    .entity-select__container {

        @include breakpoint() {

            width: 100vw;

            padding: 24px;
            margin: 24px -24px 0;

            background-color: $anti_flash_white;

            display: none;

        }

        @include breakpoint($small_tablet) {

            position: absolute;

            top: 96px;
            left: 0;

            width: 660px;
            height: 564px;

            padding: 32px;
            margin: 0;

            background-color: $white;
            @include box-shadow(0 0 35px 0 rgba($black, 0.15));

            flex-direction: row;

        }
        @include breakpoint($small_desktop) {

            top: 96px;
            right: -16px;
            left: unset;

        }

        &::before {

            @include breakpoint($small_tablet) {

                content: '';
                display: block;

                width: 0;
                height: 0;

                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent $white transparent;

                position: absolute;
                top: -10px;
                right: 24px;

                top: -10px;
                left: calc(50% - 24px);

            }
            @include breakpoint($small_desktop) {

                left: unset;
                right: 24px;

            }

        }

        &.is-active {

            @include breakpoint() {
                display: flex;
                flex-direction: column;
            }
            @include breakpoint($small_tablet) {
                flex-direction: row;
            }

        }

        .entity-select__col-left {

            @include breakpoint() {
                margin: 0 0 24px 0;
            }
            @include breakpoint($small_tablet) {

                margin: 0;
                width: 320px;

                display: flex;
                flex-direction: column;

            }

        }

        .entity-select__col-right {

            @include breakpoint() {

                border-top: solid 2px $platinum;
                padding: 24px 0 0 0;

            }
            @include breakpoint($small_tablet) {

                width: 247px;
                padding: 0;
                margin: 0 0 0 32px;

                border: none;

                display: flex;
                flex-direction: column;

            }

        }

        .entity-select__title {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 18px;
                line-height: 24px;
                font-weight: $bold;

                margin: 0 0 16px;

            }

        }

        .entity-select__selected-title {

            @include breakpoint() {
                display: none;
            }
            @include breakpoint($small_tablet) {

                display: block;

                color: $old_silver;

                font-size: 12px;
                line-height: 24px;
                font-weight: $bold;

                text-transform: uppercase;

                margin: 0 0 16px 0;

            }

        }

        .selected-cta {

            @include breakpoint($small_tablet) {

                margin: auto 0 0 0;

            }

        }

        .f-element {

            &.search {

                @include breakpoint($small_tablet) {
                    margin: 0 0 32px 0;
                    flex-grow: 0;
                }

                input[type="text"] {

                    @include breakpoint() {

                        font-weight: $bold;

                        border-top: none;
                        border-right: none;
                        border-left: none;
                        background-color: transparent;
    
                        padding-left: 0;

                    }

                }

            }

        }

        .entity__list {

            @include breakpoint() {

                max-height: 186px;

                overflow-x: hidden;
                overflow-y: scroll;

            }
            @include breakpoint($small_tablet) {

                max-height: 310px;

            }

            li {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;

                    margin: 0 0 16px 0;

                }
                @include breakpoint($small_tablet) {

                    flex-direction: row-reverse;

                }

                .entity__details {

                    @include breakpoint() {

                        flex-grow: 1;
                        flex-shrink: 0;

                    }

                    strong {

                        @include breakpoint() {

                            color: $old_silver;

                            font-size: 16px;
                            line-height: 20px;
                            font-weight: $bold;

                            display: block;

                            margin: 0 0 8px 0;

                            .highlight {

                                @include breakpoint() {

                                    font-size: 16px;
                                    line-height: 18px;
                                    font-weight: $bold;
                                    color: $dark_gray;
                                
                                }
        
                            }

                        }

                    }

                    span {

                        @include breakpoint() {

                            color: $old_silver;

                            font-size: 12px;
                            line-height: 18px;

                        }

                    }

                }

                .entity__select {

                    @include breakpoint() {

                        flex-grow: 0;
                        flex-shrink: 0;

                    }
                    @include breakpoint($small_tablet) {


                        margin: 0 24px 0 0;

                    }

                }

            }

        }

        .entity__select-all {

            @include breakpoint() {
                display: none;
            }
            @include breakpoint($small_tablet) {

                display: block;
                margin: auto 0 0 0;

            }

        }

        .entity__selected-list {

            @include breakpoint() {

                display: flex;
                flex-direction: column;

                margin: 0 0 24px 0;

            }

            li {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;

                    margin: 0 0 16px 0;

                }

                &:last-child {

                    @include breakpoint() {
                        margin: 0;
                    }

                }

                .selected__details {

                    @include breakpoint() {

                        flex-grow: 1;
                        flex-shrink: 0;

                    }

                    strong {

                        @include breakpoint() {

                            color: $dark_gray;

                            font-size: 14px;
                            line-height: 20px;
                            font-weight: $bold;

                        }

                    }

                }

                .selected__control {

                    @include breakpoint() {

                        flex-grow: 0;
                        flex-shrink: 0;

                    }

                    //  MORPHING DEFAULT CHECKBOX
                    //  TO ROUND X ON DESKTOP

                    input[type="checkbox"] {

                        &.checkbox {

                            &.remove {

                                & + label {

                                }

                                &:checked {

                                    & + label {

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}