select {

    @include breakpoint() {

        font-family: 'Raleway',sans-serif;

		width: 100%;
		height: 50px;

		padding: 0 16px;

		border: solid 2px $border_color;

        color: $dark_gray;
        background-color: $white;

		font-size: 16px;
		font-weight: $medium;

        @include transition(all 300ms ease-in-out 0ms);

        -webkit-appearance: none;
        -moz-appearance: none;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: 12px 7px;
        background-position: calc(100% - 16px) center;

        background-image: url('../images/icons/i_triangle.svg');

	}

    &:focus {

        @include breakpoint() {

            outline: none;
            border: solid 2px $border_highlight_color;

        }

    }

}