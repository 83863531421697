.alert {

    @include breakpoint() {
        
        margin: 40px 0 0;
        padding: 36px 20px;

        display: flex;
        flex-direction: column;

    }
    @include breakpoint($small_tablet) {

        padding: 20px;
        flex-direction: row;

    }
    @include breakpoint($tablet) {
        margin: 50px 0 0;
    }

    &.no-margin {

        @include breakpoint() {
            margin: 0;
        }

    }

    .alert__icon {

        @include breakpoint() {

            width: 25px;
            height: 25px;

            display: block;
            margin: 0 auto 20px auto;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: cover;

            flex: none;

        }
        @include breakpoint($small_tablet) {
            margin: 0 24px auto 0;
        }

    }

    .alert__content {

        @include breakpoint() {

            margin: 0 0 20px 0;

            font-size: 14px;
            line-height: 24px;

        }

        &:last-child {
            @include breakpoint() {
                margin: 0;
            }
        }

    }

    //  YELLOW ALERT
    &.warning {

        @include breakpoint() {

            border: solid 2px rgba($chrome_yellow, 0.2);
            background-color: rgba($chrome_yellow, 0.05);

        }

        .alert__icon {

            @include breakpoint() {

                background-image: url('../images/icons/i_alert-warning.svg');

            }

        }
    
        .alert__content {
    
            @include breakpoint() {

                color: $grizzly;

            }

        }

    }

}