.add-role-list {

    @include breakpoint() {

        width: 100%;
        margin: 0 0 24px 0;

    }

    li {

        @include breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            margin: 0 0 24px 0;

        }
        @include breakpoint($small_tablet) {
            justify-content: flex-start;
        }

        &:last-child {
            @include breakpoint() {
                margin: 0;
            }
        }

        input[type="checkbox"].checkbox {

            & + label {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 14px;
                    line-height: 20px;
                    font-weight: $bold;

                    margin: 0;

                }

                &::before {

                    @include breakpoint() {
                        margin: 0 16px 0 0;
                    }

                }

            }

        }

    }

}