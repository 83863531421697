.f-container {

    @include breakpoint() {

        flex-grow: 1;

        display: flex;
        flex-direction: column;

    }

	form {

		@include breakpoint() {

            flex-grow: 1;
    
            display: flex;
            flex-direction: column;
    
        }

	}

}

.f-section {

    @include breakpoint() {

        flex-grow: 1;

        display: flex;
        flex-direction: column;


        margin: 0 0 32px 0;

    }

    &:last-child {

        @include breakpoint() {
            margin: 0;
        }

    }

}

.f-section__title {

    @include breakpoint() {

        color: $dark_gray;

        font-size: 18px;
        font-weight: $extra_bold;

        margin: 0 0 40px 0;

        width: 100%;

    }

}

.f-row {

	@include breakpoint() {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		margin: 0 0 24px 0;

    }
    @include breakpoint($tablet) {
        margin: 0 0 32px 0;
    }

	&:last-child {
		@include breakpoint() {
			margin: 0;
		}
	}

    &.tablet-negative-left-margin-48 {
        @include breakpoint($small_tablet) {
            margin-left: -48px;
        }
    }

	.f-element {

        @include breakpoint() {

            width: 100%;
            margin: 0 0 32px 0;

        }
        @include breakpoint($small_tablet) {

            width: auto;

            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;

            margin: 0 12px;

        }

        &:first-child {
            @include breakpoint($small_tablet) {
                margin: 0 12px 0 0;
            }
        }

        &:last-child {

            @include breakpoint() {
                margin: 0;
            }
            @include breakpoint($small_tablet) {
                margin: 0 0 0 12px;
            }

        }

		&:only-child {

			@include breakpoint() {

				flex: none;
				width: 100%;

            }
            @include breakpoint($small_tablet) {
                margin: 0;
            }

		}

        &.is-one-third {
        
            @include breakpoint($small_tablet) {
                flex:none;
                width: calc(33.333333% - 12px);
            }
        
        }
        
        &.is-two-thirds {
        
            @include breakpoint($small_tablet) {
        
                flex:none;
                width: calc(66.666667% - 12px);
        
            }
        
        }

        &.action {

            @include breakpoint() {

                position: relative;

            }

            input[type="text"] {

                @include breakpoint() {
                    padding: 0 64px 0 16px
                }

            }

            .action-copy {

                @include breakpoint() {

                    position: absolute;

                    right: 16px;
                    bottom: 12px;

                    font-size: 12px;
                    line-height: 20px;
                    font-weight: $bold;

                    padding: 0 8px;
                    display: block;

                    text-transform: uppercase;

                    border-radius: 20px;

                    color: rgba($black, 0.3);
                    background-color: rgba($black, 0.05);

                    cursor: pointer;

                }

            }

        }

        .react-select {
            &.is-danger {
                .react-select__control {
                    border-color: $maximum_red;
                }
            }
        }

        .field {
            &.is-danger {
                border-color: $maximum_red;
            }
        }

        p {
            &.is-danger {
                font-family: 'Raleway',sans-serif;
                color: $maximum_red;
            }
        }

	}

    &.inline-cta {

        .f-element {

            @include breakpoint() {

                width: auto;

                flex-basis: 1;
                flex-grow: 0;
                flex-shrink: 1;

                margin: 0 12px;

            }

            &:first-child {
                @include breakpoint() {
                    margin: 0 12px 0 0;
                }
            }

            &:last-child {
                @include breakpoint() {
                    margin: 0 0 0 12px;
                }
            }

        }

        &.space-between {
            @include breakpoint() {
                justify-content: space-between;
            }
        }

    }

    //  BORDERED
    &.border-top {

        @include breakpoint() {

            padding: 24px 0 0 0;
            margin: 0;

            border-top: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            padding: 40px 0 40px 0;

        }

    }

    &.border-bottom {

        @include breakpoint() {

            padding: 24px 0;
            margin: 0 0 24px;

            border-bottom: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            padding: 40px 0;
            margin: 0 0 40px 0;

        }

    }

}

label,
.label {

	@include breakpoint() {

		color: $label_color;

		font-size: 12px;
		font-weight: $bold;

		margin: 0 0 10px 0;

		display: block;

		text-transform: uppercase;

		letter-spacing: 1px;

	}

    &.black {

        @include breakpoint() {
            color: $dark_gray;
        }

    }

    &.extra-bottom-margin {

        @include breakpoint() {
            margin: 0 0 24px 0;
        }

    }

}

.f-description {

	@include breakpoint() {

		color: $dim_gray;

		font-size: 16px;
		line-height: 1.5;

		font-weight: $medium;

		text-align: center;

		margin: 0 0 32px 0;

	}

}

.f-title {

	@include breakpoint() {

		color: $dark_gray;

		font-size: 32px;
		line-height: 1;

		font-weight: $bold;

		text-align: center;

		margin: 0 0 32px 0;

	}

}

//  CTA CONTAINER
.f-row {

    &.cta-container {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

            margin: 8px 0 0 0;
            padding: 48px 0 0 0;

            border-top: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            flex-direction: row;
            justify-content: space-between;

        }

        .f-element {

            @include breakpoint() {
                margin: 0;
            }

            &.alpha {

                @include breakpoint($small_tablet) {

                    order: 2;

                    max-width: 220px;

                }

            }

            &.beta {

                @include breakpoint($small_tablet) {

                    order: 1;

                    flex-grow: 0;
                    flex-shrink: 1;

                }

            }

        }

    }

}

//  EXTENDABLE HEIGHT FORM