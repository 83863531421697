.entity-list {

    &.role-list {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

            padding: 48px 0;

        }

    }

}

.entity-list__item {

    &.role-item {

        @include breakpoint() {

            padding: 0 0 24px 0;
            margin: 0 0 24px 0;

            border-bottom: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            padding: 0 0 32px 0;
            margin: 0 0 32px 0;

        }


        //  BODY
        .role__body {

            @include breakpoint() {

                max-height: 0px;

                opacity: 0;
                visibility: hidden;
                overflow: hidden;

                @include transition(all 600ms ease-in-out 0ms);

            }

        }

        //  HEAD
        .role__head {

            .role__row {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    position: relative;

                }
                @include breakpoint($small_tablet) {
                    justify-content: space-between;
                }

                .role__cell {

                    &.alpha {

                        @include breakpoint() {
                            margin: 0 16px 0 0;
                        }

                    }

                    &.beta {

                        @include breakpoint() {

                            display: flex;
                            flex-direction: row;

                            max-width: calc(100% - 100px);

                        }
                        @include breakpoint($small_tablet) {

                            position: absolute;

                            top: 12px;
                            left: 120px;

                        }

                    }

                    &.gamma {

                        @include breakpoint() {

                            flex: none;
                            width: 100%;

                            padding: 24px 0 0;

                        }
                        @include breakpoint($small_tablet) {

                            max-width: 380px;

                            position: absolute;

                            bottom: 12px;
                            left: 120px;

                            padding: 0;

                        }

                    }

                    &.delta {

                        @include breakpoint() {

                            position: absolute;

                            top: 0;
                            right: 0;

                        }
                        @include breakpoint($small_tablet) {

                            position: static;

                            display: flex;
                            flex-direction: row;

                        }

                    }

                }

            }

            &.is-active {

                & + .role__body {

                    @include breakpoint() {

                        padding: 24px 0 0 0;

                        max-height: 999vh;

                        opacity: 1;
                        visibility: visible;
                        overflow: auto;

                    }
                    @include breakpoint($small_tablet) {
                        padding: 32px 0 0 0;
                    }

                }

            }

        }

        .role-type__icon {

            @include breakpoint() {

                width: 40px;
                height: 40px;

                display: block;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;
                background-size: 40px 40px;

            }
            @include breakpoint($small_tablet) {

                width: 80px;
                height: 80px;

                background-size: 80px 80px;

            }

            &.admin {
                @include breakpoint() {
                    background-image: url('../images/icons/i_mini_user_type_admin.svg');
                }
                @include breakpoint($small_tablet) {
                    background-image: url('../images/icons/i_user_type_admin.svg')
                }
            }

            &.manager,
            &.user {
                @include breakpoint() {
                    background-image: url('../images/icons/i_mini_user_type_user.svg');
                }
                @include breakpoint($small_tablet) {
                    background-image: url('../images/icons/i_user_type_user.svg')
                }
            }

        }

        .role-type__name {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 18px;
                line-height: 20px;
                font-weight: $bold;

                margin: auto 0;

            }
            @include breakpoint($small_tablet) {
                line-height: 24px;
            }

        }

        .role-type__description {

            @include breakpoint() {

                color: $old_silver;

                font-size: 16px;
                line-height: 24px;
                font-weight: $medium;

            }

        }

        .role-type__expand {

            @extend .f-mini-link;

            line-height: 40px;

            @include breakpoint($small_tablet) {
                margin: auto 0;
            }

            .role-type__open {
                @include breakpoint() {
                    display: block;
                }
            }

            .role-type__close {
                @include breakpoint() {
                    display: none;
                }
            }

            &.is-active {

                .role-type__open {
                    @include breakpoint() {
                        display: none;
                    }
                }

                .role-type__close {
                    @include breakpoint() {
                        display: block;
                    }
                }

            }

        }

    }

}