.placeholder-pills {

    @include breakpoint() {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    li {

        @include breakpoint() {
            width: calc(50% - 8px);
        }
        @include breakpoint($small_tablet) {
            width: calc(33.333333% - (32px / 3));
            margin: 24px;
        }

        &:nth-child(odd) {
            @include breakpoint() {
                margin: 0 8px 16px 0;
            }
            @include breakpoint($small_tablet) {
                margin: 0 8px 16px 8px;
            }
        }
        &:nth-child(even) {
            @include breakpoint() {
                margin: 0 0 16px 8px;
            }
            @include breakpoint($small_tablet) {
                margin: 0 8px 16px 8px;
            }
        }
        &:nth-child(3n) {
            @include breakpoint($small_tablet) {
                margin: 0 0 16px 8px;
            }
        }
        &:nth-child(3n-2) {
            @include breakpoint($small_tablet) {
                margin: 0 8px 16px 0;
            }
        }

        .placeholder-pill {

            @include breakpoint() {

                padding: 0 10px 0 16px;
                display: block;
    
                color: $dim_gray;
                background-color: rgba($black, 0.05);
    
                font-size: 14px;
                line-height: 40px;
                font-weight: $medium;
    
                border-radius: 20px;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                justify-content: space-between;

            }

            &::after {

                @include breakpoint() {

                    content: '?';
                    display: block;

                    width: 20px;
                    height: 20px;

                    border-radius: 20px;

                    font-size: 14px;
                    line-height: 20px;
                    font-weight: $medium;

                    text-align: center;

                    margin: auto 0;

                    background-color: rgba($black, 0.05);

                }

            }

        }

    }

}