.rep__list {

    @include breakpoint() {
        margin: 0 0 24px 0;
    }
    @include breakpoint($tablet) {
        margin: 0 0 58px 0;
    }

    .rep__item {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

            padding: 24px 0;

        }

        .rep__designation {

            @include breakpoint() {
                margin: 0 0 24px 0;
            }

            .rep__name {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 18px;
                    line-height: 21px;
                    font-weight: $extra_bold;

                    margin: 0 0 8px 0;

                }

            }

            .rep__id {

                @include breakpoint() {

                    color: $old_silver;

                    font-size: 14px;
                    line-height: 16px;

                }

            }

        }

        .rep__row {

            @include breakpoint() {

                padding: 0 0 16px 0;
                margin: 0 0 16px 0;

                border-bottom: solid 2px rgba($platinum, 0.5);

            }
            @include breakpoint($small_tablet) {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                padding: 8px 10px;
                margin: 0;

                border: none;

            }
            @include breakpoint($tablet) {

                padding: 16px 20px;

            }

            .rep__cell {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                }
                @include breakpoint($small_tablet) {

                    flex: none;

                    margin: 0 10px 0 0;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                }

                &:last-child {
                    @include breakpoint($small_tablet) {
                        margin: 0;
                    }
                }

                &::before {

                    @include breakpoint() {

                        display: block;

                        color: $old_silver;

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $medium;

                        margin: 0 auto 0 0;

                    }
                    @include breakpoint($large_mobile) {

                        font-size: 16px;
                        line-height: 24px;

                    }
                    @include breakpoint($small_tablet) {
                        display: none;
                    }

                }

                &.sub {

                    @include breakpoint($small_tablet) {
                        width: 130px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Subscription';
                        }
                    }

                }

                &.type {

                    @include breakpoint($small_tablet) {
                        width: 130px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Type';
                        }
                    }

                }

                &.auth {

                    @include breakpoint($small_tablet) {
                        width: 95px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Authentication';
                        }
                    }

                }

                &.data {

                    @include breakpoint($small_tablet) {
                        width: 95px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Data Capture';
                        }
                    }

                }

                &.bar {

                    @include breakpoint($small_tablet) {
                        width: 95px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Barcode Only';
                        }
                    }

                }

                &.total {

                    @include breakpoint($small_tablet) {
                        width: 105px;
                    }
                    @include breakpoint($tablet) {

                    }

                    &::before {
                        @include breakpoint() {
                            content: 'Total';
                        }
                    }

                }

                &.sub,
                &.type,
                &.auth,
                &.data,
                &.bar {

                    @include breakpoint($tablet) {
                        width: calc(100% / 6);
                    }

                }

            }

        }

        .rep__head {

            @include breakpoint() {
                display: none;
            }
            @include breakpoint($small_tablet) {

                background-color: $anti_flash_white;
                display: block;

            }

            .rep__row {

                .rep__cell {

                    @include breakpoint($small_tablet) {

                        color: $dark_gray;

                        font-size: 12px;
                        line-height: 20px;
                        font-weight: $bold;

                    }
                    @include breakpoint($tablet) {
                        font-size: 16px;
                    }

                }

            }

        }

        .rep__body {

            .rep__row {

                .rep__cell {

                    &.auth,
                    &.data,
                    &.bar,
                    &.total {

                        @extend .roboto;

                    }

                    @include breakpoint() {

                        color: $dark_gray;

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $medium;

                    }
                    @include breakpoint($large_mobile) {

                        font-size: 16px;
                        line-height: 24px;

                    }
                    @include breakpoint($small_tablet) {

                        font-size: 12px;
                        line-height: 20px;

                    }
                    @include breakpoint($tablet) {
                        font-size: 14px;
                    }

                }

            }

        }

        .rep__foot {

            @include breakpoint($small_tablet) {
                border-top: solid 2px $anti_flash_white;
            }

            .rep__row {

                &:only-child {

                    @include breakpoint() {
                        padding: 0;
                        margin: 0 0 16px;
                        border-bottom: none;
                    }
                    @include breakpoint($small_tablet) {
                        padding: 16px 10px;
                        margin: 0;
                    }
                    @include breakpoint($tablet) {
                        padding: 16px 20px;
                        margin: 0;
                    }

                }

                .rep__cell {

                    &.auth,
                    &.data,
                    &.bar,
                    &.total {

                        @extend .roboto;

                    }

                    @include breakpoint($small_tablet) {

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $bold;

                    }
                    @include breakpoint($tablet) {
                        font-size: 16px;
                    }

                    &.sub,
                    &.type {

                        @include breakpoint() {
                            display: none;
                        }
                        @include breakpoint($small_tablet) {
                            display: block;
                        }

                        &::before {
                            @include breakpoint() {
                                display: none;
                            }
                        }

                    }

                }

            }

        }

        //  CONTAINER FOR CHILDREN ASOCIATED WITH PARRENT
        .rep__children {

            @include breakpoint() {

                margin: 0 -24px;
                padding: 0 24px;

                background-color: $x12gray;
                @include box-shadow(inset 0 -4px 8px 0 rgba(0,0,0,0.15));

            }
            @include breakpoint($tablet) {

                margin: 0;
                padding: 0 0 0 24px;

            }

        }

        //  PRIMARY OVERRIDES
        &.primary {

            @include breakpoint($small_tablet) {

                padding: 0 0 48px 0;
                margin: 0 0 24px 0;

                border-bottom: solid 2px $platinum;

            }

            .rep__row {

                .rep__cell {

                    @include breakpoint($small_tablet) {

                        width: calc(20% - 10px);

                    }

                    &.auth,
                    &.data,
                    &.bar,
                    &.total {

                        @include breakpoint($small_tablet) {

                            text-align: right;
                            display: block;

                        }

                    }

                }

            }

            .rep__head {

            }

            .rep__body {

            }

            .rep__foot {

                @include breakpoint($small_tablet) {

                    background-color: $anti_flash_white;

                }

                .rep__row {

                    .rep__cell {

                        @include breakpoint($small_tablet) {

                            font-size: 18px;
                            font-weight: $bold;

                        }

                    }

                }

            }

        }

    }

}

//  REPORT HEAD & FOOT
.rep-head {

    margin: 0 0 16px;

    @include breakpoint($small_tablet) {

        display: flex;
        flex-direction: row;

    }

    &:last-child {
        @include breakpoint() {
            margin: 0 0 40px;
        }
    }

    .rep-head__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 24px;
            line-height: 32px;
            font-weight: $bold;

            margin: 0 0 16px 0;

        }
        @include breakpoint($small_tablet) {
            margin: 0;
        }

    }

    .rep-head__control {

        @extend .f-mini-link;

        @include breakpoint() {

            display: inline-block;

            line-height: 24px;

            padding: 0 0 0 32px;
            margin: auto 0 auto auto;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: left center;
            background-size: 18px 20px;

            background-image: url('../images/icons/i_download_red.svg');

        }

    }

}