.nav {

    @include breakpoint() {

        background-color: $white;

        max-height: 100vh;
        overflow-y: scroll;

        @include box-shadow(0 4px 15px 0 rgba($black,0.05));

    }
    @include breakpoint($small_tablet) {
        overflow-y: unset;
    }

    .nav__row {

        @include breakpoint() {

            padding: 20px 0;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

        }
        @include breakpoint($small_tablet) {

            padding: 20px 0;

        }

        .assure-id-logo-black-red {

            @include breakpoint() {

                width: 111px;
                height: 36px;

                display: block;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: left center;

                background-image: url('../images/logos/acuant-logo.svg');

                flex-shrink: 0;
                flex-grow: 0;

                position: relative;
                z-index: 99;

            }
            @include breakpoint($small_tablet) {

                height: 40px;

            }

        }

        .nav__container {

            @include breakpoint() {
                width: 100%;
                margin: -36px 0 0 0;
            }
            @include breakpoint($small_tablet) {

                width: auto;
                margin: 0;

                flex-basis: 1;
                flex-grow: 1;
                flex-shrink: 0;

            }

            .nav__menu {

                @include breakpoint() {

                    max-height: 0;

                    opacity: 0;
                    visibility: hidden;

                    overflow: hidden;

                }
                @include breakpoint($small_tablet) {
                    
                    max-height: none;

                    opacity: 1;
                    visibility: visible;

                    overflow: unset;

                    display: flex;
                    flex-direction: row;

                }

            }

            .nav__trigger {

                @include breakpoint() {

                    width: 36px;
                    height: 36px;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;

                    padding: 11px 0;
                    margin: 0 0 0 auto;

                }
                @include breakpoint($small_tablet) {
                    display: none;
                }

                &::before,
                &::after {

                    @include breakpoint() {
                    
                        content: '';
                        display: block;

                        background-color: $granite_gray;
                        height: 2px;

                        @include transition(all 300ms ease-in-out 0ms);

                    }

                }

                &::before {
                    @include breakpoint() {
                        width: 28px;
                    }
                }

                &::after {
                    @include breakpoint() {
                        width: 19px;
                    }
                }

                &.is-active {

                    @include breakpoint() {
                        margin: 0 0 26px auto;
                    }

                    &::before,
                    &::after {

                        @include breakpoint() {
                            transform-origin: center center;
                        }

                    }

                    &::before {
                        @include breakpoint() {
                            @include transform(rotate(45deg) translate3d(0,8px,0));
                        }
                    }
    
                    &::after {
                        @include breakpoint() {
                            width: 28px;
                            @include transform(rotate(-45deg) translate3d(0,-8px,0));
                        }
                    }

                    & + .nav__menu {

                        @include breakpoint() {

                            opacity: 1;
                            visibility: visible;

                            width: calc(100vw - 40px);
                            min-height: calc(100vh - 88px);
                            max-height: 999vh;        

                            display: flex;
                            flex-direction: column;

                        }
                        @include breakpoint($small_tablet) {

                            flex-direction: row;
                            flex-wrap: wrap;
                            align-content: center;

                        }

                    }

                }

            }


        }

    }

}

//  SUB NAVIGATIONS
.sub-navigation {

    @include breakpoint() {

        background-color: $white;

        @include box-shadow(0 4px 15px 0 rgba($black,0.05));

        position: relative;
        z-index: 9;

    }

}