//  FORM OVERRIDES OR RULES FOR CREATE ACCOUNT FORMS

.account-name {

    .f-row {

        &.cta-container {

            .f-element {

                @include breakpoint() {
                    margin: 0;
                }

                &.alpha {

                    @include breakpoint($small_tablet) {

                        order: 2;

                        flex: none;
                        max-width: 230px;

                        margin: 0 0 0 auto;

                    }

                }

                &.beta {

                    @include breakpoint($small_tablet) {

                        order: 1;

                        flex: none;
                        width: auto;

                    }

                }

            }

        }

    }

}

.asign-admin {

    .f-row {

        &.cta-container {

            .f-element {

                @include breakpoint() {
                    margin: 0;
                }

                &.alpha {
                    @include breakpoint() {
                        width: 100%;
                    }
                    @include breakpoint($small_tablet) {

                        order: 3;

                        flex: none;
                        max-width: 220px;

                    }
                }

                &.beta {
                    @include breakpoint() {
                        width: 50%;
                    }
                    @include breakpoint($small_tablet) {

                        order: 1;

                        flex: none;
                        width: auto;

                    }
                }

                &.gamma {
                    @include breakpoint() {
                        width: 50%;
                    }
                    @include breakpoint($small_tablet) {

                        order: 2;

                        flex: none;
                        max-width: 230px;

                        margin: 0 0 0 auto;

                    }
                }

            }

        }

    }

}

//  F SECTION OVERRRIDE
.account-name,
.asign-admin {

    .f-section {

        @include breakpoint($small_tablet) {

            height: calc(100vh - 290px);

            display: flex;
            flex-direction: column;

        }

        .f-row {

            @include breakpoint() {
                flex-shrink: 0;
            }

            &.cta-container {

                @include breakpoint() {
                    margin: 8px 0 0 0;
                }

            }

        }

    }

}