//	TYPOGRAPHIC ELEMENTS

h2 {
	@include breakpoint() {
		font-size: 24px;
		line-height: 1;
	}
	&.extra-bold {
		@include breakpoint() {
			font-weight: $extra_bold;
		}
	}
}

p {
	@include breakpoint() {
		font-size: 16px;
		line-height: 1.5;
	}
	&.small {
		@include breakpoint() {
			font-size: 12px;
		}
    }
    &.sub-note {
        @include breakpoint() {

            color: $dark_gray;

            font-size: 14px;
            line-height: 24px;
            font-style: italic;

        }
    }
}

em {
	@include breakpoint() {
		font-style: italic;
	}
}

.sub-note-title {

    @include breakpoint() {

        color: $dark_gray;

        font-size: 18px;
        line-height: 20px;
        margin: 0 0 20px 0;

        font-style: italic;

        font-weight: $bold;

    }

}


.page-title {

    @include breakpoint() {

        color: $dark_gray;

        font-size: 24px;
        font-weight: $bold;

        margin: 0 0 40px 0;

        width: 100%;

    }
    @include breakpoint($tablet) {
        margin: 0 0 50px 0;
    }

}