.page__sub-head {

    @include breakpoint() {

        display: flex;
        flex-direction: row;

        justify-content: space-between;

        padding: 24px 0 0;
        margin: 0 0 40px 0;

    }

    .filter__container {

        @include breakpoint() {
            display: none;
        }
        @include breakpoint($small_tablet) {
            display: flex;
        }

    }

    .search__container {

        @include breakpoint() {

            width: 100%;

        }
        @include breakpoint($small_tablet) {

            max-width: 320px;

        }

    }

}