.account__list {

    @include breakpoint() {

        margin: 0 -24px;
        padding: 0 0 96px;

        width: calc(100% + 48px);

    }
    @include breakpoint($tablet) {

        margin: 0;
        padding: 0 0 96px;

        width: auto;

    }

    .account__row {

        @include breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            padding: 24px;

            position: relative;

        }

        .account__cell {

            &.alpha {

                @include breakpoint() {
                    display: none;
                }
                @include breakpoint($small_tablet) {

                    display: block;

                    width: 18px;
                    margin: 0 16px 0 0;

                }
                @include breakpoint($tablet) {

                }

            }

            &.beta {

                @include breakpoint() {

                    width: 100%;

                    margin: 0 0 24px 0;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                }
                @include breakpoint($small_tablet) {

                    width: auto;

                    flex-basis: 0;
                    flex-grow: 1;
                    flex-shrink: 1;

                    margin: 0;

                }
                @include breakpoint($tablet) {

                }

                //  DEFAULT WIDTHS
                .account__name {
                    @include breakpoint() {
                        max-width: calc( 100% - 46px );
                    }
                }
                .account__details {
                    @include breakpoint() {
                        width: 100%;
                    }
                }


                //  MOBILE ONLY INLINE TOGGLE
                .account__toggle {

                    @include breakpoint() {
                        margin-right: 16px;
                    }

                    &.mobile-only {

                        @include breakpoint() {
                        }
                        @include breakpoint($small_tablet) {
                            display: none;
                        }

                    }

                    //  TARGETING ACCOUNT NAMES ONLY AFTER AN EXISTING ACCOUNT TOGGLE

                    & + .account__name {

                        @include breakpoint() {
                            max-width: calc( 100% - 16px - 18px - 46px );
                        }

                    }

                }

            }

            &.gamma,
            &.delta {

                @include breakpoint() {

                    flex: none;
                    width: 50%;

                }
                @include breakpoint($small_tablet) {

                    width: 150px;

                }
                @include breakpoint($tablet) {

                    width: 200px;

                }

            }

            &.gamma {

                @include breakpoint() {
                    text-align: left;
                }
                @include breakpoint($small_tablet) {

                }
                @include breakpoint($tablet) {

                }

            }

            &.delta {

                @include breakpoint() {
                    text-align: right;
                }
                @include breakpoint($small_tablet) {
                    text-align: left;
                }
                @include breakpoint($tablet) {

                }

            }

            &.epsilon {

                @include breakpoint() {

                    position: absolute;

                    top: 24px;
                    right: 24px;

                }
                @include breakpoint($small_tablet) {

                    position: static;

                    width: 80px;

                    text-align: right;

                }
                @include breakpoint($tablet) {

                }

            }

        }

    }

    .account__head {

        @include breakpoint() {
            display: none;
        }
        @include breakpoint($small_tablet) {

            display: block;

            background-color: $anti_flash_white;

        }

        .account__row {

            @include breakpoint($small_tablet) {
                padding: 10px 24px;
                border: none;
            }

            .account__cell {

                @include breakpoint($small_tablet) {

                    color: $old_silver;

                    font-size: 12px;
                    font-weight: $bold;
                    letter-spacing: 1px;

                    text-transform: uppercase;

                }

                &.alpha {
                    @include breakpoint($small_tablet) {
                        order: 2;
                    }
                }
                &.beta {
                    @include breakpoint($small_tablet) {
                        order: 1;
                    }
                }
                &.gamma {
                    @include breakpoint($small_tablet) {
                        order: 3;
                    }
                }
                &.delta {
                    @include breakpoint($small_tablet) {
                        order: 4;
                    }
                }
                &.epsilon {
                    @include breakpoint($small_tablet) {
                        order: 5;
                    }
                }

            }

        }

    }

    .account__foot {

        @include breakpoint() {

            display: flex;
            flex-direction: row;

            padding: 24px;

        }
        @include breakpoint($tablet) {
            padding: 24px 0;
        }

        .account__refresh {

            @include breakpoint() {

                color: $old_silver;

                font-size: 16px;
                line-height: 24px;

                font-weight: $medium;

                padding: 0 0 0 32px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: left center;
                background-size: 24px 24px;

                background-image: url('../images/icons/i_refresh.svg');

                cursor: pointer;

            }

        }

    }

    .account__item {

        &.white {
            background-color: #fff;
        }
        .account__children {

            @include breakpoint() {
                background-color: $anti_flash_white;
            }
            @include breakpoint($small_tablet) {
                padding: 0 0 0 56px;
            }

            .account__item {

                @include breakpoint() {
                    position: relative;
                }

                .account__row {
                    @include breakpoint() {
                        border: none;
                    }
                }

            }

        }

        //  ITEM ELEMENTS

        .account__name {

            &.loading-placeholder {
                background-color: #E5E5E5;
                width: 50%;
                height: 16px;
                margin-top: 0;
            }

            @include breakpoint() {

                color: $dark_gray;

                font-size: 18px;
                line-height: 24px;

                font-weight: $bold;

                display: flex;
                flex-direction: row;

                cursor: pointer;

                @include transition(all 300ms ease-in-out 0ms);

            }

            &:hover {

                @include breakpoint($small_desktop) {
                    color: $old_silver;
                }

            }

            .root__account {

                @include breakpoint() {

                    color: $white;
                    background-color: $black;

                    font-size: 12px;
                    line-height: 20px;

                    font-weight: $extra_bold;

                    display: inline-block;

                    padding: 0 8px;
                    margin: auto 0 auto 16px;

                    border-radius: 20px;

                }

            }

        }

        .account__details {

            @include breakpoint() {
                padding: 24px 0 0 0;
            }
            @include breakpoint($small_tablet) {
                padding: 14px 0 0 0;
            }

            li {

                &.loading-placeholder {
                    background-color: #E5E5E5;
                    width: 50%;
                    height: 12px;
                    margin-top: 8px;
                }

                @include breakpoint() {

                    color: $old_silver;

                    font-size: 16px;
                    line-height: 24px;

                    font-weight: $medium;

                }
                @include breakpoint($small_tablet) {
                    font-size: 14px;
                }

                strong {

                    @include breakpoint() {
                        font-weight: $bold;
                    }

                }

            }

        }

        .value-mobile {

            @include breakpoint() {

                display: block;

                color: $old_silver;

                font-size: 14px;
                line-height: 24px;

                font-weight: $bold;

            }
            @include breakpoint($small_tablet) {
                display: none;
            }

        }

        .value-tablet {

            &.loading-placeholder {
                background-color: #E5E5E5;
                width: 50%;
                height: 16px;
            }

            @include breakpoint() {

                display: none;

                color: $old_silver;

                font-size: 14px;
                line-height: 24px;

                font-weight: $bold;

            }
            @include breakpoint($small_tablet) {
                display: block;
            }

        }

        .account____action {

            @include breakpoint() {

                color: $maximum_red;

                font-size: 12px;
                line-height: 24px;

                font-weight: $bold;

                display: block;

                text-transform: uppercase;

                letter-spacing: 1px;

                cursor: pointer;

            }
            @include breakpoint($small_tablet) {

                display: inline-block;

            }

        }

        .account__toggle {

            @include breakpoint() {

                width: 18px;
                height: 18px;

                display: block;

                position: relative;

                margin: 3px 0;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;
                background-size: 18px 18px;

            }

            &.loading-placeholder {
                margin-top: 0;
                @include breakpoint() {
                    background-image: url('../images/icons/i_placeholder.svg');
                }
            }

            &.open {
                
                @include breakpoint() {
                    background-image: url('../images/icons/i_add.svg');
                }

            }

            &.close {
                
                @include breakpoint() {
                    background-image: url('../images/icons/i_substract.svg');
                }

            }

        }

        //  CASE WHEN CHILDREN EXISTS
        //  PARRENT HEAD SHOULD ALSO BE GRAYED
        .account__row {

            &:not(:only-child) {
                @include breakpoint() {
                    background-color: $anti_flash_white;
                }
            }

        }

    }

}

.account__list {

    .account__item {

        @include breakpoint() {
            border-bottom: solid 2px $platinum;
        }

        &.white {
        }

        .account__children {

            @include breakpoint($small_tablet) {
                padding: 0 0 0 56px;
            }

            .account__children {
                @include breakpoint($small_tablet) {
                    padding: 0 0 0 32px;
                }
            }

            .account__item {

                @include breakpoint($small_tablet) {
                    border-top: solid 2px $platinum;
                    border-bottom: none;
                }

                .account__row {
                    @include breakpoint($small_tablet) {
                        padding-left: 0;
                    }   
                }

            }

        }

    }

}