.f-element {

    &.search {

        @include breakpoint() {

            position: relative;

        }

        input[type="text"] {

            @include breakpoint() {
                padding: 0 16px 0 48px
            }

        }

        input[type="submit"] {

            &.search-submit {

                @include breakpoint() {

                    width: 48px;
                    height: 48px;

                    padding: 0;

                    position: absolute;
                    top: 0;
                    left: 0;

                    background-color: transparent;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center;

                }

                &.search-icon {

                    @include breakpoint() {
                        background-image: url('../images/icons/i_search.svg');
                        background-size: 24px 24px;
                    }

                }

                &.clear-icon {

                    @include breakpoint() {
                        background-image: url('../images/icons/i_clear.svg');
                        background-size: 18px 18px;
                    }

                }

            }

        }

    }

}