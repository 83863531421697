//  MOBILE ONLY
.aum {

    &.mobile {

        @include breakpoint() {

            width: 100vw;

            margin: 0 -24px 32px;
            padding: 24px 24px 24px 24px;

            background-color: $anti_flash_white;

            position: relative;

        }
        @include breakpoint($small_tablet) {
            display: none;
        }

        &::before {

            @include breakpoint() {

                content: '';
                display: block;

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent $anti_flash_white transparent;

                position: absolute;
                top: -12px;
                right: 48px;

            }

        }

    }

    .aum__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 18px;
            line-height: 24px;
            font-weight: $bold;

            margin: 0 0 24px 0;

        }

    }

    .aum__list {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

        }

        .aum__row {

            @include breakpoint() {

                display: flex;
                flex-direction: row;

                margin: 0 0 24px 0;

            }

            .aum__cell {

                &.alpha {

                    @include breakpoint() {

                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: 0;

                    }

                }

                &.beta {

                    @include breakpoint() {

                        display: flex;
                        flex-direction: column;

                    }

                    label {

                        @include breakpoint() {
                            margin: auto 0 auto auto;
                        }

                    }

                }

                .aum__name {

                    @include breakpoint() {

                        color: $dark_gray;

                        font-size: 16px;
                        line-height: 20px;
                        font-weight: $bold;

                        display: block;

                    }

                }

                .aum__email {

                    @include breakpoint() {

                        color: $old_silver;

                        font-size: 14px;
                        line-height: 20px;
                        font-weight: $medium;

                        display: block;

                    }

                }

            }

        }

    }

}