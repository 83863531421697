.entity-list {

    .entity-list__item {
        
    }

    .entity-list__group-head {

        @include breakpoint() {
            margin: 24px 0;
        }
        @include breakpoint($small_tablet) {
            margin: 32px 0;
        }

        &:first-child {
            @include breakpoint() {
                margin-top: 0;
            }
        }

        .group-head__name {

            @include breakpoint() {

                color: $old_silver;

                font-size: 12px;
                line-height: 14px;
                font-weight: $bold;

                letter-spacing: 1px;

                text-transform: uppercase;

            }

        }

    }

}