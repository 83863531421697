//  DEFAULT STATE
.e-modal {

    .e-modal__overlay {

        @include breakpoint() {

            position: fixed;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            z-index: 99;

            background-color: $white;

            @include animation(fade_in_up 600ms both);

        }

    }

    .e-modal__content {

        @include breakpoint() {

            min-height: 100vh;
            max-height: 100vh;

            -webkit-overflow-scrolling: touch;

            overflow: auto;

            outline: none;

            position: relative;

        }

    }

    .e-modal__wrapper {

        @include breakpoint() {

            padding: 24px;

            display: flex;
            flex-direction: column;

        }
        @include breakpoint($small_tablet) {

            padding: 100px 0;

            width: 660px;
            margin: 0 auto;

            min-height: 100vh;

        }

    }

}

.e-modal__head {

    @include breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 0 0 50px 0;

        flex-grow: 0;
        flex-shrink: 0;

    }

    .e-modal__title {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 24px;
            line-height: 30px;
            font-weight: $bold;

        }
        @include breakpoint($small_tablet) {

            font-size: 32px;
            line-height: 40px;

        }

    }

    .e-modal__close {

        @include breakpoint() {

            width: 30px;
            height: 30px;

            display: block;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: 18px 18px;

            background-image: url('../images/icons/i_close.svg');

            cursor: pointer;

        }
        @include breakpoint($small_tablet) {

            width: 40px;
            height: 40px;

            background-size: 24px 24px;

            position: absolute;

            top: 40px;
            right: 40px;

        }

    }

    .steps__container {

        @include breakpoint() {
            display: none;
        }
        @include breakpoint($small_tablet) {
            display: block;
        }

    }

}

.e-modal__body {

    @include breakpoint() {

        flex-grow: 1;
        display: flex;
        flex-direction: column;

    }

}

//  OPEN
.e-modal--is-open {

    .e-modal {

        .e-modal__overlay {
        }

    }

}