input[type="radio"] {

    &.custom {

        &.bullet {

            @include breakpoint() {
                display: none;
            }

            & + label {

                @include breakpoint() {

                    color: $black;

                    font-size: 14px;
                    line-height: 24px;

                    margin-top: 24px;

                    position: relative;

                    display: flex;
                    flex-direction: row;

                    text-transform: unset;

                }

                &::before,
                &::after {

                    @include breakpoint() {

                        content: '';
                        display: block;

                    }

                }

                &::before {

                    @include breakpoint() {

                        width: 24px;
                        height: 24px;

                        margin: 0 20px 0 0;

                        background-color: $white;

                        border: solid 2px $border_color;
                        border-radius: 50%;

                        box-sizing: border-box;

                    }

                }

                &::after {

                    @include breakpoint() {

                        width: 12px;
                        height: 12px;

                        background-color: $granite_gray;

                        border-radius: 50%;

                        position: absolute;
                        top: 6px;
                        left: 6px;

                        transform-origin: center center;

                        @include transition(all 300ms ease-in-out 0ms);
                        @include transform(scale(0));
                        opacity: 0;
                        

                    }

                }

            }

            &:checked {

                & + label {

                    &::after {

                        @include breakpoint() {

                            @include transform(scale(1));
                            opacity: 1;

                        }

                    }

                }

            }

        }

    }

}