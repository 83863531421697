input[type="checkbox"] {

    @include breakpoint() {
        display: none;
    }

    & + label {

        &.toggle {

            @include breakpoint() {
    
                width: 40px;
                height: 24px;
    
                display: block;
    
                border-radius: 12px;
                margin: 0;
    
                background-color: $platinum;
    
                cursor: pointer;
    
                display: flex;
                flex-direction: row;
    
                @include transition(all 300ms ease-in-out 0ms);
    
                will-change: background;
    
                @include user-select(none);
    
            }
    
            &::before {
    
                @include breakpoint() {
    
                    content: '';
    
                    width: 16px;
                    height: 16px;
    
                    display: block;
    
                    border-radius: 50%;
    
                    background-color: $white;
                    @include box-shadow(0 2px 4px 0 rgba($black, 0.2));
    
                    margin: 4px 20px 4px 4px;
    
                    @include transition(all 300ms ease-in-out 0ms);
    
                    will-change: margin;
    
                }
    
            }
    
        }

    }

    &:checked {

        & + label {

            &.toggle {

                @include breakpoint() {
                    background-color: $ocean_green;
                }
    
                &::before {
                    @include breakpoint() {
                        margin: 4px 4px 4px 20px;
                    }   
                }
    
            }

        }

    }


    //  DISABLED
    &:disabled {

        & + label {

            &.toggle {

                @include breakpoint() {
                    background-color: $platinum;
                }

            }

        }

        &:checked {

            & + label {

                &.toggle {

                    @include breakpoint() {
                        background-color: $platinum;
                    }

                }

            }

        }

    }

}
