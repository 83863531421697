.tabs {

    @include breakpoint() {

        display: flex;
        flex-direction: row;

    }

    li {

        @include breakpoint() {

            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;

        }
        //  SWITCHING TO DESKTOP TYPE NAV FROM SMALL MOBILE
        //  BECAUSE AS IS / LOOKS WEIRD
        @include breakpoint($mobile) {

            flex-grow: 0;
            flex-shrink: 0;

            margin: 0 40px 0 0;

        }

        &:last-child {

            @include breakpoint($mobile) {
                margin: 0;
            }

        }

        .tab {

            @include breakpoint() {

                color: $arsenic;

                font-size: 16px;
                line-height: 20px;
                font-weight: $medium;

                padding: 30px 0 0;

                text-decoration: none;

                text-align: center;

                display: flex;
                flex-direction: column;

            }
            @include breakpoint($mobile) {

                white-space: nowrap;

            }

            &.is-current {

                @include breakpoint() {

                    font-weight: $bold;

                    position: relative;

                }

                &::after {

                    @include breakpoint() {

                        content: '';
                        display: block;

                        width: 24px;
                        height: 4px;

                        background-color: $arsenic;

                        margin: 26px auto 0 auto;

                    }
                    @include breakpoint($mobile) {
                        margin: 26px auto 0 0;
                    }

                }

            }

        }

    }

}