section {

    &.subscriptions,
    &.users,
    &.reports {

        @include breakpoint() {
            background-color: $white;
        }

    }

}