.permission-list {

    @include breakpoint() {
        width: 100%;
    }

    .premission-group {

        @include breakpoint() {

            padding: 0 0 24px 0;
            margin: 0 0 24px 0;

            border-bottom: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            padding: 0 0 32px 0;
            margin: 0 0 32px 0;

        }

        &:last-child {

            @include breakpoint() {

                padding: 0;
                margin: 0;
                border: none;

            }

        }

        .permission-group__head {

            @include breakpoint() {

                color: $old_silver;

                font-size: 12px;
                line-height: 14px;
                font-weight: $bold;

                text-transform: uppercase;

                margin: 0 0 24px;
                letter-spacing: 1px;

            }

        }

        .permission-group__list {

            .permission-group__row {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    margin: 0 0 24px 0;

                }

                &:last-child {

                    @include breakpoint() {
                        margin: 0;
                    }

                }

                &:only-of-type {

                    @include breakpoint() {
                        margin: 0 0 24px 0;
                    }
                    @include breakpoint($small_tablet) {
                        margin: 0;
                    }
    
                }

                .permission-group__cell {

                    .permission__name {

                        @include breakpoint() {

                            color: $dark_gray;

                            font-size: 16px;
                            line-height: 24px;
                            font-weight: $medium;

                            display: block;

                        }

                    }

                    label {

                        @include breakpoint() {
                            margin: 0;
                        }

                    }

                }

            }

        }

    }

}