.header {

    @include breakpoint() {

        width: 100%;
        height: 200px;

        position: relative;

        background-color: $dark_gray;

    }

    &::before {

        @include breakpoint() {

            content: '';
            display: block;

            position: absolute;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-repeat: repeat;
            background-attachment: scroll;
            background-position: center center;

            background-image: url('../images/header-bg.jpg');

            opacity: 0.1;

        }

    }

    .header__wrapper {

        @extend .wrapper;

        @include breakpoint() {

            height: 100%;

            display: flex;
            flex-direction: column;

            position: relative;

        }

        .header__back {

            @include breakpoint() {

                color: $x11gray;

                font-size: 14px;
                line-height: 16px;
                font-weight: $medium;

                display: block;

                position: absolute;

                top: 24px;

                text-decoration: none;

                padding: 0 0 0 24px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: left center;
                background-size: 9px 14px;

                background-image: url('../images/icons/i_chevron_left.svg');

            }

        }

        .header__title {

            @include breakpoint() {

                color: $white;

                font-size: 32px;
                line-height: 40px;
                font-weight: $extra_bold;

                margin: auto 0;

            }
            @include breakpoint($tablet) {

                font-size: 48px;
                line-height: 56px;

            }

        }

    }

}