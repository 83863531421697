.tooltip-trigger {

    @include breakpoint() {

        width: 16px;
        height: 16px;

        display: block;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;

        cursor: pointer;

        margin: 0 0 0 auto;

    }
    @include breakpoint($small_tablet) {
        margin: 0 auto 0 16px;
    }

    &.info {

        @include breakpoint() {

            background-size: 16px 16px;
            background-image: url('../images/icons/i_info.svg');

        }

    }

}

.rc-tooltip {

    @include breakpoint() {

        z-index: 99;

        display: inline-block;
        max-width: 200px;

    }

    .rc-tooltip-content {

        @include breakpoint() {

            z-index: 99;

            display: inline-block;
            max-width: 200px;
    
            background-color: $x12gray;
            border-radius: 5px;
    
            padding: 16px;
    
            font-family: 'Raleway',sans-serif;
    
            font-size: 14px;
            line-height: 24px;
            font-weight: $medium;
    
            @include box-shadow(0 0 15px 0 rgba(0,0,0,0.1));

            position: relative;

        }

    }


    &.rc-tooltip-placement-left {

        .rc-tooltip-content {

            @include breakpoint() {
                @include transform(translate3d(-16px,0,0));
            }

            &::after {

                @include breakpoint() {
    
                    content: '';
                    display: block;
    
                    width: 0;
                    height: 0;
    
                    border-style: solid;
                    border-width: 4px 0 4px 4px;
                    border-color: transparent transparent transparent $x12gray;
    
                    position: absolute;
                    right: -4px;
                    top: 50%;
    
                    @include transform(translate3d(0,-2px,0));
    
                }
    
            }

        }

    }

    &.rc-tooltip-placement-right {

        .rc-tooltip-content {

            @include breakpoint() {
                @include transform(translate3d(16px,0,0));
            }

            &::after {

                @include breakpoint() {
    
                    content: '';
                    display: block;
    
                    width: 0;
                    height: 0;
    
                    border-style: solid;
                    border-width: 4px 4px 4px 0;
                    border-color: transparent $x12gray transparent transparent ;
    
                    position: absolute;
                    left: -4px;
                    top: 50%;
    
                    @include transform(translate3d(0,-2px,0));
    
                }
    
            }

        }

    }

}
