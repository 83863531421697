.nav__profile-container {

    @include breakpoint() {

        order: 2;

    }

    .nav__profile {

        @include breakpoint() {

            display: flex;
            flex-direction: row;

            padding: 8px 0;

            justify-content: center;

        }

        li {

            @include breakpoint() {

                margin: 0 16px 0 0;
                padding: 0 16px 0 0;

                border-right: solid 2px $border_color;

            }

            &:last-child {

                @include breakpoint() {

                    border: none;
                    margin: 0;
                    padding: 0;

                }

            }

            .profile-name {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 16px;
                    line-height: 24px;

                    font-weight: $bold;

                    display: block;

                }

            }

            a {

                @include breakpoint() {
                    line-height: 24px;
                }

            }

        }

    }

}