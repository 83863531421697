.steps__container {

    .steps {

        @include breakpoint() {
         
            display: flex;
            flex-direction: row;

            padding: 16px 0;

        }

        //  DEFAULT STEPS GRAY BORDER WITH NEXT LINE GRAY
        .step {

            @include breakpoint() {

                width: 8px;
                height: 8px;

                border-style: solid;
                border-width: 2px;
                border-color: $x11gray;

                border-radius: 50%;

                background-color: $white;

                margin: 0 20px 0 0;

                position: relative;

            }

            &::after {

                @include breakpoint() {

                    content: '';
                    display: block;

                    height: 2px;
                    width: 20px;

                    background-color: $x11gray;

                    position: absolute;
                    right: -22px;
                    top: 1px;

                }

            }

            &:last-child {

                @include breakpoint() {
                    margin: 0;
                }

                &::after {

                    @include breakpoint() {
                        display: none;
                    }

                }

            }

            //  MODIFIER RED BORDER NEXT LINE GRAY
            &.is-current {

                @include breakpoint() {
                    border-color: $maximum_red;
                }

            }

            //  MODIFIER RED BORDER / RED BACKGROUND / NEXT LINE RED
            &.is-done {

                @include breakpoint() {

                    border-color: $maximum_red;
                    background-color: $maximum_red;

                }

                &::after {

                    @include breakpoint() {
                        background-color: $maximum_red;
                    }

                }

            }

        }

    }

}