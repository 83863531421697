.wrapper {

	@include breakpoint() {

		margin: 0 24px;

    }
    @include breakpoint($tablet) {

        width: 1000px;

        margin: 0 auto;

    }
    // @include breakpoint($small_desktop) {
    //     width: 1100px;
    // }

}