.entity-list {

    &.user-list {

        @include breakpoint() {
            padding: 24px 0;
        }

    }

    &.bottom-spacer{
        margin:0 0 48px 0;
    }

}


.entity-list__item {

    &.user-item {

        @include breakpoint() {

            padding: 24px 0;

            border-bottom: solid 2px $platinum;

            position: relative;

        }

        &:last-child {

            @include breakpoint() {
                border: none;
            }

        }

        .user__row {

            @include breakpoint() {

                display: flex;
                flex-direction: column;

            }
            @include breakpoint($small_tablet) {
                flex-direction: row;
            }

            .user__cell {

                &.alpha {

                    @include breakpoint() {
                        display: none;
                    }
                    @include breakpoint($small_tablet) {

                        display: block;

                        width: 100px;
                        margin: 0 42px 0 0;

                    }

                }

                &.beta {

                    @include breakpoint($small_tablet) {

                        max-width: 220px;

                    }

                }

                &.gamma {

                    @include breakpoint() {

                        position: absolute;

                        top: 24px;
                        right: 0;

                    }

                }

            }

        }

        .user-type__icon {

            @include breakpoint() {

                width: 84px;
                height: 84px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;

                margin: 0 0 16px auto;

            }

            &.admin {

                @include breakpoint() {

                    background-image: url('../images/icons/i_user_type_admin.svg');
                    background-size: 76px 83px;

                }

            }

            &.manager,
            &.user {

                @include breakpoint() {

                    background-image: url('../images/icons/i_user_type_user.svg');
                    background-size: 82px 82px;

                }

            }

        }

        .user-type__role {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 14px;
                line-height: 16px;
                font-weight: $bold;

                text-align: center;

                max-width: 84px;
                margin: 0 0 0 auto;

            }

        }

        .user__action {

            @include breakpoint() {

                color: $maximum_red;

                font-size: 12px;
                line-height: 21px;
                font-weight: $bold;

                text-transform: uppercase;

                letter-spacing: 1px;

                display: block;

                @include transition(all 300ms ease-in-out 0ms);

                cursor: pointer;

            }

            &:hover {

                @include breakpoint() {
                    color: darken($maximum_red, 10%);
                }

            }

        }

        .user__info {

            @include breakpoint($small_tablet) {
                width: 400px;
            }

            .user__name {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 18px;
                    line-height: 21px;
                    font-weight: $bold;

                    display: flex;
                    flex-direction: row;

                }

                &.admin {

                    // &::after {

                    //     @include breakpoint() {

                    //         content: '';
                    //         display: block;

                    //         width: 20px;
                    //         height: 20px;

                    //         background-repeat: no-repeat;
                    //         background-attachment: scroll;
                    //         background-position: center;
                    //         background-size: 20px 20px;

                    //         background-image: url('../images/icons/i_star.svg');

                    //         margin: 0 0 0 8px;

                    //     }
                    //     @include breakpoint($small_tablet) {
                    //         display: none;
                    //     }

                    // }

                }

            }

            .user__details {

                @include breakpoint() {

                    padding: 24px 0;

                    display: flex;
                    flex-direction: column;

                }

                li {

                    @include breakpoint() {

                        color: $old_silver;

                        font-size: 14px;
                        line-height: 24px;
                        font-weight: $medium;

                    }

                    strong {

                        @include breakpoint() {
                            font-weight: $bold;
                        }

                    }

                }

            }

            .user__role {

                @include breakpoint() {

                    color: $old_silver;

                    font-size: 14px;
                    line-height: 21px;
                    font-weight: $bold;

                    margin: 0 0 16px 0;

                }

            }

            .user__email {

                @include breakpoint() {

                    color: $old_silver;

                    font-size: 12px;
                    line-height: 18px;
                    font-weight: $medium;

                    margin: 0 0 16px 0;
                    
                    display: block;

                }

            }

            .user__subs {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;

                }

                .subs-count {

                    @include breakpoint() {

                        color: $dark_gray;

                        font-size: 14px;
                        line-height: 21px;
                        font-weight: $bold;

                    }

                }

                .subs-list {

                    @include breakpoint() {

                        width: 100%;

                        display: flex;
                        flex-direction: column;
                    }

                    li {

                        @include breakpoint() {

                            color: $dark_gray;

                            font-size: 14px;
                            line-height: 20px;
                            font-weight: $medium;

                            margin: 0 0 16px 0;
                        }

                        &:first-child {
                            @include breakpoint() {
                                margin: 24px 0 16px 0;
                            }
                        }
                        &:last-child {
                            @include breakpoint() {
                                margin: 0;
                            }
                        }

                    }

                }

                .subs-add-trigger {

                    @include breakpoint() {

                        display: block;

                        background-repeat: no-repeat;
                        background-attachment: scroll;
                        background-position: center right;
                        background-size: 18px 18px;

                        background-image: url('../images/icons/i_add.svg');

                        margin: auto 0;
                        padding: 0 24px 0 0;

                        cursor: pointer;

                    }

                    & + .subs-list {

                        @include breakpoint() {

                            max-height: 0;

                            opacity: 0;
                            visibility: hidden;

                            @include transition(all 1500ms ease-in-out 0ms);

                            will-change: max-height;

                        }

                    }

                    &.is-active {

                        @include breakpoint() {
                            background-image: url('../images/icons/i_substract.svg');
                        }

                        & + .subs-list {

                            @include breakpoint() {

                                max-height: 999vh;

                                opacity: 1;
                                visibility: visible;

                            }

                        }

                    }

                }


            }

        }

    }

}
