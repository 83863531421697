.page {

	@include breakpoint() {

        min-height: 100vh;

        display: flex;
        flex-direction: column;

        background-color: $main-page-bg;

	}

    &.print {

        @include breakpoint() {

            background-color: transparent;

        }

    }

    &.secondary {

        @include breakpoint() {
            background-color: $white;
        }

    }

	&.credentials {

		@include breakpoint() {

			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center center;
	
			background-size: cover;
	
			background-image: url('../images/login-bg.jpg');

			padding: 80px 0;

        }
        @include breakpoint($small_tablet) {
            padding: 100px 0;
        }
        @include breakpoint($tablet) {

            padding: 0;

            display: flex;
            flex-direction: column;

        }

	}

}