.e-table {

    @include breakpoint() {

        display: flex;
        flex-direction: column;

        width: 100%;

    }
    @include breakpoint($small_tablet) {

        padding: 48px 0 0 0;

    }

    .e-table__row {

        @include breakpoint() {

            display: flex;
            flex-direction: column;

            position: relative;

            margin: 0 0 24px 0;

        }
        @include breakpoint($small_tablet) {
            flex-direction: row;
        }

        &:last-child {
            @include breakpoint($small_tablet) {
                margin: 0;
            }
        }

        .e-table__cell {

            @include breakpoint() {

                display: flex;
                flex-direction: column;
                
            }

            &.alpha {
                @include breakpoint($small_tablet) {
                    flex: none;
                    width: 320px;
                }
            }

            &.beta {

                @include breakpoint($small_tablet) {

                    width: 240px;
                    margin: 0 20px 0 auto;

                }

                &.display-tablet-and-above {
                    @include breakpoint() {
                        display: none;
                    }
                    @include breakpoint($small_tablet) {
                        display: flex;
                    }
                }

            }

            &.control_cell {

                @include breakpoint() {

                    position: absolute;

                    top: 0;
                    right: 0;

                }
                @include breakpoint($small_tablet) {
                    position: static;

                    width: 60px;
                    flex: none;
                }

                &.min-height {
                    @include breakpoint() {
                        min-height: 40px;
                    }
                }

                label,
                .control {

                    @include breakpoint() {
                        margin: auto 0;
                    }

                }

                a {

                    @extend .f-mini-link;

                    @include breakpoint() {
                        margin: auto 0;
                    }

                }

            }

        }

    }

    .e-table__name {

        @include breakpoint() {

            color: $dark_gray;

            font-size: 16px;
            line-height: 24px;
            font-weight: $bold;

            margin: 0 0 16px 0;

        }
        @include breakpoint($small_tablet) {
            margin: 0;
        }

        &.remove-margins {
            @include breakpoint() {
                margin: 0;
            }
        }

    }

    .e-table__data {

        @include breakpoint() {

            font-size: 14px;
            line-height: 16px;

            color: $old_silver;

        }

        &.tiny {
            @include breakpoint() {
                font-size: 12px;
            }
        }

        &.large {
            @include breakpoint() {
                font-size: 16px;
            }
        }

        &.bold {
            @include breakpoint() {
                font-weight: $bold;
            }
        }

        &.vertically-centered {
            @include breakpoint($small_tablet) {
                margin: auto 0;
            }
        }

        &.uppercase {
            @include breakpoint() {
                text-transform: uppercase;
            }
        }

    }

    .control {

        @include breakpoint() {

            color: $maximum_red;

            font-size: 12px;
            line-height: 24px;
            font-weight: $bold;

            letter-spacing: 1px;

            text-transform: uppercase;

            cursor: pointer;

            @include transition(all 300ms ease-in-out 0ms);

        }

        &:hover {

            @include breakpoint() {
                color: darken($maximum_red, 10%);
            }

        }

    }

}