.nav__links-container {

    @include breakpoint() {


        display: flex;
        flex-direction: column;

        margin: 0 0 20px 0;
        border-bottom: solid 2px rgba($platinum, 0.5);

        padding: 100px 0 0 0;

    }
    @include breakpoint($small_tablet) {

        padding: 0;


        flex-grow: 0;
        flex-shrink: 1;

        border: none;

        margin: 0 auto;

        height: auto;
        width: auto;

    }

    .nav__links {

        @include breakpoint($small_tablet) {

            display: flex;
            flex-direction: row;

        }

        li {

            @include breakpoint() {
                margin: 0 0 20px 0;
            }
            @include breakpoint($small_tablet) {
                margin: 0 15px;
            }

            .nav__link {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 14px;
                    line-height: 20px;

                    font-weight: $bold;

                    display: block;

                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;

                    letter-spacing: 1px;

                }
                @include breakpoint($small_tablet) {

                    color: $light_gray;

                    line-height: 40px;

                    @include transition(all 300ms ease-in-out 0ms);

                }

                &:hover {

                    @include breakpoint($small_tablet) {

                        color: $dark_gray;

                    }

                }

                &.is-current {

                    @include breakpoint($small_tablet) {

                        color: $dark_gray;

                        position: relative;

                    }

                    &::after {

                        @include breakpoint($small_tablet) {

                            content: '';
                            display: block;

                            background-color: $dark_gray;

                            width: 30px;
                            height: 4px;

                            position: absolute;

                            bottom: -20px;
                            left: 50%;

                            @include transform(translate3d(-50%,0,0));

                        }

                    }

                }

            }


        }

    }

}