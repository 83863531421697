.entity-list {

    &.sub-list {

        @include breakpoint() {

            margin: 0 -24px 48px;
            padding: 0 24px;

            border-top: solid 2px $platinum;

        }
        @include breakpoint($small_tablet) {

            margin: 0 0 48px;
            padding: 0;

        }
        @include breakpoint($tablet) {
            margin: 0 0 64px;
        }

    }

}

.entity-list__item {

    &.sub {

        @include breakpoint() {

            padding: 24px 0;

            border-bottom: solid 2px $platinum;

            position: relative;

        }
        @include breakpoint($small_tablet) {
            padding: 48px 0;
        }

        .sub__row {

            @include breakpoint() {

                display: flex;
                flex-direction: column;

            }
            @include breakpoint($small_tablet) {
                flex-direction: row;
            }

            .sub__cell {

                &.alpha {

                    @include breakpoint() {
                        display: none;
                    }
                    @include breakpoint($small_tablet) {

                        display: block;

                        width: 166px;

                    }

                }

                &.beta {

                }

                &.gamma {

                }

            }

        }

        //  SUB ELEMENTS

        //  SUB TYPE
        .sub-type {

            @include breakpoint() {

                display: flex;
                flex-direction: column;

            }

            .sub-type__avatar {

                @include breakpoint() {

                    width: 86px;
                    height: 79px;

                    display: block;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;
                    background-size: 86px 79px;

                    margin: 0 auto 16px;

                }

                &.auth {

                    @include breakpoint() {
                        background-image: url('../images/icons/i_sub_auth.svg');
                    }

                }

                &.data-capture {

                    @include breakpoint() {
                        background-image: url('../images/icons/i_sub_data.svg');
                    }

                }

                &.bar-code {

                    @include breakpoint() {
                        background-image: url('../images/icons/i_sub_bar.svg');
                    }

                }

            }

            .sub-type__name {

                @include breakpoint() {

                    display: block;

                    color: $old_silver;

                    font-size: 12px;
                    line-height: 14px;
                    font-weight: $bold;

                    text-align: center;

                }

            }

        }

        //  SUB INFO
        .sub__info {

            .sub__name {

                @include breakpoint() {

                    color: $dark_gray;

                    font-size: 18px;
                    line-height: 24px;
                    font-weight: $bold;

                }

            }

            .sub__type {

                @include breakpoint() {

                    color: $old_silver;

                    font-size: 14px;
                    line-height: 24px;
                    font-weight: $bold;

                }
                @include breakpoint($small_tablet) {
                    display: none;
                }

            }

            .sub__details {

                @include breakpoint() {

                    padding: 24px 0;

                    display: flex;
                    flex-direction: column;

                }

                li {

                    @include breakpoint() {

                        color: $old_silver;

                        font-size: 14px;
                        line-height: 24px;
                        font-weight: $medium;

                    }

                    strong {

                        @include breakpoint() {
                            font-weight: $bold;
                        }

                    }

                }

            }

            .sub__users {

                @include breakpoint() {

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                }
                @include breakpoint($small_tablet) {

                    justify-content: flex-start;

                }

                .users-count {

                    @include breakpoint() {

                        color: $dark_gray;

                        font-size: 14px;
                        line-height: 24px;
                        font-weight: $bold;

                    }

                }

                .users-add-trigger {

                    @include breakpoint() {

                        display: block;

                        background-repeat: no-repeat;
                        background-attachment: scroll;
                        background-position: center right;
                        background-size: 18px 18px;

                        background-image: url('../images/icons/i_add.svg');

                        margin: auto 0;
                        padding: 0 24px 0 0;

                        cursor: pointer;

                    }
                    @include breakpoint($small_tablet) {
                        margin: auto 0 auto 24px;
                    }

                }

            }

        }
        .sub__delete {

            @include breakpoint() {

                position: absolute;

                top: 24px;
                right: 0;

                color: $maximum_red;

                font-size: 12px;
                line-height: 24px;
                font-weight: $bold;

                letter-spacing: 1px;

                text-transform: uppercase;

                display: block;

                cursor: pointer;

                @include transition(all 300ms ease-in-out 0ms);

            }
            @include breakpoint($small_tablet) {

                top: 68px;

            }

            &:hover {

                @include breakpoint() {
                    color: darken($maximum_red, 10%);
                }

            }

        }
        .sub__authorize {

            @include breakpoint() {

                position: absolute;

                top: 24px;
                right: 0;

                color: $maximum_red;

                font-size: 12px;
                line-height: 24px;
                font-weight: $bold;

                letter-spacing: 1px;

                text-transform: uppercase;

                display: block;

                cursor: pointer;

                @include transition(all 300ms ease-in-out 0ms);

            }
            @include breakpoint($small_tablet) {

                top: 88px;

            }

            &:hover {

                @include breakpoint() {
                    color: darken($maximum_red, 10%);
                }

            }

        }
        //  SUB EDIT
        .sub__edit {

            @include breakpoint() {

                position: absolute;

                top: 24px;
                right: 0;

                color: $maximum_red;

                font-size: 12px;
                line-height: 24px;
                font-weight: $bold;

                letter-spacing: 1px;

                text-transform: uppercase;

                display: block;

                cursor: pointer;

                @include transition(all 300ms ease-in-out 0ms);

            }
            @include breakpoint($small_tablet) {

                top: 48px;

            }

            &:hover {

                @include breakpoint() {
                    color: darken($maximum_red, 10%);
                }

            }

        }

    }

}