//	GENERAL INPUTS
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {

	@include breakpoint() {

        font-family: 'Raleway',sans-serif;

		width: 100%;
		height: 48px;

		padding: 0 16px;

        border: solid 2px $border_color;
        background-color: $main-page-bg;

		color: $dark_gray;

		font-size: 16px;
		font-weight: $medium;

	}

    &:focus {

        @include breakpoint() {

            outline: none;
            border-color: $border_highlight_color;

        }

    }

    //  LARGE MODIFIER
    &.large {

        @include breakpoint() {

            height: 70px;

            padding: 0;

            border: none;
            border-bottom: solid 2px $border_color;

            font-size: 24px;
            line-height: 30px;

        }

        &:focus {

            @include breakpoint() {

                outline: none;
                border-color: $border_highlight_color;

            }

        }

    }

    &.transparent-bg {

        @include breakpoint() {
            background-color: transparent;
        }

    }

    //  LOCATION PIN
    &.location-pin {

        @include breakpoint() {

            padding: 0 46px 0 16px;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: calc(100% - 16px) center;
            background-size: 14px 20px;

            background-image: url('../images/icons/i_location_pin.svg');

        }

    }

    @include placeholder {

        color: $old_silver;
        font-weight: $medium;

    }

    &:disabled {
        @include breakpoint() {
            color: $old_silver;
            background-color: rgba($platinum, 0.5);
        }
    }

}

//	SUBMIT / BUTTON / CTA

input[type="submit"],
button,
.cta {

	@include breakpoint() {

		color: $white;
		background-color: $maximum_red;

		width: 100%;
		height: 48px;

        font-family: 'Raleway',sans-serif;

		font-size: 16px;
		font-weight: $bold;

		line-height: 48px;

        display: block;
        padding: 0 20px;

		border: none;

        cursor: pointer;

        @include transition(all 300ms ease-in-out 0ms);

        text-decoration: none;
        text-align: center;
        text-transform: capitalize;

        white-space: nowrap;

        //  MOBILE SAFARI ADS WEIRD ROUNDED CORNERS ON INPUTS
        //  THIS FIXES IT
        -webkit-appearance: none;
        -webkit-border-radius: 0;

    }


    &.is-small {
        width: 40%;
    }
    &.is-secondary {
        background-color: $x12gray;
        &:hover {

            @include breakpoint() {
                background-color: darken($x12gray, 10%);
            }

        }
    }

    &:hover {

        @include breakpoint() {
            background-color: darken($maximum_red, 10%);
        }

    }

    &:focus {

        @include breakpoint() {

            outline: none;

        }

    }

    &.extra-padding {
        @include breakpoint() {
            padding: 0 40px;
        }
    }

    &.auto-padding {
        @include breakpoint() {
            padding: 0;
            text-align: center;
        }
    }

    &.auto {

        @include breakpoint() {
            width: auto;
        }

    }

    //  MODIFIERS
    &.gray {

        @include breakpoint() {

            color: $dark_gray;
            background-color: $ultra_ligh_gray;

        }

        &:hover {

            @include breakpoint() {
                background-color: $border_highlight_color;
            }

        }

    }

    &.light {

        @include breakpoint() {

            color: $old_silver;

            font-size: 12px;
            line-height: 24px;
            font-weight: $bold;

            display: block;

            margin: auto 0;
            padding: 0;

            letter-spacing: 1px;

            text-transform: uppercase;

            background-color: transparent;

        }

        &:hover {

            @include breakpoint() {

                color: $dark_gray;

            }

        }

    }

    //  DYNAMIC MODIFIER WITH UNSET VARIABLES
    //  CAPABLE OF GETTING SET FROM PARRENT IF REQUIRED
    &.var {

        @include breakpoint() {

            color: var(--cta-var-secondary-color);
            background-color: var(--cta-var-primary-color);

        }

    }

    &.link {

        @include breakpoint() {

            padding: 0;
            text-align: left;

            color: $dim_gray;
            background-color: transparent;

        }

        &.red {

            @include breakpoint() {
                color: $maximum_red;
            }

            &:hover {
                @include breakpoint() {
                    color: darken($maximum_red, 10%);
                }
            }

        }

        &.small {
            @include breakpoint() {
                font-size: 12px;
            }
        }

        &.uppercase {
            @include breakpoint() {
                text-transform: uppercase;
            }
        }

    }

    &.type-simple {

        @include breakpoint() {

            font-size: 12px;
            font-weight: $bold;

            color: $label_color;

            text-align: center;
            text-transform: uppercase;

            background-color: transparent;

            margin: 0 auto;
            max-width: 320px;

        }

        &:hover {

            @include breakpoint() {
                color: $dark_gray;
            }

        }

    }

    //  OUTLINES
    &.outline {

        @include breakpoint() {

            background-color: transparent;

        }

        &.red {

            @include breakpoint() {

                color: $maximum_red;
                @include box-shadow(inset 0 0 0 2px $maximum_red);

            }

        }

    }

}

//  DATE
.DayPickerInput {
    @include breakpoint() {
        width: 100%;
    }
}

input[type="date"],
input.date {

    @include breakpoint() {

        font-family: 'Raleway',sans-serif;

        width: 100%;
        height: 48px;

        border: solid 2px $border_color;

        color: $dark_gray;

        font-family: 'Roboto',sans-serif;
        font-size: 16px;
        font-weight: $medium;

        padding: 0 16px 0 56px;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 16px center;
        background-size: 24px 24px;

        background-image: url('../images/icons/i_callendar.svg');

    }

    &.is-danger {
        border-color: $maximum_red;
    }

    &:disabled {
        @include breakpoint() {
            color: $old_silver;
            background-color: rgba($platinum, 0.5);
        }
    }

}