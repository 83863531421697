@include keyframes(fade_in) {

    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }

}

@include keyframes(blur_in) {

    0% {
        @include filter(blur(0px));
    }
    100% {
        @include filter(blur(5px));
    }

}

@include keyframes(fade_in_down) {

    0% {
        opacity: 0;
        @include transform(translate3d(0,-25px,0));
    }
    100% {
        opacity: 1;
        @include transform(translate3d(0,0,0));
    }

}

@include keyframes(fade_in_up) {

    0% {
        opacity: 0;
        @include transform(translate3d(0,50%,0));
    }
    100% {
        opacity: 1;
        @include transform(translate3d(0,0,0));
    }

}

@include keyframes(fade_out_down) {

    0% {
        opacity: 1;
        @include transform(translate3d(0,0,0));
    }
    100% {
        opacity: 1;
        @include transform(translate3d(0,50%,0));
    }

}