.f-link {

	@include breakpoint() {

		color: $maximum_red;

		font-size: 14px;
		line-height: 20px;

		display: block;

        text-decoration: none;
        
        padding: 0 20px;

        @include transition(all 300ms ease-in-out 0ms);

	}

    &:hover {

        @include breakpoint() {
            color: darken($maximum_red, 10%);
        }

    }

    &:focus {

        @include breakpoint() {
            outline: none;
        }

    }

    &.medium {
        @include breakpoint() {
            font-weight: $medium;
        }
    }

    &.bold {
        @include breakpoint() {
            font-weight: $bold;
        }
    }

	&.align-right {
		@include breakpoint() {
			margin: 0 0 0 auto;
		}
	}

    &.align-center {
        @include breakpoint() {
            margin: 0 auto;
        }
    }

    &.no-pad {
        @include breakpoint() {
            padding: 0;
        }
    }

    //  VAR MODIFIER WITH UNSET VARIABLES
    //  CAPABLE OF GETTING SET FROM PARRENT IF REQUIRED
    &.var {

        @include breakpoint() {

            color: var(--link-var-color);
            line-height: var(--link-var-line-height);

        }

    }

}

.f-mini-link {

    @include breakpoint() {

        color: $maximum_red;

        font-size: 12px;
        line-height: 20px;
        font-weight: $bold;

        letter-spacing: 1px;

        text-transform: uppercase;

        display: block;

        cursor: pointer;

        @include transition(all 300ms ease-in-out 0ms);

    }

    &:hover {
        @include breakpoint() {
            color: darken($maximum_red, 10%);
        }
    }

}