.dashboard {

    @include breakpoint() {
        padding: 40px 0;
    }

    .dasboard__head {

        @include breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

        }

        .dashboard__title {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 24px;
                line-height: 50px;
                font-weight: $bold;

            }

        }

        .cta {

        }

        .dashboard__info {

            @include breakpoint() {

                color: $dark_gray;

                font-size: 16px;
                line-height: 24px;

                padding: 20px 0;

            }
            @include breakpoint($tablet) {

                padding: 20px calc(100% - 620px) 20px 0;

            }

        }

    }
    
    .dashboard__body {

    }

}