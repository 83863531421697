.f-container {

    &.reports-form {

        .f-section {

            .reports-form__container {

                @include breakpoint($small_tablet) {

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                }

                .f-element {

                    &.alpha {
    
                        @include breakpoint() {
                            margin: 0;
                        }
                        @include breakpoint($small_tablet) {
                            flex: none;
                            width: calc(50% - 25px);
                            margin: 0 0 32px 0;
                        }
                        @include breakpoint($small_desktop) {
                            width: 230px;
                        }
    
                    }
    
                    &.beta {

                        @include breakpoint() {
                        }
                        @include breakpoint($small_tablet) {
                            flex: none;
                            width: 50px;
                            margin: 0 0 32px 0;
                        }
                        @include breakpoint($small_desktop) {
                            width: 16px;
                        }
    
                        .f-or {
    
                            @include breakpoint() {
    
                                color: $old_silver;
    
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: $medium;
    
                                display: block;
    
                                text-align: center;
    
                            }
                            @include breakpoint($small_tablet) {

                                line-height: 48px;
                                margin: 22px 0 0 0;

                            }
    
                        }
    
                    }
    
                    &.gamma {
    
                        @include breakpoint($small_tablet) {
                            flex: none;
                            width: calc(50% - 25px);
                            margin: 0 0 32px 0;
                        }
                        @include breakpoint($small_desktop) {
                            width: 230px;
                        }
    
                    }

                    &.epsilon {
    
                        @include breakpoint($small_tablet) {
                            flex: none;
                            width: calc(50% - 25px);
                            margin: 0;
                        }
                        @include breakpoint($small_desktop) {
                            width: 230px;
                            margin: 0 0 0 16px;
                        }
    
                    }

                    &.delta {
    
                        @include breakpoint($small_tablet) {
                            flex: none;
                            width: calc(50% - 25px);
                            margin: 0 0 0 auto;
                        }
                        @include breakpoint($small_desktop) {
                            width: 230px;
                            margin: 0 0 0 16px;
                        }

                        button {
    
                            @include breakpoint($small_tablet) {
                                margin: 22px 0 0 0;
                            }
    
                        }
    
                    }
   
                }
    
            }

        }

    }

}
