/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800|Roboto:400,700,800");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.react-select div {
  transition: none;
}
.react-select input[type=text] {
  transition: none;
  -webkit-transition: none;
}
.react-select .react-select__control {
  height: 50px;
  background-color: #fafafa;
  border-radius: 0px;
  border: solid 2px rgba(0, 0, 0, 0.1);
}
.react-select .react-select__control:hover {
  border: solid 2px rgba(0, 0, 0, 0.1);
}
.react-select .react-select__indicator-separator {
  display: none;
}
.react-select .react-select__value-container {
  height: 50px;
}
.react-select .react-select__indicators {
  height: 50px;
}

body {
  font-family: "Raleway", sans-serif;
}

body .roboto, body .rep__list .rep__item .rep__foot .rep__row .rep__cell.auth, .rep__list .rep__item .rep__foot .rep__row body .rep__cell.auth, body .rep__list .rep__item .rep__foot .rep__row .rep__cell.data, .rep__list .rep__item .rep__foot .rep__row body .rep__cell.data, body .rep__list .rep__item .rep__foot .rep__row .rep__cell.bar, .rep__list .rep__item .rep__foot .rep__row body .rep__cell.bar, body .rep__list .rep__item .rep__foot .rep__row .rep__cell.total, .rep__list .rep__item .rep__foot .rep__row body .rep__cell.total, body .rep__list .rep__item .rep__body .rep__row .rep__cell.auth, .rep__list .rep__item .rep__body .rep__row body .rep__cell.auth, body .rep__list .rep__item .rep__body .rep__row .rep__cell.data, .rep__list .rep__item .rep__body .rep__row body .rep__cell.data, body .rep__list .rep__item .rep__body .rep__row .rep__cell.bar, .rep__list .rep__item .rep__body .rep__row body .rep__cell.bar, body .rep__list .rep__item .rep__body .rep__row .rep__cell.total, .rep__list .rep__item .rep__body .rep__row body .rep__cell.total {
  font-family: "Roboto", sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  overflow-y: scroll;
}

#root {
  min-height: 100vh;
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #E8E8E8;
}

@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-o-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes blur_in {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}
@-moz-keyframes blur_in {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}
@-o-keyframes blur_in {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}
@keyframes blur_in {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}
@-webkit-keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -25px, 0);
    -moz-transform: translate3d(0, -25px, 0);
    -ms-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -25px, 0);
    -moz-transform: translate3d(0, -25px, 0);
    -ms-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -25px, 0);
    -moz-transform: translate3d(0, -25px, 0);
    -ms-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -25px, 0);
    -moz-transform: translate3d(0, -25px, 0);
    -ms-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fade_out_down {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
@-moz-keyframes fade_out_down {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
@-o-keyframes fade_out_down {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
@keyframes fade_out_down {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
@media screen and (min-width: 48em) {
  .mobile-only {
    display: none;
  }
}

@media screen {
  .tablet-and-above {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .tablet-and-above {
    display: block;
  }
}

@media screen {
  .prevent-scroll {
    overflow: hidden !important;
  }
}

@media screen {
  .wrapper, .header .header__wrapper {
    margin: 0 24px;
  }
}
@media screen and (min-width: 64em) {
  .wrapper, .header .header__wrapper {
    width: 1000px;
    margin: 0 auto;
  }
}

@media screen {
  .page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
  }
}
@media screen {
  .page.print {
    background-color: transparent;
  }
}
@media screen {
  .page.secondary {
    background-color: #ffffff;
  }
}
@media screen {
  .page.credentials {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-image: url("../images/login-bg.jpg");
    padding: 80px 0;
  }
}
@media screen and (min-width: 48em) {
  .page.credentials {
    padding: 100px 0;
  }
}
@media screen and (min-width: 64em) {
  .page.credentials {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

@media screen {
  .sign-in__container {
    padding: 0 24px;
    -webkit-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    -moz-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    -o-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    animation: fade_in 600ms ease-in-out 1 forwards 0ms;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1000px;
    margin: auto;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container {
    flex-direction: column;
    justify-content: space-evenly;
    height: 570px;
  }
}
@media screen and (min-width: 80em) {
  .sign-in__container {
    width: 1100px;
  }
}
@media screen {
  .sign-in__container .alpha {
    color: #ffffff;
  }
}
@media screen and (min-width: 48em) {
  .sign-in__container .alpha {
    width: 540px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .alpha {
    width: 460px;
  }
}
@media screen and (min-width: 80em) {
  .sign-in__container .alpha {
    width: 600px;
  }
}
@media screen {
  .sign-in__container .alpha .iamx-info {
    font-size: 14px;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .alpha .iamx-info {
    font-size: 16px;
  }
}
@media screen {
  .sign-in__container .alpha .break-from-tablet {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .sign-in__container .alpha .break-from-tablet {
    display: block;
  }
}
@media screen {
  .sign-in__container .alpha .assure-id-logo-white {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-image: url("../images/logos/acuant-logo-white.svg");
    width: 111px;
    height: 52px;
    margin: 0 auto 40px;
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .sign-in__container .alpha .assure-id-logo-white {
    margin: 0 auto 50px;
  }
}
@media screen {
  .sign-in__container .alpha h2,
  .sign-in__container .alpha p {
    text-align: center;
    margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 48em) {
  .sign-in__container .alpha h2,
  .sign-in__container .alpha p {
    margin: 0 0 50px 0;
  }
}
@media screen and (min-width: 80em) {
  .sign-in__container .alpha p {
    width: 500px;
    margin: 0 auto;
  }
}
@media screen {
  .sign-in__container .alpha h2.extra-bold {
    font-size: 18px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .alpha h2.extra-bold {
    font-size: 24px;
  }
}
@media screen {
  .sign-in__container .sign-in-form {
    padding: 32px 16px;
    margin: 0 0 40px 0;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 48em) {
  .sign-in__container .sign-in-form {
    width: 477px;
    padding: 56px;
    margin: 0 auto 50px auto;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .sign-in-form {
    flex: none;
    align-self: flex-end;
    width: 460px;
    margin: 0;
    order: 3;
  }
}
@media screen and (min-width: 80em) {
  .sign-in__container .sign-in-form {
    width: 477px;
  }
}
@media screen {
  .sign-in__container .sign-in-form .f-title {
    font-size: 24px;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .sign-in-form .f-title {
    font-size: 32px;
  }
}
@media screen {
  .sign-in__container .sign-in-form .f-description {
    font-size: 14px;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .sign-in-form .f-description {
    font-size: 16px;
  }
}
@media screen {
  .sign-in__container .beta {
    color: #ffffff;
  }
}
@media screen and (min-width: 64em) {
  .sign-in__container .beta {
    width: 460px;
    margin: 0;
  }
}
@media screen and (min-width: 80em) {
  .sign-in__container .beta {
    width: 600px;
  }
}
@media screen {
  .sign-in__container .beta p {
    font-weight: 700;
    text-align: center;
  }
}
.sign-in__container .sign-in__info {
  color: #fff;
}
@media screen {
  .sign-in__container .sign-in__info {
    margin-top: 24px;
    padding: 16px 0 0 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }
}
@media screen and (min-width: 80em) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sign-in__container {
    margin-top: calc((100vh - 570px) / 2);
  }
}

@media screen {
  .recover__container {
    padding: 0 20px;
    -webkit-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    -moz-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    -o-animation: fade_in 600ms ease-in-out 1 forwards 0ms;
    animation: fade_in 600ms ease-in-out 1 forwards 0ms;
  }
}
@media screen and (min-width: 48em) {
  .recover__container {
    margin: auto;
  }
}
@media screen {
  .recover__container .recover-form {
    padding: 40px 20px;
    margin: 0 0 40px 0;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 48em) {
  .recover__container .recover-form {
    width: 477px;
    margin: 0;
    padding: 56px;
  }
}

@media screen {
  .dashboard {
    padding: 40px 0;
  }
}
@media screen {
  .dashboard .dasboard__head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .dashboard .dasboard__head .dashboard__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    line-height: 50px;
    font-weight: 700;
  }
}
@media screen {
  .dashboard .dasboard__head .dashboard__info {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    padding: 20px 0;
  }
}
@media screen and (min-width: 64em) {
  .dashboard .dasboard__head .dashboard__info {
    padding: 20px calc(100% - 620px) 20px 0;
  }
}
@media screen {
  footer {
    padding: 40px 0;
    margin: auto 0 0 0;
    text-align: center;
    color: #b3b3b3;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen {
  .page__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .page__head {
    padding: 48px 0 0 0;
  }
}
@media screen {
  .page__head .page__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    line-height: 48px;
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .page__head .page__title {
    font-size: 32px;
  }
}
@media screen and (min-width: 48em) {
  .page__head .page__title.small {
    font-size: 24px;
  }
}
@media screen {
  .page__head .cta {
    max-width: 180px;
    padding: 0;
    margin: 0 0 0 10px;
  }
}
@media screen and (min-width: 48em) {
  .page__head .cta {
    max-width: none;
    flex-grow: 0;
    flex-basis: 0;
    padding: 0 20px;
  }
}
@media screen {
  .page__head .cta.mobile-short {
    max-width: 140px;
  }
}
@media screen and (min-width: 48em) {
  .page__head .cta.mobile-short {
    max-width: none;
  }
}

@media screen {
  .page__sub-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0 0;
    margin: 0 0 40px 0;
  }
}
@media screen {
  .page__sub-head .filter__container {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .page__sub-head .filter__container {
    display: flex;
  }
}
@media screen {
  .page__sub-head .search__container {
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .page__sub-head .search__container {
    max-width: 320px;
  }
}

@media screen {
  .account-name .f-row.cta-container .f-element {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .account-name .f-row.cta-container .f-element.alpha {
    order: 2;
    flex: none;
    max-width: 230px;
    margin: 0 0 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .account-name .f-row.cta-container .f-element.beta {
    order: 1;
    flex: none;
    width: auto;
  }
}

@media screen {
  .asign-admin .f-row.cta-container .f-element {
    margin: 0;
  }
}
@media screen {
  .asign-admin .f-row.cta-container .f-element.alpha {
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .asign-admin .f-row.cta-container .f-element.alpha {
    order: 3;
    flex: none;
    max-width: 220px;
  }
}
@media screen {
  .asign-admin .f-row.cta-container .f-element.beta {
    width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .asign-admin .f-row.cta-container .f-element.beta {
    order: 1;
    flex: none;
    width: auto;
  }
}
@media screen {
  .asign-admin .f-row.cta-container .f-element.gamma {
    width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .asign-admin .f-row.cta-container .f-element.gamma {
    order: 2;
    flex: none;
    max-width: 230px;
    margin: 0 0 0 auto;
  }
}

@media screen and (min-width: 48em) {
  .account-name .f-section,
  .asign-admin .f-section {
    height: calc(100vh - 290px);
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .account-name .f-section .f-row,
  .asign-admin .f-section .f-row {
    flex-shrink: 0;
  }
}
@media screen {
  .account-name .f-section .f-row.cta-container,
  .asign-admin .f-section .f-row.cta-container {
    margin: 8px 0 0 0;
  }
}

@media screen {
  .create-subscription .f-row.cta-container .f-element.alpha,
  .edit-subscription .f-row.cta-container .f-element.alpha {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .create-subscription .f-row.cta-container .f-element.alpha,
  .edit-subscription .f-row.cta-container .f-element.alpha {
    order: 2;
    max-width: 240px;
    margin: 0 0 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .create-subscription .f-row.cta-container .f-element.beta,
  .edit-subscription .f-row.cta-container .f-element.beta {
    order: 1;
    margin: 0;
    flex-grow: 0;
  }
}

.custom-radio__container {
  width: 100%;
}

@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen {
  .f-container.reports-form .f-section .reports-form__container .f-element.alpha {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.alpha {
    flex: none;
    width: calc(50% - 25px);
    margin: 0 0 32px 0;
  }
}
@media screen and (min-width: 80em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.alpha {
    width: 230px;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.beta {
    flex: none;
    width: 50px;
    margin: 0 0 32px 0;
  }
}
@media screen and (min-width: 80em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.beta {
    width: 16px;
  }
}
@media screen {
  .f-container.reports-form .f-section .reports-form__container .f-element.beta .f-or {
    color: #828282;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    display: block;
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.beta .f-or {
    line-height: 48px;
    margin: 22px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.gamma {
    flex: none;
    width: calc(50% - 25px);
    margin: 0 0 32px 0;
  }
}
@media screen and (min-width: 80em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.gamma {
    width: 230px;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.epsilon {
    flex: none;
    width: calc(50% - 25px);
    margin: 0;
  }
}
@media screen and (min-width: 80em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.epsilon {
    width: 230px;
    margin: 0 0 0 16px;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.delta {
    flex: none;
    width: calc(50% - 25px);
    margin: 0 0 0 auto;
  }
}
@media screen and (min-width: 80em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.delta {
    width: 230px;
    margin: 0 0 0 16px;
  }
}
@media screen and (min-width: 48em) {
  .f-container.reports-form .f-section .reports-form__container .f-element.delta button {
    margin: 22px 0 0 0;
  }
}

@media screen {
  section.subscriptions, section.users, section.reports {
    background-color: #ffffff;
  }
}

@media screen {
  .f-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .f-container form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen {
  .f-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;
  }
}
@media screen {
  .f-section:last-child {
    margin: 0;
  }
}

@media screen {
  .f-section__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 800;
    margin: 0 0 40px 0;
    width: 100%;
  }
}

@media screen {
  .f-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 64em) {
  .f-row {
    margin: 0 0 32px 0;
  }
}
@media screen {
  .f-row:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .f-row.tablet-negative-left-margin-48 {
    margin-left: -48px;
  }
}
@media screen {
  .f-row .f-element {
    width: 100%;
    margin: 0 0 32px 0;
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin: 0 12px;
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element:first-child {
    margin: 0 12px 0 0;
  }
}
@media screen {
  .f-row .f-element:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element:last-child {
    margin: 0 0 0 12px;
  }
}
@media screen {
  .f-row .f-element:only-child {
    flex: none;
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element:only-child {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element.is-one-third {
    flex: none;
    width: calc(33.333333% - 12px);
  }
}
@media screen and (min-width: 48em) {
  .f-row .f-element.is-two-thirds {
    flex: none;
    width: calc(66.666667% - 12px);
  }
}
@media screen {
  .f-row .f-element.action {
    position: relative;
  }
}
@media screen {
  .f-row .f-element.action input[type=text] {
    padding: 0 64px 0 16px;
  }
}
@media screen {
  .f-row .f-element.action .action-copy {
    position: absolute;
    right: 16px;
    bottom: 12px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    padding: 0 8px;
    display: block;
    text-transform: uppercase;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
}
.f-row .f-element .react-select.is-danger .react-select__control {
  border-color: #D52B1E;
}
.f-row .f-element .field.is-danger {
  border-color: #D52B1E;
}
.f-row .f-element p.is-danger {
  font-family: "Raleway", sans-serif;
  color: #D52B1E;
}
@media screen {
  .f-row.inline-cta .f-element {
    width: auto;
    flex-basis: 1;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0 12px;
  }
}
@media screen {
  .f-row.inline-cta .f-element:first-child {
    margin: 0 12px 0 0;
  }
}
@media screen {
  .f-row.inline-cta .f-element:last-child {
    margin: 0 0 0 12px;
  }
}
@media screen {
  .f-row.inline-cta.space-between {
    justify-content: space-between;
  }
}
@media screen {
  .f-row.border-top {
    padding: 24px 0 0 0;
    margin: 0;
    border-top: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .f-row.border-top {
    padding: 40px 0 40px 0;
  }
}
@media screen {
  .f-row.border-bottom {
    padding: 24px 0;
    margin: 0 0 24px;
    border-bottom: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .f-row.border-bottom {
    padding: 40px 0;
    margin: 0 0 40px 0;
  }
}

@media screen {
  label,
  .label {
    color: #828282;
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 10px 0;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
@media screen {
  label.black,
  .label.black {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  label.extra-bottom-margin,
  .label.extra-bottom-margin {
    margin: 0 0 24px 0;
  }
}

@media screen {
  .f-description {
    color: #6D6A75;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
    margin: 0 0 32px 0;
  }
}

@media screen {
  .f-title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 32px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    margin: 0 0 32px 0;
  }
}

@media screen {
  .f-row.cta-container {
    display: flex;
    flex-direction: column;
    margin: 8px 0 0 0;
    padding: 48px 0 0 0;
    border-top: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .f-row.cta-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen {
  .f-row.cta-container .f-element {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .f-row.cta-container .f-element.alpha {
    order: 2;
    max-width: 220px;
  }
}
@media screen and (min-width: 48em) {
  .f-row.cta-container .f-element.beta {
    order: 1;
    flex-grow: 0;
    flex-shrink: 1;
  }
}

@media screen {
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=date] {
    font-family: "Raleway", sans-serif;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: solid 2px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 500;
  }
}
@media screen {
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=password]:focus,
  input[type=date]:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.2);
  }
}
@media screen {
  input[type=text].large,
  input[type=email].large,
  input[type=password].large,
  input[type=date].large {
    height: 70px;
    padding: 0;
    border: none;
    border-bottom: solid 2px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen {
  input[type=text].large:focus,
  input[type=email].large:focus,
  input[type=password].large:focus,
  input[type=date].large:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.2);
  }
}
@media screen {
  input[type=text].transparent-bg,
  input[type=email].transparent-bg,
  input[type=password].transparent-bg,
  input[type=date].transparent-bg {
    background-color: transparent;
  }
}
@media screen {
  input[type=text].location-pin,
  input[type=email].location-pin,
  input[type=password].location-pin,
  input[type=date].location-pin {
    padding: 0 46px 0 16px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: calc(100% - 16px) center;
    background-size: 14px 20px;
    background-image: url("../images/icons/i_location_pin.svg");
  }
}
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder {
  color: #828282;
  font-weight: 500;
}
input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=date]:-moz-placeholder {
  color: #828282;
  font-weight: 500;
}
input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=date]::-moz-placeholder {
  color: #828282;
  font-weight: 500;
}
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder {
  color: #828282;
  font-weight: 500;
}
@media screen {
  input[type=text]:disabled,
  input[type=email]:disabled,
  input[type=password]:disabled,
  input[type=date]:disabled {
    color: #828282;
    background-color: rgba(225, 225, 225, 0.5);
  }
}

@media screen {
  input[type=submit],
  button,
  .cta {
    color: #ffffff;
    background-color: #D52B1E;
    width: 100%;
    height: 48px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    display: block;
    padding: 0 20px;
    border: none;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    text-decoration: none;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
}
input[type=submit].is-small,
button.is-small,
.cta.is-small {
  width: 40%;
}
input[type=submit].is-secondary,
button.is-secondary,
.cta.is-secondary {
  background-color: #e5e5e5;
}
@media screen {
  input[type=submit].is-secondary:hover,
  button.is-secondary:hover,
  .cta.is-secondary:hover {
    background-color: #cccccc;
  }
}
@media screen {
  input[type=submit]:hover,
  button:hover,
  .cta:hover {
    background-color: #a82218;
  }
}
@media screen {
  input[type=submit]:focus,
  button:focus,
  .cta:focus {
    outline: none;
  }
}
@media screen {
  input[type=submit].extra-padding,
  button.extra-padding,
  .cta.extra-padding {
    padding: 0 40px;
  }
}
@media screen {
  input[type=submit].auto-padding,
  button.auto-padding,
  .cta.auto-padding {
    padding: 0;
    text-align: center;
  }
}
@media screen {
  input[type=submit].auto,
  button.auto,
  .cta.auto {
    width: auto;
  }
}
@media screen {
  input[type=submit].gray,
  button.gray,
  .cta.gray {
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  input[type=submit].gray:hover,
  button.gray:hover,
  .cta.gray:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media screen {
  input[type=submit].light,
  button.light,
  .cta.light {
    color: #828282;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    margin: auto 0;
    padding: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: transparent;
  }
}
@media screen {
  input[type=submit].light:hover,
  button.light:hover,
  .cta.light:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  input[type=submit].var,
  button.var,
  .cta.var {
    color: var(--cta-var-secondary-color);
    background-color: var(--cta-var-primary-color);
  }
}
@media screen {
  input[type=submit].link,
  button.link,
  .cta.link {
    padding: 0;
    text-align: left;
    color: #6D6A75;
    background-color: transparent;
  }
}
@media screen {
  input[type=submit].link.red,
  button.link.red,
  .cta.link.red {
    color: #D52B1E;
  }
}
@media screen {
  input[type=submit].link.red:hover,
  button.link.red:hover,
  .cta.link.red:hover {
    color: #a82218;
  }
}
@media screen {
  input[type=submit].link.small,
  button.link.small,
  .cta.link.small {
    font-size: 12px;
  }
}
@media screen {
  input[type=submit].link.uppercase,
  button.link.uppercase,
  .cta.link.uppercase {
    text-transform: uppercase;
  }
}
@media screen {
  input[type=submit].type-simple,
  button.type-simple,
  .cta.type-simple {
    font-size: 12px;
    font-weight: 700;
    color: #828282;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    margin: 0 auto;
    max-width: 320px;
  }
}
@media screen {
  input[type=submit].type-simple:hover,
  button.type-simple:hover,
  .cta.type-simple:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  input[type=submit].outline,
  button.outline,
  .cta.outline {
    background-color: transparent;
  }
}
@media screen {
  input[type=submit].outline.red,
  button.outline.red,
  .cta.outline.red {
    color: #D52B1E;
    -webkit-box-shadow: inset 0 0 0 2px #D52B1E;
    -moz-box-shadow: inset 0 0 0 2px #D52B1E;
    -ms-box-shadow: inset 0 0 0 2px #D52B1E;
    box-shadow: inset 0 0 0 2px #D52B1E;
  }
}

@media screen {
  .DayPickerInput {
    width: 100%;
  }
}

@media screen {
  input[type=date],
  input.date {
    font-family: "Raleway", sans-serif;
    width: 100%;
    height: 48px;
    border: solid 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px 0 56px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 16px center;
    background-size: 24px 24px;
    background-image: url("../images/icons/i_callendar.svg");
  }
}
input[type=date].is-danger,
input.date.is-danger {
  border-color: #D52B1E;
}
@media screen {
  input[type=date]:disabled,
  input.date:disabled {
    color: #828282;
    background-color: rgba(225, 225, 225, 0.5);
  }
}

@media screen {
  .f-link {
    color: #D52B1E;
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-decoration: none;
    padding: 0 20px;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .f-link:hover {
    color: #a82218;
  }
}
@media screen {
  .f-link:focus {
    outline: none;
  }
}
@media screen {
  .f-link.medium {
    font-weight: 500;
  }
}
@media screen {
  .f-link.bold {
    font-weight: 700;
  }
}
@media screen {
  .f-link.align-right {
    margin: 0 0 0 auto;
  }
}
@media screen {
  .f-link.align-center {
    margin: 0 auto;
  }
}
@media screen {
  .f-link.no-pad {
    padding: 0;
  }
}
@media screen {
  .f-link.var {
    color: var(--link-var-color);
    line-height: var(--link-var-line-height);
  }
}

@media screen {
  .f-mini-link, .rep-head .rep-head__control, .entity-list__item.role-item .role-type__expand, .e-table .e-table__row .e-table__cell.control_cell a {
    color: #D52B1E;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .f-mini-link:hover, .rep-head .rep-head__control:hover, .entity-list__item.role-item .role-type__expand:hover, .e-table .e-table__row .e-table__cell.control_cell a:hover {
    color: #a82218;
  }
}

@media screen {
  select {
    font-family: "Raleway", sans-serif;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    border: solid 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 12px 7px;
    background-position: calc(100% - 16px) center;
    background-image: url("../images/icons/i_triangle.svg");
  }
}
@media screen {
  select:focus {
    outline: none;
    border: solid 2px rgba(0, 0, 0, 0.2);
  }
}

@media screen {
  input[type=radio].custom.bullet {
    display: none;
  }
}
@media screen {
  input[type=radio].custom.bullet + label {
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    text-transform: unset;
  }
}
@media screen {
  input[type=radio].custom.bullet + label::before, input[type=radio].custom.bullet + label::after {
    content: "";
    display: block;
  }
}
@media screen {
  input[type=radio].custom.bullet + label::before {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    background-color: #ffffff;
    border: solid 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-sizing: border-box;
  }
}
@media screen {
  input[type=radio].custom.bullet + label::after {
    width: 12px;
    height: 12px;
    background-color: #606060;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 6px;
    transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@media screen {
  input[type=radio].custom.bullet:checked + label::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@media screen {
  input[type=checkbox] {
    display: none;
  }
}
@media screen {
  input[type=checkbox] + label.toggle {
    width: 40px;
    height: 24px;
    display: block;
    border-radius: 12px;
    margin: 0;
    background-color: #e1e1e1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    will-change: background;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  input[type=checkbox] + label.toggle::before {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    margin: 4px 20px 4px 4px;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    will-change: margin;
  }
}
@media screen {
  input[type=checkbox]:checked + label.toggle {
    background-color: #52C78F;
  }
}
@media screen {
  input[type=checkbox]:checked + label.toggle::before {
    margin: 4px 4px 4px 20px;
  }
}
@media screen {
  input[type=checkbox]:disabled + label.toggle {
    background-color: #e1e1e1;
  }
}
@media screen {
  input[type=checkbox]:disabled:checked + label.toggle {
    background-color: #e1e1e1;
  }
}

@media screen {
  input[type=checkbox].checkbox {
    display: none;
  }
}
@media screen {
  input[type=checkbox].checkbox + label {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-transform: none;
    display: flex;
    flex-direction: row;
    letter-spacing: 0;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
}
@media screen {
  input[type=checkbox].checkbox + label::before, input[type=checkbox].checkbox + label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    flex-shrink: 0;
  }
}
@media screen {
  input[type=checkbox].checkbox + label::before {
    width: 20px;
    height: 20px;
    border: solid 2px #828282;
    border-radius: 2px;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  input[type=checkbox].checkbox + label::after {
    height: 6px;
    width: 10px;
    border-bottom: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
    transform-origin: center center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 5px;
    left: 4px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  input[type=checkbox].checkbox + label:not(:empty) {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
}
@media screen {
  input[type=checkbox].checkbox + label:not(:empty)::before {
    margin: 0 24px 0 0;
  }
}
@media screen {
  input[type=checkbox].checkbox:checked + label::before {
    border-color: #00A887;
    background-color: #00A887;
  }
}
@media screen {
  input[type=checkbox].checkbox:checked + label::after {
    opacity: 1;
    visibility: visible;
  }
}

@media screen {
  .f-element.search {
    position: relative;
  }
}
@media screen {
  .f-element.search input[type=text] {
    padding: 0 16px 0 48px;
  }
}
@media screen {
  .f-element.search input[type=submit].search-submit {
    width: 48px;
    height: 48px;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
  }
}
@media screen {
  .f-element.search input[type=submit].search-submit.search-icon {
    background-image: url("../images/icons/i_search.svg");
    background-size: 24px 24px;
  }
}
@media screen {
  .f-element.search input[type=submit].search-submit.clear-icon {
    background-image: url("../images/icons/i_clear.svg");
    background-size: 18px 18px;
  }
}

@media screen {
  h2 {
    font-size: 24px;
    line-height: 1;
  }
}
@media screen {
  h2.extra-bold {
    font-weight: 800;
  }
}

@media screen {
  p {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media screen {
  p.small {
    font-size: 12px;
  }
}
@media screen {
  p.sub-note {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
  }
}

@media screen {
  em {
    font-style: italic;
  }
}

@media screen {
  .sub-note-title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 20px 0;
    font-style: italic;
    font-weight: 700;
  }
}

@media screen {
  .page-title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 40px 0;
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .page-title {
    margin: 0 0 50px 0;
  }
}

@media screen {
  .notification {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
.notification.red {
  --primary-color: #D52B1E;
  --secondary-color: #ffffff;
}
@media screen {
  .notification.red {
    color: var(--secondary-color);
    background-color: var(--primary-color);
  }
}
@media screen {
  .notification .notification__container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
}
@media screen and (min-width: 48em) {
  .notification .notification__container {
    flex-direction: row;
  }
}
@media screen {
  .notification .notification__content {
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
    margin: 0 0 20px 0;
  }
}
@media screen and (min-width: 48em) {
  .notification .notification__content {
    text-align: left;
    margin: 0;
  }
}
@media screen and (min-width: 64em) {
  .notification .notification__content {
    max-width: 600px;
    margin: 0 auto 0 0;
  }
}
@media screen {
  .notification .notification__controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 48em) {
  .notification .notification__controls {
    flex: none;
    align-items: center;
  }
}
@media screen {
  .notification .notification__controls .cta.var {
    --cta-var-primary-color: var(--secondary-color);
    --cta-var-secondary-color: var(--primary-color) ;
  }
}
@media screen and (min-width: 64em) {
  .notification .notification__controls .cta.var {
    margin: 0 0 0 20px;
  }
}
@media screen {
  .notification .notification__controls .f-link.var {
    --link-var-color: var(--secondary-color);
    --link-var-line-height: 50px;
  }
}
@media screen {
  .notification.push-in {
    max-height: 100vh;
  }
}

@media screen {
  .alert {
    margin: 40px 0 0;
    padding: 36px 20px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .alert {
    padding: 20px;
    flex-direction: row;
  }
}
@media screen and (min-width: 64em) {
  .alert {
    margin: 50px 0 0;
  }
}
@media screen {
  .alert.no-margin {
    margin: 0;
  }
}
@media screen {
  .alert .alert__icon {
    width: 25px;
    height: 25px;
    display: block;
    margin: 0 auto 20px auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    flex: none;
  }
}
@media screen and (min-width: 48em) {
  .alert .alert__icon {
    margin: 0 24px auto 0;
  }
}
@media screen {
  .alert .alert__content {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen {
  .alert .alert__content:last-child {
    margin: 0;
  }
}
@media screen {
  .alert.warning {
    border: solid 2px rgba(235, 169, 0, 0.2);
    background-color: rgba(235, 169, 0, 0.05);
  }
}
@media screen {
  .alert.warning .alert__icon {
    background-image: url("../images/icons/i_alert-warning.svg");
  }
}
@media screen {
  .alert.warning .alert__content {
    color: #825410;
  }
}

@media screen {
  .e-modal .e-modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: #ffffff;
    -webkit-animation: fade_in_up 600ms both;
    -moz-animation: fade_in_up 600ms both;
    -o-animation: fade_in_up 600ms both;
    animation: fade_in_up 600ms both;
  }
}
@media screen {
  .e-modal .e-modal__content {
    min-height: 100vh;
    max-height: 100vh;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    outline: none;
    position: relative;
  }
}
@media screen {
  .e-modal .e-modal__wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .e-modal .e-modal__wrapper {
    padding: 100px 0;
    width: 660px;
    margin: 0 auto;
    min-height: 100vh;
  }
}

@media screen {
  .e-modal__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 50px 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media screen {
  .e-modal__head .e-modal__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .e-modal__head .e-modal__title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen {
  .e-modal__head .e-modal__close {
    width: 30px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 18px 18px;
    background-image: url("../images/icons/i_close.svg");
    cursor: pointer;
  }
}
@media screen and (min-width: 48em) {
  .e-modal__head .e-modal__close {
    width: 40px;
    height: 40px;
    background-size: 24px 24px;
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
@media screen {
  .e-modal__head .steps__container {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .e-modal__head .steps__container {
    display: block;
  }
}

@media screen {
  .e-modal__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen {
  .e-table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .e-table {
    padding: 48px 0 0 0;
  }
}
@media screen {
  .e-table .e-table__row {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row {
    flex-direction: row;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row:last-child {
    margin: 0;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row .e-table__cell.alpha {
    flex: none;
    width: 320px;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row .e-table__cell.beta {
    width: 240px;
    margin: 0 20px 0 auto;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell.beta.display-tablet-and-above {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row .e-table__cell.beta.display-tablet-and-above {
    display: flex;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell.control_cell {
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__row .e-table__cell.control_cell {
    position: static;
    width: 60px;
    flex: none;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell.control_cell.min-height {
    min-height: 40px;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell.control_cell label,
  .e-table .e-table__row .e-table__cell.control_cell .control {
    margin: auto 0;
  }
}
@media screen {
  .e-table .e-table__row .e-table__cell.control_cell a {
    margin: auto 0;
  }
}
@media screen {
  .e-table .e-table__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__name {
    margin: 0;
  }
}
@media screen {
  .e-table .e-table__name.remove-margins {
    margin: 0;
  }
}
@media screen {
  .e-table .e-table__data {
    font-size: 14px;
    line-height: 16px;
    color: #828282;
  }
}
@media screen {
  .e-table .e-table__data.tiny {
    font-size: 12px;
  }
}
@media screen {
  .e-table .e-table__data.large {
    font-size: 16px;
  }
}
@media screen {
  .e-table .e-table__data.bold {
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .e-table .e-table__data.vertically-centered {
    margin: auto 0;
  }
}
@media screen {
  .e-table .e-table__data.uppercase {
    text-transform: uppercase;
  }
}
@media screen {
  .e-table .control {
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .e-table .control:hover {
    color: #a82218;
  }
}

@media screen {
  .pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;
  }
}
@media screen {
  .pills li {
    flex: 1 1 auto;
    margin: 8px 8px;
  }
}
@media screen {
  .pills li:last-child {
    flex: none;
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .pills li:last-child {
    flex: 1 1 auto;
    width: auto;
  }
}
@media screen {
  .pills li .pill {
    padding: 0 44px 0 16px;
    display: block;
    color: #6D6A75;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 20px;
    text-decoration: none;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: calc(100% - 16px) center;
    background-size: 12px 12px;
    background-image: url("../images/icons/i_close.svg");
  }
}
@media screen {
  .pills li .pill.add {
    background-color: #ffffff;
    background-image: none;
    border: solid 1px rgba(0, 0, 0, 0.05);
    padding: 0 16px;
    text-align: center;
  }
}

@media screen {
  .tabs {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .tabs li {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
@media screen and (min-width: 23em) {
  .tabs li {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 40px 0 0;
  }
}
@media screen and (min-width: 23em) {
  .tabs li:last-child {
    margin: 0;
  }
}
@media screen {
  .tabs li .tab {
    color: #434343;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding: 30px 0 0;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 23em) {
  .tabs li .tab {
    white-space: nowrap;
  }
}
@media screen {
  .tabs li .tab.is-current {
    font-weight: 700;
    position: relative;
  }
}
@media screen {
  .tabs li .tab.is-current::after {
    content: "";
    display: block;
    width: 24px;
    height: 4px;
    background-color: #434343;
    margin: 26px auto 0 auto;
  }
}
@media screen and (min-width: 23em) {
  .tabs li .tab.is-current::after {
    margin: 26px auto 0 0;
  }
}

@media screen {
  .placeholder {
    color: #434343;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    padding: 20px;
    background-color: rgba(213, 43, 30, 0.05);
  }
}
@media screen and (min-width: 64em) {
  .placeholder {
    padding: 40px 30px;
  }
}

@media screen {
  .placeholder-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen {
  .placeholder-pills li {
    width: calc(50% - 8px);
  }
}
@media screen and (min-width: 48em) {
  .placeholder-pills li {
    width: calc(33.333333% - 10.6666666667px);
    margin: 24px;
  }
}
@media screen {
  .placeholder-pills li:nth-child(odd) {
    margin: 0 8px 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .placeholder-pills li:nth-child(odd) {
    margin: 0 8px 16px 8px;
  }
}
@media screen {
  .placeholder-pills li:nth-child(even) {
    margin: 0 0 16px 8px;
  }
}
@media screen and (min-width: 48em) {
  .placeholder-pills li:nth-child(even) {
    margin: 0 8px 16px 8px;
  }
}
@media screen and (min-width: 48em) {
  .placeholder-pills li:nth-child(3n) {
    margin: 0 0 16px 8px;
  }
}
@media screen and (min-width: 48em) {
  .placeholder-pills li:nth-child(3n-2) {
    margin: 0 8px 16px 0;
  }
}
@media screen {
  .placeholder-pills li .placeholder-pill {
    padding: 0 10px 0 16px;
    display: block;
    color: #6D6A75;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .placeholder-pills li .placeholder-pill::after {
    content: "?";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    margin: auto 0;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

@media screen {
  .account__list {
    margin: 0 -24px;
    padding: 0 0 96px;
    width: calc(100% + 48px);
  }
}
@media screen and (min-width: 64em) {
  .account__list {
    margin: 0;
    padding: 0 0 96px;
    width: auto;
  }
}
@media screen {
  .account__list .account__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px;
    position: relative;
  }
}
@media screen {
  .account__list .account__row .account__cell.alpha {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.alpha {
    display: block;
    width: 18px;
    margin: 0 16px 0 0;
  }
}
@media screen {
  .account__list .account__row .account__cell.beta {
    width: 100%;
    margin: 0 0 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.beta {
    width: auto;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
  }
}
@media screen {
  .account__list .account__row .account__cell.beta .account__name {
    max-width: calc(100% - 46px);
  }
}
@media screen {
  .account__list .account__row .account__cell.beta .account__details {
    width: 100%;
  }
}
@media screen {
  .account__list .account__row .account__cell.beta .account__toggle {
    margin-right: 16px;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.beta .account__toggle.mobile-only {
    display: none;
  }
}
@media screen {
  .account__list .account__row .account__cell.beta .account__toggle + .account__name {
    max-width: calc(100% - 16px - 18px - 46px);
  }
}
@media screen {
  .account__list .account__row .account__cell.gamma, .account__list .account__row .account__cell.delta {
    flex: none;
    width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.gamma, .account__list .account__row .account__cell.delta {
    width: 150px;
  }
}
@media screen and (min-width: 64em) {
  .account__list .account__row .account__cell.gamma, .account__list .account__row .account__cell.delta {
    width: 200px;
  }
}
@media screen {
  .account__list .account__row .account__cell.gamma {
    text-align: left;
  }
}
@media screen {
  .account__list .account__row .account__cell.delta {
    text-align: right;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.delta {
    text-align: left;
  }
}
@media screen {
  .account__list .account__row .account__cell.epsilon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__row .account__cell.epsilon {
    position: static;
    width: 80px;
    text-align: right;
  }
}
@media screen {
  .account__list .account__head {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head {
    display: block;
    background-color: #f2f2f2;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row {
    padding: 10px 24px;
    border: none;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell {
    color: #828282;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell.alpha {
    order: 2;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell.beta {
    order: 1;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell.gamma {
    order: 3;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell.delta {
    order: 4;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__head .account__row .account__cell.epsilon {
    order: 5;
  }
}
@media screen {
  .account__list .account__foot {
    display: flex;
    flex-direction: row;
    padding: 24px;
  }
}
@media screen and (min-width: 64em) {
  .account__list .account__foot {
    padding: 24px 0;
  }
}
@media screen {
  .account__list .account__foot .account__refresh {
    color: #828282;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 0 0 32px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    background-size: 24px 24px;
    background-image: url("../images/icons/i_refresh.svg");
    cursor: pointer;
  }
}
.account__list .account__item.white {
  background-color: #fff;
}
@media screen {
  .account__list .account__item .account__children {
    background-color: #f2f2f2;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__children {
    padding: 0 0 0 56px;
  }
}
@media screen {
  .account__list .account__item .account__children .account__item {
    position: relative;
  }
}
@media screen {
  .account__list .account__item .account__children .account__item .account__row {
    border: none;
  }
}
.account__list .account__item .account__name.loading-placeholder {
  background-color: #E5E5E5;
  width: 50%;
  height: 16px;
  margin-top: 0;
}
@media screen {
  .account__list .account__item .account__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 80em) {
  .account__list .account__item .account__name:hover {
    color: #828282;
  }
}
@media screen {
  .account__list .account__item .account__name .root__account {
    color: #ffffff;
    background-color: #000000;
    font-size: 12px;
    line-height: 20px;
    font-weight: 800;
    display: inline-block;
    padding: 0 8px;
    margin: auto 0 auto 16px;
    border-radius: 20px;
  }
}
@media screen {
  .account__list .account__item .account__details {
    padding: 24px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__details {
    padding: 14px 0 0 0;
  }
}
.account__list .account__item .account__details li.loading-placeholder {
  background-color: #E5E5E5;
  width: 50%;
  height: 12px;
  margin-top: 8px;
}
@media screen {
  .account__list .account__item .account__details li {
    color: #828282;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__details li {
    font-size: 14px;
  }
}
@media screen {
  .account__list .account__item .account__details li strong {
    font-weight: 700;
  }
}
@media screen {
  .account__list .account__item .value-mobile {
    display: block;
    color: #828282;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .value-mobile {
    display: none;
  }
}
.account__list .account__item .value-tablet.loading-placeholder {
  background-color: #E5E5E5;
  width: 50%;
  height: 16px;
}
@media screen {
  .account__list .account__item .value-tablet {
    display: none;
    color: #828282;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .value-tablet {
    display: block;
  }
}
@media screen {
  .account__list .account__item .account____action {
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account____action {
    display: inline-block;
  }
}
@media screen {
  .account__list .account__item .account__toggle {
    width: 18px;
    height: 18px;
    display: block;
    position: relative;
    margin: 3px 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 18px 18px;
  }
}
.account__list .account__item .account__toggle.loading-placeholder {
  margin-top: 0;
}
@media screen {
  .account__list .account__item .account__toggle.loading-placeholder {
    background-image: url("../images/icons/i_placeholder.svg");
  }
}
@media screen {
  .account__list .account__item .account__toggle.open {
    background-image: url("../images/icons/i_add.svg");
  }
}
@media screen {
  .account__list .account__item .account__toggle.close {
    background-image: url("../images/icons/i_substract.svg");
  }
}
@media screen {
  .account__list .account__item .account__row:not(:only-child) {
    background-color: #f2f2f2;
  }
}

@media screen {
  .account__list .account__item {
    border-bottom: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__children {
    padding: 0 0 0 56px;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__children .account__children {
    padding: 0 0 0 32px;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__children .account__item {
    border-top: solid 2px #e1e1e1;
    border-bottom: none;
  }
}
@media screen and (min-width: 48em) {
  .account__list .account__item .account__children .account__item .account__row {
    padding-left: 0;
  }
}

@media screen {
  .inline-menu {
    padding: 32px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 9;
  }
}
@media screen {
  .inline-menu.arrow.top {
    margin: 24px 0 0 0;
  }
}
@media screen {
  .inline-menu.arrow.top::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -10px;
  }
}
@media screen {
  .inline-menu.arrow.top.center::before {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@media screen {
  .inline-menu.arrow.top.right::before {
    right: 24px;
  }
}

@media screen {
  .inline-menu__container {
    position: relative;
  }
}
@media screen {
  .inline-menu__container .inline-menu__trigger + .inline-menu {
    max-height: 0;
    min-width: 320px;
    opacity: 0;
    visibility: hidden;
    top: 24px;
    right: 0;
  }
}
@media screen {
  .inline-menu__container .inline-menu__trigger.is-active + .inline-menu {
    max-height: 999vh;
    opacity: 1;
    visibility: visible;
    -webkit-animation: fade_in_down 600ms both;
    -moz-animation: fade_in_down 600ms both;
    -o-animation: fade_in_down 600ms both;
    animation: fade_in_down 600ms both;
  }
}

@media screen {
  .filter__container {
    position: relative;
    display: flex;
  }
}
@media screen {
  .filter__container .filter__trigger {
    color: #828282;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline;
    flex-direction: row;
    margin: auto 0;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .filter__container .filter__trigger {
    display: flex;
  }
}
@media screen {
  .filter__container .filter__trigger .mobile-only {
    display: inline;
  }
}
@media screen and (min-width: 48em) {
  .filter__container .filter__trigger .mobile-only {
    display: none;
  }
}
@media screen {
  .filter__container .filter__trigger::after {
    content: "";
    display: inline;
    width: 0;
    height: 0;
    margin: auto 10px auto 10px;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: #C4C4C4 transparent transparent transparent;
    position: relative;
    top: 8px;
  }
}
@media screen and (min-width: 25em) {
  .filter__container .filter__trigger::after {
    margin: auto 0 auto 10px;
  }
}
@media screen and (min-width: 48em) {
  .filter__container .filter__trigger::after {
    position: static;
    display: block;
  }
}
@media screen {
  .filter__container .filter__trigger + .filter__content {
    max-height: 0;
    width: 240px;
    opacity: 0;
    visibility: hidden;
    margin-top: 44px;
  }
}
@media screen {
  .filter__container .filter__trigger.is-active + .filter__content {
    max-height: 999vh;
    opacity: 1;
    visibility: visible;
    -webkit-animation: fade_in_down 600ms both;
    -moz-animation: fade_in_down 600ms both;
    -o-animation: fade_in_down 600ms both;
    animation: fade_in_down 600ms both;
  }
}

@media screen {
  .filter {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .filter li {
    margin: 0;
  }
}
@media screen {
  .filter li:last-child {
    margin: 0;
  }
}
@media screen {
  .filter li .filter-item {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    text-decoration: none;
  }
}
@media screen {
  .filter li .filter-item.is-selected {
    color: #828282;
  }
}

@media screen {
  .page__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0 0 0;
    padding: 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .page__filters {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 48em) {
  .page__filters .filter__container {
    margin: 0 40px 0 0;
  }
}
@media screen and (min-width: 48em) {
  .page__filters .filter__container:last-child {
    margin: 0;
  }
}
@media screen {
  .page__filters .filter__container:first-child .filter__content {
    left: 0;
  }
}
@media screen {
  .page__filters .filter__container:last-child .filter__content {
    right: 0;
  }
}

@media screen {
  .steps__container .steps {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
  }
}
@media screen {
  .steps__container .steps .step {
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: #bdbdbd;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 20px 0 0;
    position: relative;
  }
}
@media screen {
  .steps__container .steps .step::after {
    content: "";
    display: block;
    height: 2px;
    width: 20px;
    background-color: #bdbdbd;
    position: absolute;
    right: -22px;
    top: 1px;
  }
}
@media screen {
  .steps__container .steps .step:last-child {
    margin: 0;
  }
}
@media screen {
  .steps__container .steps .step:last-child::after {
    display: none;
  }
}
@media screen {
  .steps__container .steps .step.is-current {
    border-color: #D52B1E;
  }
}
@media screen {
  .steps__container .steps .step.is-done {
    border-color: #D52B1E;
    background-color: #D52B1E;
  }
}
@media screen {
  .steps__container .steps .step.is-done::after {
    background-color: #D52B1E;
  }
}

@media screen {
  .header {
    width: 100%;
    height: 200px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  .header::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: center center;
    background-image: url("../images/header-bg.jpg");
    opacity: 0.1;
  }
}
@media screen {
  .header .header__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
@media screen {
  .header .header__wrapper .header__back {
    color: #bdbdbd;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    display: block;
    position: absolute;
    top: 24px;
    text-decoration: none;
    padding: 0 0 0 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    background-size: 9px 14px;
    background-image: url("../images/icons/i_chevron_left.svg");
  }
}
@media screen {
  .header .header__wrapper .header__title {
    color: #ffffff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 800;
    margin: auto 0;
  }
}
@media screen and (min-width: 64em) {
  .header .header__wrapper .header__title {
    font-size: 48px;
    line-height: 56px;
  }
}

@media screen {
  .entity-list .entity-list__group-head {
    margin: 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-list .entity-list__group-head {
    margin: 32px 0;
  }
}
@media screen {
  .entity-list .entity-list__group-head:first-child {
    margin-top: 0;
  }
}
@media screen {
  .entity-list .entity-list__group-head .group-head__name {
    color: #828282;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@media screen {
  .entity-list.sub-list {
    margin: 0 -24px 48px;
    padding: 0 24px;
    border-top: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .entity-list.sub-list {
    margin: 0 0 48px;
    padding: 0;
  }
}
@media screen and (min-width: 64em) {
  .entity-list.sub-list {
    margin: 0 0 64px;
  }
}

@media screen {
  .entity-list__item.sub {
    padding: 24px 0;
    border-bottom: solid 2px #e1e1e1;
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub {
    padding: 48px 0;
  }
}
@media screen {
  .entity-list__item.sub .sub__row {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__row {
    flex-direction: row;
  }
}
@media screen {
  .entity-list__item.sub .sub__row .sub__cell.alpha {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__row .sub__cell.alpha {
    display: block;
    width: 166px;
  }
}
@media screen {
  .entity-list__item.sub .sub-type {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-list__item.sub .sub-type .sub-type__avatar {
    width: 86px;
    height: 79px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 86px 79px;
    margin: 0 auto 16px;
  }
}
@media screen {
  .entity-list__item.sub .sub-type .sub-type__avatar.auth {
    background-image: url("../images/icons/i_sub_auth.svg");
  }
}
@media screen {
  .entity-list__item.sub .sub-type .sub-type__avatar.data-capture {
    background-image: url("../images/icons/i_sub_data.svg");
  }
}
@media screen {
  .entity-list__item.sub .sub-type .sub-type__avatar.bar-code {
    background-image: url("../images/icons/i_sub_bar.svg");
  }
}
@media screen {
  .entity-list__item.sub .sub-type .sub-type__name {
    display: block;
    color: #828282;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__type {
    color: #828282;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__info .sub__type {
    display: none;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__details {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__details li {
    color: #828282;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__details li strong {
    font-weight: 700;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__users {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__info .sub__users {
    justify-content: flex-start;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__users .users-count {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen {
  .entity-list__item.sub .sub__info .sub__users .users-add-trigger {
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: 18px 18px;
    background-image: url("../images/icons/i_add.svg");
    margin: auto 0;
    padding: 0 24px 0 0;
    cursor: pointer;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__info .sub__users .users-add-trigger {
    margin: auto 0 auto 24px;
  }
}
@media screen {
  .entity-list__item.sub .sub__delete {
    position: absolute;
    top: 24px;
    right: 0;
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__delete {
    top: 68px;
  }
}
@media screen {
  .entity-list__item.sub .sub__delete:hover {
    color: #a82218;
  }
}
@media screen {
  .entity-list__item.sub .sub__authorize {
    position: absolute;
    top: 24px;
    right: 0;
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__authorize {
    top: 88px;
  }
}
@media screen {
  .entity-list__item.sub .sub__authorize:hover {
    color: #a82218;
  }
}
@media screen {
  .entity-list__item.sub .sub__edit {
    position: absolute;
    top: 24px;
    right: 0;
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.sub .sub__edit {
    top: 48px;
  }
}
@media screen {
  .entity-list__item.sub .sub__edit:hover {
    color: #a82218;
  }
}

@media screen {
  .entity-list.user-list {
    padding: 24px 0;
  }
}
.entity-list.bottom-spacer {
  margin: 0 0 48px 0;
}

@media screen {
  .entity-list__item.user-item {
    padding: 24px 0;
    border-bottom: solid 2px #e1e1e1;
    position: relative;
  }
}
@media screen {
  .entity-list__item.user-item:last-child {
    border: none;
  }
}
@media screen {
  .entity-list__item.user-item .user__row {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.user-item .user__row {
    flex-direction: row;
  }
}
@media screen {
  .entity-list__item.user-item .user__row .user__cell.alpha {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.user-item .user__row .user__cell.alpha {
    display: block;
    width: 100px;
    margin: 0 42px 0 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.user-item .user__row .user__cell.beta {
    max-width: 220px;
  }
}
@media screen {
  .entity-list__item.user-item .user__row .user__cell.gamma {
    position: absolute;
    top: 24px;
    right: 0;
  }
}
@media screen {
  .entity-list__item.user-item .user-type__icon {
    width: 84px;
    height: 84px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    margin: 0 0 16px auto;
  }
}
@media screen {
  .entity-list__item.user-item .user-type__icon.admin {
    background-image: url("../images/icons/i_user_type_admin.svg");
    background-size: 76px 83px;
  }
}
@media screen {
  .entity-list__item.user-item .user-type__icon.manager, .entity-list__item.user-item .user-type__icon.user {
    background-image: url("../images/icons/i_user_type_user.svg");
    background-size: 82px 82px;
  }
}
@media screen {
  .entity-list__item.user-item .user-type__role {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-align: center;
    max-width: 84px;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .entity-list__item.user-item .user__action {
    color: #D52B1E;
    font-size: 12px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    cursor: pointer;
  }
}
@media screen {
  .entity-list__item.user-item .user__action:hover {
    color: #a82218;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.user-item .user__info {
    width: 400px;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__details {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__details li {
    color: #828282;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__details li strong {
    font-weight: 700;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__role {
    color: #828282;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__email {
    color: #828282;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    margin: 0 0 16px 0;
    display: block;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-count {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-list li {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-list li:first-child {
    margin: 24px 0 16px 0;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-list li:last-child {
    margin: 0;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-add-trigger {
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: 18px 18px;
    background-image: url("../images/icons/i_add.svg");
    margin: auto 0;
    padding: 0 24px 0 0;
    cursor: pointer;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-add-trigger + .subs-list {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 1500ms ease-in-out 0ms;
    -moz-transition: all 1500ms ease-in-out 0ms;
    -ms-transition: all 1500ms ease-in-out 0ms;
    transition: all 1500ms ease-in-out 0ms;
    will-change: max-height;
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-add-trigger.is-active {
    background-image: url("../images/icons/i_substract.svg");
  }
}
@media screen {
  .entity-list__item.user-item .user__info .user__subs .subs-add-trigger.is-active + .subs-list {
    max-height: 999vh;
    opacity: 1;
    visibility: visible;
  }
}

@media screen {
  .entity-list.role-list {
    display: flex;
    flex-direction: column;
    padding: 48px 0;
  }
}

@media screen {
  .entity-list__item.role-item {
    padding: 0 0 24px 0;
    margin: 0 0 24px 0;
    border-bottom: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item {
    padding: 0 0 32px 0;
    margin: 0 0 32px 0;
  }
}
@media screen {
  .entity-list__item.role-item .role__body {
    max-height: 0px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: all 600ms ease-in-out 0ms;
    -moz-transition: all 600ms ease-in-out 0ms;
    -ms-transition: all 600ms ease-in-out 0ms;
    transition: all 600ms ease-in-out 0ms;
  }
}
@media screen {
  .entity-list__item.role-item .role__head .role__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role__head .role__row {
    justify-content: space-between;
  }
}
@media screen {
  .entity-list__item.role-item .role__head .role__row .role__cell.alpha {
    margin: 0 16px 0 0;
  }
}
@media screen {
  .entity-list__item.role-item .role__head .role__row .role__cell.beta {
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 100px);
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role__head .role__row .role__cell.beta {
    position: absolute;
    top: 12px;
    left: 120px;
  }
}
@media screen {
  .entity-list__item.role-item .role__head .role__row .role__cell.gamma {
    flex: none;
    width: 100%;
    padding: 24px 0 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role__head .role__row .role__cell.gamma {
    max-width: 380px;
    position: absolute;
    bottom: 12px;
    left: 120px;
    padding: 0;
  }
}
@media screen {
  .entity-list__item.role-item .role__head .role__row .role__cell.delta {
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role__head .role__row .role__cell.delta {
    position: static;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .entity-list__item.role-item .role__head.is-active + .role__body {
    padding: 24px 0 0 0;
    max-height: 999vh;
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role__head.is-active + .role__body {
    padding: 32px 0 0 0;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__icon {
    width: 40px;
    height: 40px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 40px 40px;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role-type__icon {
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__icon.admin {
    background-image: url("../images/icons/i_mini_user_type_admin.svg");
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role-type__icon.admin {
    background-image: url("../images/icons/i_user_type_admin.svg");
  }
}
@media screen {
  .entity-list__item.role-item .role-type__icon.manager, .entity-list__item.role-item .role-type__icon.user {
    background-image: url("../images/icons/i_mini_user_type_user.svg");
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role-type__icon.manager, .entity-list__item.role-item .role-type__icon.user {
    background-image: url("../images/icons/i_user_type_user.svg");
  }
}
@media screen {
  .entity-list__item.role-item .role-type__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin: auto 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role-type__name {
    line-height: 24px;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__description {
    color: #828282;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
.entity-list__item.role-item .role-type__expand {
  line-height: 40px;
}
@media screen and (min-width: 48em) {
  .entity-list__item.role-item .role-type__expand {
    margin: auto 0;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__expand .role-type__open {
    display: block;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__expand .role-type__close {
    display: none;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__expand.is-active .role-type__open {
    display: none;
  }
}
@media screen {
  .entity-list__item.role-item .role-type__expand.is-active .role-type__close {
    display: block;
  }
}

@media screen {
  .context {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .context {
    margin: 0 0 12px 0;
  }
}
@media screen and (min-width: 48em) {
  .context:only-child {
    margin: 0;
  }
}
@media screen {
  .context .context__cell.alpha {
    flex-grow: 1;
  }
}
@media screen {
  .context .context__cell.beta {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    position: relative;
  }
}
@media screen {
  .context .context__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
  }
}
@media screen {
  .context .context__title.large {
    font-size: 18px;
    font-weight: 800;
  }
}
@media screen {
  .context .context__trigger {
    color: #D52B1E;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    display: block;
    white-space: nowrap;
    margin: auto 0;
    cursor: pointer;
  }
}
@media screen {
  .context .context__trigger:hover {
    color: #a82218;
  }
}
@media screen {
  .context.child {
    padding: 0 0 0 40px;
    position: relative;
  }
}
@media screen {
  .context.child::before {
    content: "";
    display: block;
    height: 30px;
    width: 10px;
    border-bottom: solid 1px #828282;
    border-left: solid 1px #828282;
    position: absolute;
    bottom: 12px;
    left: 20px;
  }
}
@media screen and (min-width: 48em) {
  .context.child::before {
    height: 20px;
    width: 10px;
  }
}
@media screen {
  .context.child .context__cell.alpha .context__title {
    color: #828282;
  }
}

@media screen {
  .aum.mobile {
    width: 100vw;
    margin: 0 -24px 32px;
    padding: 24px 24px 24px 24px;
    background-color: #f2f2f2;
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .aum.mobile {
    display: none;
  }
}
@media screen {
  .aum.mobile::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #f2f2f2 transparent;
    position: absolute;
    top: -12px;
    right: 48px;
  }
}
@media screen {
  .aum .aum__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .aum .aum__list {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .aum .aum__list .aum__row {
    display: flex;
    flex-direction: row;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .aum .aum__list .aum__row .aum__cell.alpha {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}
@media screen {
  .aum .aum__list .aum__row .aum__cell.beta {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .aum .aum__list .aum__row .aum__cell.beta label {
    margin: auto 0 auto auto;
  }
}
@media screen {
  .aum .aum__list .aum__row .aum__cell .aum__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    display: block;
  }
}
@media screen {
  .aum .aum__list .aum__row .aum__cell .aum__email {
    color: #828282;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: block;
  }
}

@media screen {
  .add-role-list {
    width: 100%;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .add-role-list li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .add-role-list li {
    justify-content: flex-start;
  }
}
@media screen {
  .add-role-list li:last-child {
    margin: 0;
  }
}
@media screen {
  .add-role-list li input[type=checkbox].checkbox + label {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin: 0;
  }
}
@media screen {
  .add-role-list li input[type=checkbox].checkbox + label::before {
    margin: 0 16px 0 0;
  }
}

@media screen {
  .tooltip-trigger {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    cursor: pointer;
    margin: 0 0 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .tooltip-trigger {
    margin: 0 auto 0 16px;
  }
}
@media screen {
  .tooltip-trigger.info {
    background-size: 16px 16px;
    background-image: url("../images/icons/i_info.svg");
  }
}

@media screen {
  .rc-tooltip {
    z-index: 99;
    display: inline-block;
    max-width: 200px;
  }
}
@media screen {
  .rc-tooltip .rc-tooltip-content {
    z-index: 99;
    display: inline-block;
    max-width: 200px;
    background-color: #e5e5e5;
    border-radius: 5px;
    padding: 16px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    position: relative;
  }
}
@media screen {
  .rc-tooltip.rc-tooltip-placement-left .rc-tooltip-content {
    -webkit-transform: translate3d(-16px, 0, 0);
    -moz-transform: translate3d(-16px, 0, 0);
    -ms-transform: translate3d(-16px, 0, 0);
    transform: translate3d(-16px, 0, 0);
  }
}
@media screen {
  .rc-tooltip.rc-tooltip-placement-left .rc-tooltip-content::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #e5e5e5;
    position: absolute;
    right: -4px;
    top: 50%;
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}
@media screen {
  .rc-tooltip.rc-tooltip-placement-right .rc-tooltip-content {
    -webkit-transform: translate3d(16px, 0, 0);
    -moz-transform: translate3d(16px, 0, 0);
    -ms-transform: translate3d(16px, 0, 0);
    transform: translate3d(16px, 0, 0);
  }
}
@media screen {
  .rc-tooltip.rc-tooltip-placement-right .rc-tooltip-content::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 4px 0;
    border-color: transparent #e5e5e5 transparent transparent;
    position: absolute;
    left: -4px;
    top: 50%;
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}

@media screen {
  .permission-list {
    width: 100%;
  }
}
@media screen {
  .permission-list .premission-group {
    padding: 0 0 24px 0;
    margin: 0 0 24px 0;
    border-bottom: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .permission-list .premission-group {
    padding: 0 0 32px 0;
    margin: 0 0 32px 0;
  }
}
@media screen {
  .permission-list .premission-group:last-child {
    padding: 0;
    margin: 0;
    border: none;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__head {
    color: #828282;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 24px;
    letter-spacing: 1px;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__list .permission-group__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__list .permission-group__row:last-child {
    margin: 0;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__list .permission-group__row:only-of-type {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .permission-list .premission-group .permission-group__list .permission-group__row:only-of-type {
    margin: 0;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__list .permission-group__row .permission-group__cell .permission__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: block;
  }
}
@media screen {
  .permission-list .premission-group .permission-group__list .permission-group__row .permission-group__cell label {
    margin: 0;
  }
}

@media screen {
  .rep__list {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 64em) {
  .rep__list {
    margin: 0 0 58px 0;
  }
}
@media screen {
  .rep__list .rep__item {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }
}
@media screen {
  .rep__list .rep__item .rep__designation {
    margin: 0 0 24px 0;
  }
}
@media screen {
  .rep__list .rep__item .rep__designation .rep__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 21px;
    font-weight: 800;
    margin: 0 0 8px 0;
  }
}
@media screen {
  .rep__list .rep__item .rep__designation .rep__id {
    color: #828282;
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row {
    padding: 0 0 16px 0;
    margin: 0 0 16px 0;
    border-bottom: solid 2px rgba(225, 225, 225, 0.5);
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px 10px;
    margin: 0;
    border: none;
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__row {
    padding: 16px 20px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell {
    flex: none;
    margin: 0 10px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell:last-child {
    margin: 0;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell::before {
    display: block;
    color: #828282;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 auto 0 0;
  }
}
@media screen and (min-width: 25em) {
  .rep__list .rep__item .rep__row .rep__cell::before {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell::before {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.sub {
    width: 130px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.sub::before {
    content: "Subscription";
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.type {
    width: 130px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.type::before {
    content: "Type";
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.auth {
    width: 95px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.auth::before {
    content: "Authentication";
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.data {
    width: 95px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.data::before {
    content: "Data Capture";
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.bar {
    width: 95px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.bar::before {
    content: "Barcode Only";
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__row .rep__cell.total {
    width: 105px;
  }
}
@media screen {
  .rep__list .rep__item .rep__row .rep__cell.total::before {
    content: "Total";
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__row .rep__cell.sub, .rep__list .rep__item .rep__row .rep__cell.type, .rep__list .rep__item .rep__row .rep__cell.auth, .rep__list .rep__item .rep__row .rep__cell.data, .rep__list .rep__item .rep__row .rep__cell.bar {
    width: 16.6666666667%;
  }
}
@media screen {
  .rep__list .rep__item .rep__head {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__head {
    background-color: #f2f2f2;
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__head .rep__row .rep__cell {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__head .rep__row .rep__cell {
    font-size: 16px;
  }
}
@media screen {
  .rep__list .rep__item .rep__body .rep__row .rep__cell {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}
@media screen and (min-width: 25em) {
  .rep__list .rep__item .rep__body .rep__row .rep__cell {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__body .rep__row .rep__cell {
    font-size: 12px;
    line-height: 20px;
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__body .rep__row .rep__cell {
    font-size: 14px;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__foot {
    border-top: solid 2px #f2f2f2;
  }
}
@media screen {
  .rep__list .rep__item .rep__foot .rep__row:only-child {
    padding: 0;
    margin: 0 0 16px;
    border-bottom: none;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__foot .rep__row:only-child {
    padding: 16px 10px;
    margin: 0;
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__foot .rep__row:only-child {
    padding: 16px 20px;
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell {
    font-size: 16px;
  }
}
@media screen {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell.sub, .rep__list .rep__item .rep__foot .rep__row .rep__cell.type {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell.sub, .rep__list .rep__item .rep__foot .rep__row .rep__cell.type {
    display: block;
  }
}
@media screen {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell.sub::before, .rep__list .rep__item .rep__foot .rep__row .rep__cell.type::before {
    display: none;
  }
}
@media screen {
  .rep__list .rep__item .rep__children {
    margin: 0 -24px;
    padding: 0 24px;
    background-color: #e5e5e5;
    -webkit-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 64em) {
  .rep__list .rep__item .rep__children {
    margin: 0;
    padding: 0 0 0 24px;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item.primary {
    padding: 0 0 48px 0;
    margin: 0 0 24px 0;
    border-bottom: solid 2px #e1e1e1;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item.primary .rep__row .rep__cell {
    width: calc(20% - 10px);
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item.primary .rep__row .rep__cell.auth, .rep__list .rep__item.primary .rep__row .rep__cell.data, .rep__list .rep__item.primary .rep__row .rep__cell.bar, .rep__list .rep__item.primary .rep__row .rep__cell.total {
    text-align: right;
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item.primary .rep__foot {
    background-color: #f2f2f2;
  }
}
@media screen and (min-width: 48em) {
  .rep__list .rep__item.primary .rep__foot .rep__row .rep__cell {
    font-size: 18px;
    font-weight: 700;
  }
}

.rep-head {
  margin: 0 0 16px;
}
@media screen and (min-width: 48em) {
  .rep-head {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .rep-head:last-child {
    margin: 0 0 40px;
  }
}
@media screen {
  .rep-head .rep-head__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .rep-head .rep-head__title {
    margin: 0;
  }
}
@media screen {
  .rep-head .rep-head__control {
    display: inline-block;
    line-height: 24px;
    padding: 0 0 0 32px;
    margin: auto 0 auto auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    background-size: 18px 20px;
    background-image: url("../images/icons/i_download_red.svg");
  }
}

@media print {
  .rep__list {
    background-color: #ffffff;
  }
}
@media print {
  .rep__list > * {
    page-break-inside: avoid;
  }
}
.rep__list .rep-head {
  display: none;
}
@media print {
  .rep__list .rep-head {
    display: flex;
    flex-direction: column;
    padding: 0.5cm 0.5cm 0cm 0.5cm;
    margin: 0 0 1cm 0;
  }
}
.rep__list .rep-head .rep-head__title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin: 0 0 16px 0;
}
@media print {
  .rep__list .rep__item {
    display: flex;
    flex-direction: column;
    padding: 0.5cm 0.5cm 0cm 0.5cm;
    margin: 0 0 1cm 0;
  }
}
@media print {
  .rep__list .rep__item:last-of-type {
    padding: 0.5cm 0.5cm 0.5cm 0.5cm;
  }
}
@media print {
  .rep__list .rep__item .rep__designation {
    margin: 0 0 0.5cm 0;
  }
}
@media print {
  .rep__list .rep__item .rep__designation .rep__name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 21px;
    font-weight: 800;
    margin: 0 0 0.25cm 0;
  }
}
@media print {
  .rep__list .rep__item .rep__designation .rep__id {
    color: #828282;
    font-size: 14px;
    line-height: 16px;
  }
}
@media print {
  .rep__list .rep__item .rep__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 20px;
    margin: 0;
  }
}
@media print {
  .rep__list .rep__item .rep__row .rep__cell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: none;
    margin: 0;
    align-items: center;
  }
}
@media print {
  .rep__list .rep__item .rep__row .rep__cell:last-child {
    margin: 0;
  }
}
@media print {
  .rep__list .rep__item .rep__row .rep__cell.sub {
    width: 25%;
    padding: 0 0.25cm 0 0;
  }
}
@media print {
  .rep__list .rep__item .rep__row .rep__cell.type {
    width: 15%;
  }
}
@media print {
  .rep__list .rep__item .rep__row .rep__cell.auth, .rep__list .rep__item .rep__row .rep__cell.data, .rep__list .rep__item .rep__row .rep__cell.bar, .rep__list .rep__item .rep__row .rep__cell.total {
    justify-content: center;
    width: 15%;
  }
}
@media print {
  .rep__list .rep__item .rep__head {
    background-color: #f2f2f2;
    display: block;
  }
}
@media print {
  .rep__list .rep__item .rep__head .rep__row .rep__cell {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media print {
  .rep__list .rep__item .rep__body .rep__row .rep__cell {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}
@media print {
  .rep__list .rep__item .rep__foot {
    border-top: solid 2px #f2f2f2;
  }
}
@media print {
  .rep__list .rep__item .rep__foot .rep__row:only-child {
    padding: 16px 20px;
    margin: 0;
    border-bottom: none;
  }
}
@media print {
  .rep__list .rep__item .rep__foot .rep__row .rep__cell {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media print {
  .rep__list .rep__item .rep__children {
    margin: 0;
    padding: 0 0 0 24px;
    background-color: #e5e5e5;
    -webkit-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -4px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

@media screen {
  .asign-new-sub.mobile {
    width: 100vw;
    flex: none;
    display: block;
    margin: 16px -24px 0;
    padding: 24px;
    background-color: #f2f2f2;
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub.mobile {
    display: none;
  }
}
@media screen {
  .asign-new-sub.mobile::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 16px 12px;
    border-color: transparent transparent #f2f2f2 transparent;
    position: absolute;
    top: -12px;
    right: 24px;
  }
}
@media screen {
  .asign-new-sub.tablet-and-above {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub.tablet-and-above {
    display: block;
    width: 400px;
    padding: 32px;
    position: absolute;
    z-index: 999;
    top: 48px;
    right: 0;
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen {
  .asign-new-sub.tablet-and-above::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 16px 12px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -12px;
    right: 24px;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__title {
    margin: 0 0 24px 0;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__search {
    background-color: transparent;
    border: none;
    border-bottom: solid 2px rgba(0, 0, 0, 0.1);
    padding: 0 32px 0 0;
    margin: 0 0 24px 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: right center;
    background-size: 24px 24px;
    background-image: url("../images/icons/i_search.svg");
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__search {
    margin: 0 0 32px 0;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__list {
    margin: 0 0 32px 0;
    max-height: 261px;
    overflow-y: scroll;
    margin-right: -15px;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__head {
    color: #828282;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 24px;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__head {
    margin: 0 0 32px 0;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item {
    margin: 0 0 32px 0;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item input[type=checkbox] + label::before {
    margin: 0;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item .asign-new-sub__details {
    margin: 0 0 0 16px;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item .asign-new-sub__details .asign-new-sub__item-name {
    color: #828282;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    display: block;
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item .asign-new-sub__details .asign-new-sub__item-name .highlight {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  .asign-new-sub .asign-new-sub__list .asign-new-sub__item .asign-new-sub__details .asign-new-sub__item-id {
    color: #828282;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
}

@media screen and (min-width: 48em) {
  .asign-new-sub__trigger + .asign-new-sub.tablet-and-above {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 48em) {
  .asign-new-sub__trigger.is-active + .asign-new-sub.tablet-and-above {
    max-height: 999vh;
    opacity: 1;
    visibility: visible;
    overflow: unset;
  }
}

@media screen {
  .DayPickerInput-OverlayWrapper {
    margin: 24px 0 0 0;
  }
}

@media screen {
  .DayPicker {
    padding: 24px 16px 8px 16px;
    -webkit-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen {
  .DayPicker::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -8px;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-NavBar {
    width: 100%;
    height: 20px;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton {
    position: static;
    margin: 0;
    position: relative;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton.DayPicker-NavButton--prev {
    background-image: url("../images/icons/i_cal_chevron_left.svg");
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton.DayPicker-NavButton--next {
    background-image: url("../images/icons/i_cal_chevron_right.svg");
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Caption {
    margin-top: -35px;
    margin-bottom: 15px;
    text-align: center;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Caption div {
    font-weight: 700;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day {
    font-weight: 700;
  }
}
@media screen {
  .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--selected {
    border-radius: 0;
    color: #ffffff;
    background-color: #D52B1E;
  }
}

.calendar-container {
  width: 360px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/*  SECTIONS  */
.section_mp {
  clear: both;
  padding: 0px;
  margin: 0px;
}

/*  COLUMN SETUP  */
.col_mp {
  display: block;
  float: left;
  text-align: center;
}

.col_mp:first-child {
  margin-left: 0;
}

/*  GROUPING  */
.group_mp:before, .group:after {
  content: "";
  display: table;
}

.group_mp:after {
  clear: both;
}

.group_mp {
  zoom: 1; /* For IE 6/7 */
}

/*  GRID OF THREE  */
.span_1_of_3_mp {
  width: 33.33%;
}

.col_mp {
  font-size: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.col_mp:hover {
  color: white;
  background-color: darkslateblue;
  cursor: pointer;
}

.selected_date_mp {
  font-size: 12px;
  color: darkslateblue;
  font-weight: bold;
}

.selected_cell {
  background-color: darkslateblue;
  font-style: italic;
  color: #fff;
}

.arrows_mp {
  font-weight: bold;
  font-size: 18px;
}

@media screen {
  .calendar-container {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    width: 100%;
    position: absolute;
    z-index: 99;
    padding: 16px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    border: none;
    top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48em) {
  .calendar-container {
    left: 0;
    width: 320px;
  }
}
@media screen {
  .calendar-container::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -8px;
  }
}
@media screen {
  .calendar-container .col_mp {
    font-size: 16px;
    font-weight: 700;
    width: 33.3333333333%;
    flex: none;
    line-height: 30px;
    padding: 5px 0;
  }
}
@media screen {
  .calendar-container .col_mp:hover {
    color: #ffffff;
    background-color: rgba(213, 43, 30, 0.5);
  }
}
@media screen {
  .calendar-container .col_mp.selected_cell {
    font-style: normal;
    color: #ffffff;
    background-color: #D52B1E;
  }
}
@media screen {
  .calendar-container .section_mp {
    width: 100%;
    flex: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .calendar-container .section_mp .col_mp.arrows_mp {
    min-width: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }
}
@media screen {
  .calendar-container .section_mp .col_mp.arrows_mp:hover {
    background-color: #ffffff;
  }
}
@media screen {
  .calendar-container .section_mp .col_mp.arrows_mp:first-child {
    background-image: url("../images/icons/i_cal_chevron_left.svg");
  }
}
@media screen {
  .calendar-container .section_mp .col_mp.arrows_mp:last-child {
    background-image: url("../images/icons/i_cal_chevron_right.svg");
  }
}
@media screen {
  .calendar-container .section_mp .col_mp.selected_date_mp {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 700;
    flex-shrink: 0;
    flex-grow: 1;
    line-height: 30px;
    padding: 5px 0;
  }
}

@media screen {
  .month-picker {
    position: relative;
  }
}
@media screen {
  .month-picker .picker .btn {
    font-family: "Raleway", sans-serif;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: solid 2px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    text-align: left;
  }
}
@media screen {
  .month-picker .popover {
    max-width: 100%;
    position: absolute;
    padding: 16px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 48em) {
  .month-picker .popover {
    max-width: 688px;
  }
}
@media screen and (min-width: 48em) {
  .month-picker .popover .sec-wrap .calendar .clearfix {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .month-picker .popover .sec-wrap .calendar .clearfix .year {
    width: 320px;
  }
}
@media screen and (min-width: 48em) {
  .month-picker .popover .sec-wrap .calendar .clearfix .year.year-start {
    margin: 0 16px 0 0;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .head .title {
    font-size: 18px;
    line-height: 40px;
    margin: 0 0 16px 0;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .head .title .btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .head .title .btn i {
    display: none;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .head .title .btn.year-down {
    background-image: url("../images/icons/i_cal_chevron_left.svg");
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .head .title .btn.year-up {
    background-image: url("../images/icons/i_cal_chevron_right.svg");
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .months .clearfix {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .months .clearfix .month {
    height: 40px;
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .months .clearfix .month .btn {
    height: 40px;
    color: rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .months .clearfix .month.selected .btn {
    color: #ffffff;
    background-color: #D52B1E;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .calendar .clearfix .year .months .clearfix .month.highlight .btn {
    color: #ffffff;
    background-color: rgba(213, 43, 30, 0.5);
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts {
    justify-content: unset;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts .btn {
    width: calc(50% - 8px);
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts .btn {
    width: 200px;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts .btn.btn-success {
    margin: 0 8px 0 auto;
  }
}
@media screen {
  .month-picker .popover .sec-wrap .shortcuts .btn.btn-default {
    color: #828282;
    background-color: #ffffff;
    margin: 0 auto 0 8px;
  }
}

@media screen and (min-width: 48em) {
  .entity-select__component {
    position: relative;
  }
}
@media screen {
  .entity-select__component .sim-select {
    color: #828282;
    font-size: 16px;
    line-height: 44px;
    font-weight: 500;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: calc(100% - 16px) center;
    background-size: 12px 7px;
    background-image: url("../images/icons/i_triangle.svg");
    text-decoration: none;
    display: block;
    padding: 0 16px;
    border: solid 2px rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  .entity-select__component .entity-select__container {
    width: 100vw;
    padding: 24px;
    margin: 24px -24px 0;
    background-color: #f2f2f2;
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container {
    position: absolute;
    top: 96px;
    left: 0;
    width: 660px;
    height: 564px;
    padding: 32px;
    margin: 0;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
    flex-direction: row;
  }
}
@media screen and (min-width: 80em) {
  .entity-select__component .entity-select__container {
    top: 96px;
    right: -16px;
    left: unset;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -10px;
    right: 24px;
    top: -10px;
    left: calc(50% - 24px);
  }
}
@media screen and (min-width: 80em) {
  .entity-select__component .entity-select__container::before {
    left: unset;
    right: 24px;
  }
}
@media screen {
  .entity-select__component .entity-select__container.is-active {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container.is-active {
    flex-direction: row;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity-select__col-left {
    margin: 0 0 24px 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity-select__col-left {
    margin: 0;
    width: 320px;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity-select__col-right {
    border-top: solid 2px #e1e1e1;
    padding: 24px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity-select__col-right {
    width: 247px;
    padding: 0;
    margin: 0 0 0 32px;
    border: none;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity-select__title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 16px;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity-select__selected-title {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity-select__selected-title {
    display: block;
    color: #828282;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .selected-cta {
    margin: auto 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .f-element.search {
    margin: 0 0 32px 0;
    flex-grow: 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .f-element.search input[type=text] {
    font-weight: 700;
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
    padding-left: 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list {
    max-height: 186px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity__list {
    max-height: 310px;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li {
    display: flex;
    flex-direction: row;
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity__list li {
    flex-direction: row-reverse;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li .entity__details {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li .entity__details strong {
    color: #828282;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    display: block;
    margin: 0 0 8px 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li .entity__details strong .highlight {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li .entity__details span {
    color: #828282;
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__list li .entity__select {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity__list li .entity__select {
    margin: 0 24px 0 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__select-all {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .entity-select__component .entity-select__container .entity__select-all {
    display: block;
    margin: auto 0 0 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list li {
    display: flex;
    flex-direction: row;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list li:last-child {
    margin: 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list li .selected__details {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list li .selected__details strong {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen {
  .entity-select__component .entity-select__container .entity__selected-list li .selected__control {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media screen {
  .nav {
    background-color: #ffffff;
    max-height: 100vh;
    overflow-y: scroll;
    -webkit-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
  }
}
@media screen and (min-width: 48em) {
  .nav {
    overflow-y: unset;
  }
}
@media screen {
  .nav .nav__row {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row {
    padding: 20px 0;
  }
}
@media screen {
  .nav .nav__row .assure-id-logo-black-red {
    width: 111px;
    height: 36px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: left center;
    background-image: url("../images/logos/acuant-logo.svg");
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    z-index: 99;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row .assure-id-logo-black-red {
    height: 40px;
  }
}
@media screen {
  .nav .nav__row .nav__container {
    width: 100%;
    margin: -36px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row .nav__container {
    width: auto;
    margin: 0;
    flex-basis: 1;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__menu {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row .nav__container .nav__menu {
    max-height: none;
    opacity: 1;
    visibility: visible;
    overflow: unset;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger {
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 11px 0;
    margin: 0 0 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row .nav__container .nav__trigger {
    display: none;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger::before, .nav .nav__row .nav__container .nav__trigger::after {
    content: "";
    display: block;
    background-color: #606060;
    height: 2px;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger::before {
    width: 28px;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger::after {
    width: 19px;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger.is-active {
    margin: 0 0 26px auto;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger.is-active::before, .nav .nav__row .nav__container .nav__trigger.is-active::after {
    transform-origin: center center;
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger.is-active::before {
    -webkit-transform: rotate(45deg) translate3d(0, 8px, 0);
    -moz-transform: rotate(45deg) translate3d(0, 8px, 0);
    -ms-transform: rotate(45deg) translate3d(0, 8px, 0);
    transform: rotate(45deg) translate3d(0, 8px, 0);
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger.is-active::after {
    width: 28px;
    -webkit-transform: rotate(-45deg) translate3d(0, -8px, 0);
    -moz-transform: rotate(-45deg) translate3d(0, -8px, 0);
    -ms-transform: rotate(-45deg) translate3d(0, -8px, 0);
    transform: rotate(-45deg) translate3d(0, -8px, 0);
  }
}
@media screen {
  .nav .nav__row .nav__container .nav__trigger.is-active + .nav__menu {
    opacity: 1;
    visibility: visible;
    width: calc(100vw - 40px);
    min-height: calc(100vh - 88px);
    max-height: 999vh;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 48em) {
  .nav .nav__row .nav__container .nav__trigger.is-active + .nav__menu {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
}

@media screen {
  .sub-navigation {
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 9;
  }
}

@media screen {
  .nav__links-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
    border-bottom: solid 2px rgba(225, 225, 225, 0.5);
    padding: 100px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container {
    padding: 0;
    flex-grow: 0;
    flex-shrink: 1;
    border: none;
    margin: 0 auto;
    height: auto;
    width: auto;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .nav__links-container .nav__links li {
    margin: 0 0 20px 0;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links li {
    margin: 0 15px;
  }
}
@media screen {
  .nav__links-container .nav__links li .nav__link {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links li .nav__link {
    color: rgba(0, 0, 0, 0.4);
    line-height: 40px;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links li .nav__link:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links li .nav__link.is-current {
    color: rgba(0, 0, 0, 0.8);
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .nav__links-container .nav__links li .nav__link.is-current::after {
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    width: 30px;
    height: 4px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@media screen {
  .nav__lang-container {
    order: 3;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
  }
}
@media screen and (min-width: 48em) {
  .nav__lang-container {
    width: 50%;
    order: 2;
    align-self: flex-end;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container {
    width: auto;
    padding: 0;
    margin: 0 0 0 auto;
    order: 2;
    align-self: auto;
    position: relative;
  }
}
@media screen {
  .nav__lang-container .nav__lang-label {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    margin: 0 auto 20px;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .nav__lang-label {
    display: none;
  }
}
@media screen {
  .nav__lang-container .lang-trigger {
    text-decoration: none;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: row;
    position: relative;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger {
    margin: auto;
  }
}
@media screen {
  .nav__lang-container .lang-trigger .flag {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    margin: 0 70px 0 0;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger .flag {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
}
@media screen {
  .nav__lang-container .lang-trigger .flag::before {
    color: rgba(0, 0, 0, 0.8);
    line-height: 40px;
    margin: 0 0 0 60px;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger .flag::before {
    line-height: 24px;
    margin: 0 0 0 34px;
  }
}
@media screen {
  .nav__lang-container .lang-trigger .flag::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}
@media screen {
  .nav__lang-container .lang-trigger + .lang-list {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger.is-active {
    margin-bottom: auto;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active + .lang-list {
    opacity: 1;
    visibility: visible;
    max-height: 999vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger.is-active + .lang-list {
    position: absolute;
    top: 60px;
    right: -40px;
    z-index: 99999;
    width: 290px;
    padding: 20px 10px 0 10px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 35px 0 rgba(0, 0, 0, 0.15);
    overflow: unset;
    -webkit-animation: fade_in_down 600ms both;
    -moz-animation: fade_in_down 600ms both;
    -o-animation: fade_in_down 600ms both;
    animation: fade_in_down 600ms both;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active + .lang-list .lang-item {
    flex: none;
    width: 33.333333%;
    margin: 0 0 20px 0;
    padding: 0 0 0 20px;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger.is-active + .lang-list .lang-item {
    width: 70px;
    margin: 0 10px 20px 10px;
    padding: 0;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active + .lang-list .lang-item input[type=radio] {
    display: none;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active + .lang-list .lang-item input[type=radio] + label {
    margin: 0;
    cursor: pointer;
  }
}
@media screen {
  .nav__lang-container .lang-trigger.is-active::after {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  }
}
@media screen and (min-width: 64em) {
  .nav__lang-container .lang-trigger.is-active::after {
    border-width: 8px 8px 0 8px;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
}

@media screen {
  .flag {
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 24px 24px;
    text-decoration: none;
  }
}
@media screen and (min-width: 64em) {
  .flag:hover::before {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media screen {
  .flag::before {
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 0 34px;
  }
}
@media screen and (min-width: 64em) {
  .flag::before {
    color: rgba(0, 0, 0, 0.4);
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .flag.us {
    background-image: url("../images/icons/i_lang_usa.svg");
  }
}
@media screen {
  .flag.us::before {
    content: "usa";
  }
}
@media screen {
  .flag.en {
    background-image: url("../images/icons/i_lang_en.svg");
  }
}
@media screen {
  .flag.en::before {
    content: "en";
  }
}
@media screen {
  .flag.fr {
    background-image: url("../images/icons/i_lang_fr.svg");
  }
}
@media screen {
  .flag.fr::before {
    content: "fr";
  }
}
@media screen {
  .flag.de {
    background-image: url("../images/icons/i_lang_de.svg");
  }
}
@media screen {
  .flag.de::before {
    content: "de";
  }
}
@media screen {
  .flag.esp {
    background-image: url("../images/icons/i_lang_sp.svg");
  }
}
@media screen {
  .flag.esp::before {
    content: "esp";
  }
}
@media screen {
  .flag.it {
    background-image: url("../images/icons/i_lang_it.svg");
  }
}
@media screen {
  .flag.it::before {
    content: "it";
  }
}
@media screen {
  .flag.au {
    background-image: url("../images/icons/i_lang_au.svg");
  }
}
@media screen {
  .flag.au::before {
    content: "au";
  }
}
@media screen {
  .flag.dk {
    background-image: url("../images/icons/i_lang_dk.svg");
  }
}
@media screen {
  .flag.dk::before {
    content: "dk";
  }
}
@media screen {
  .flag.sv {
    background-image: url("../images/icons/i_lang_sv.svg");
  }
}
@media screen {
  .flag.sv::before {
    content: "sv";
  }
}

@media screen {
  .nav__profile-container {
    order: 2;
  }
}
@media screen {
  .nav__profile-container .nav__profile {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    justify-content: center;
  }
}
@media screen {
  .nav__profile-container .nav__profile li {
    margin: 0 16px 0 0;
    padding: 0 16px 0 0;
    border-right: solid 2px rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  .nav__profile-container .nav__profile li:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media screen {
  .nav__profile-container .nav__profile li .profile-name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: block;
  }
}
@media screen {
  .nav__profile-container .nav__profile li a {
    line-height: 24px;
  }
}

