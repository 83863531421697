.create-subscription,
.edit-subscription {

    .f-row {

        &.cta-container {

            .f-element {

                &.alpha {

                    @include breakpoint() {
                        margin: 0;
                    }
                    @include breakpoint($small_tablet) {

                        order: 2;

                        max-width: 240px;
                        margin: 0 0 0 auto;

                    }

                }

                &.beta {

                    @include breakpoint($small_tablet) {

                        order: 1;
                        margin: 0;

                        flex-grow: 0;

                    }

                }

            }

        }

    }

}

.custom-radio__container {
    width: 100%
}